import React, { useEffect, useState, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useParams, useHistory } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';

export const BrandBuildStrategy = () => {
    let floatingData = useParams();
    const history = useHistory();
    const [brandcore, setBrandcore] = useState({});
    const [manifiesto, setManifiesto] = useState({});
    const [mission_vision, setMission_vision] = useState({});
    const [personality, setPersonality] = useState({});
    const [statement, setStatement] = useState({});
    const [values, setValues] = useState([]);
    const [tones, setTones] = useState([]);
    const [communicationTones, setCommunicationTones] = useState([]);
    const [settings, setSettings] = useState({});
    const [personalityTones, setPersonalityTones] = useState([]);
    const [taglineInfo, setTaglineInfo] = useState({});
    const [taglineArray, setTaglineArray] = useState([]);

    const BrandTagline =useRef(null);

    const [color, changeColor] = useState("#fff");

    document.body.style.backgroundColor = color;

    useEffect(() => {
        const fetchData = async () => {
            // console.log('ID que se mando->', floatingData.id)
            await SERVICES.getBrandBuildPublic(floatingData.id).then((res) => {
                
                if (res.status !== 200) return;
                setBrandcore(res.data.BrandCore ?? []);
                setManifiesto(res.data.Manifiesto ?? []);
                setMission_vision(res.data.Mision_Vision ?? []);
                setPersonality(res.data.Personality ?? []);
                setTones(res.data.Personality_tones);
                setCommunicationTones(res.data.Communication_tones);
                setPersonalityTones(res.data.Personality_tones);
                setStatement(res.data.Statement ?? []);
                setValues(res.data.Valores ?? []);
                setSettings(res.data.Settings ?? {});
                setTaglineInfo(res.data.Taglines_Status ?? {});
                setTaglineArray(res.data.Taglines);

            }).catch((error) => {
                console.error("error build ->", error)
            });
        };
        fetchData();
    }, []);


    return (
        <div className='container brandstrategy_page_main'>
            <div className='brancore_view_container'>
                {brandcore.brand_core_show &&
                    <>
                        <div className='row' id='BrandCore'>
                            <div className='col-5 coverSection'>
                                <p className='brandcore_tittle'>{String(settings.title).toUpperCase()} BRAND CENTER</p>

                                <p className='brandcore_strategy_cover'>{brandcore.text_cover}</p>
                            </div>
                            <div className='col-7 image_section'>
                                <img
                                    className='img_brandcore'
                                    src={brandcore.photo_cover + `?=${performance.now()}`}
                                />
                            </div>
                        </div>
                        <div className='row brancore_brandstrategy_container'>
                            <p className='title_submain'>Brand Intro</p>
                            <p className='brandcore_strategy_core'>{brandcore.text_core}</p>
                        </div>
                    </>
                }
                {statement.brand_statement_show &&
                    <div className='row' id='BrandStatement'>
                        <div className='col-6 img_statement_container'>
                            <img
                                className='img_statment'
                                src={statement.photo_statement + `?=${performance.now()}`}
                            />
                        </div>
                        <div className='col-6 statement_container'>
                            <p className='statement_company'>{String(settings.title).toUpperCase()} brand strategy</p>
                            <p className='statement_tittle'>Brand Statement</p>
                            <p className='statement_info'>{statement.brand_statement}</p>

                        </div>
                    </div>
                }

                {mission_vision.mision_vision_show &&
                    <div className='row row-cols-1 row-cols-md-2 ' id='BrandMissionVision'>
                        <div className='col mission_container col'>
                            <p className='title_mission-vision'>Mission</p>
                            <p className='info_mission-vision'>{mission_vision.text_mission}</p>
                        </div>
                        <div className='col vision_container col'>
                            <p className='title_mission-vision text-white'>Vision</p>
                            <p className='info_mission-vision text-white'>{mission_vision.text_vision}</p>
                        </div>
                    </div>
                }
                <div className="row values_container" id='BrandValues'>
                    <p className='left_tittleTop'>Our Values</p>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
                        {values.map((item, index) => (
                            <div key={index}>
                                <div className='col m-2'>
                                    <img
                                        src={item.image_value + `?=${performance.now()}`}
                                        className='img_values img_values_card mt-5'
                                    />
                                    <p className='values_tittle'>{item.title_value}</p>
                                    <p className='values_description'>{item.text_value}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {manifiesto.manifiesto_show &&
                    <div className='row row-cols-1 row-cols-md-2' id='BrandManifiesto'>
                        <div className='col mission_container bg-white'>
                            <p className='left_tittleTop'>Manifiesto</p>
                            <p className='block_text_info'>{manifiesto.text_manifiesto}</p>
                        </div>
                        <div className='col img_statement_container d-flex align-items-center'>
                            <img
                                src={manifiesto.photo_manifiesto + `?=${performance.now()}`}
                                className='img_statment'
                            />
                        </div>
                    </div>
                }
                {personality.personality_show &&
                    <div className='row' id='BrandPersonality'>
                        <div className='col mission_container'>
                            <p className='left_tittleTop'>Personality And Communication Tone</p>
                            <p className='block_text_info m30'>{personality.text_personality}</p>
                            <div className="row row-cols-1 row-cols-md-2">

                                <div>
                                    <p className='fs-4'>Personality Tone</p>
                                    {personalityTones.map((item, index) => (
                                        <div key={index}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='titleBar'>{item.text_perso_tone}</p>
                                                <p className='titleBar'>{`${Number(item.percentage_perso_tone ?? 0)}%`}</p>
                                            </div>
                                            <ProgressBar animated className='progress_bar' now={Number(item.percentage_perso_tone ?? 0)} />
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <p className='fs-4'>Communication Tone</p>
                                    {communicationTones.map((item, index) => (
                                        <div key={index}>
                                            <div className='d-flex justify-content-between'>
                                                <p className='titleBar'>{item.text_comu_tone}</p>
                                                <p className='titleBar'>{`${Number(item.percentage_comu_tone ?? 0)}%`}</p>
                                            </div>
                                            <ProgressBar animated className='progress_bar' now={Number(item.percentage_comu_tone ?? 0)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* <div className='col mission_container bg-white'>
                            <p className='left_tittleTop'>Tagline</p>
                        </div> */}
                    </div>
                }
                {taglineInfo.tagline_show &&
                    <div className='row TagLineSection' id='BrandTagline'>
                        <p className='left_tittleTop mb-4'>Tagline</p>
                        <div className='row row-cols-1 row-cols-md-2'>
                            {taglineArray.map((item, index) => (
                                <div key={index} className='mb-4 px-4'>
                                    <p className='block_text_info mb-2' style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{String(item.title_tagline).toUpperCase()}</p>
                                    <p className='block_text_info mb-2'>{item.text_tagline ?? ""}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <div className='row footer_container'>
                    
                        <div className='btn_canel_footer_container col-md-3'>
                            {/* <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/Home/') }}>
                            Exit
                        </button> */}
                        </div>
                        <div className='text_footer col-6'>
                            <p className='brand_center'>BRAND CENTER</p>
                            <p className='desc_brand_center'>Continue to Brand Standards</p>
                        </div>
                        <div className='btn_save_footer_container col-md-3'>
                            <button className='btn_save_footer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/Client/' + floatingData.id + '/BrandStandard')
                                }}>
                                Continue
                            </button>
                        </div>
                    

                </div>
            </div>
        </div>
    )
}
