import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import './BuildStandard.style.css';

export const BuildStandard = () => {
    let floatingData = useParams();
    const history = useHistory();
    const [brandCore, setBrandCore] = useState({});
    const [identityInfo, setIdentityInfo] = useState({});
    const [identityArray, setIdentityArray] = useState([]);
    const [versionsInfo, setVersionsInfo] = useState({});
    const [versionsArray, setVersionsArray] = useState([]);
    const [constructionInfo, setConstructionInfo] = useState({});
    const [constructionSymbols, setConstructionSymbols] = useState([]);
    const [urlInfo, setUrlInfo] = useState({});
    const [urlArray, setUrlArray] = useState([]);
    const [dontsInfo, setDontsInfo] = useState({});
    const [dontArray, setDontArray] = useState([]);
    const [palletInfo, setPalletInfo] = useState({});
    const [palletArray, setPalletArray] = useState([]);
    const [photoInfo, setPhotoInfo] = useState({});
    const [photoArray, setPhotoArray] = useState([]);
    const [photoBullets, setPhotoBullets] = useState([]);
    const [amplifierInfo, setAmplifierInfo] = useState({});
    const [amplifierArray, setAmplifierArray] = useState([]);
    const [settings, setSettings] = useState({});
    const [iconInfo, setIconInfo] = useState({});
    const [iconArray, setIconArray] = useState([]);
    const [tipographyInfo, settipographyInfo] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            
            await SERVICES.getBrandBuildPublicStandard(floatingData.id).then((res) => {
                if (res.status !== 200) return;
                setBrandCore(res.data.CoreStandar.length > 0 ? res.data.CoreStandar[0] : {});
                setIdentityInfo(res.data.Identity ?? {});
                setIdentityArray(res.data.IdentityColors.length > 0 ? res.data.IdentityColors : []);
                setVersionsInfo(res.data.VersionStatus ?? {});
                setVersionsArray(res.data.Versions.length > 0 ? res.data.Versions : []);
                setConstructionInfo(res.data.Construction.length > 0 ? res.data.Construction[0] : {});
                setConstructionSymbols(res.data.SymbolConstructions.length > 0 ? res.data.SymbolConstructions : []);
                setUrlInfo(res.data.UrlTreatment.length > 0 ? res.data.UrlTreatment[0] : {});
                setUrlArray(res.data.UrlImageTreatments.length > 0 ? res.data.UrlImageTreatments : []);
                setDontsInfo(res.data.dontsStatus ?? {});
                setDontArray(res.data.Donts);
                setPalletInfo(res.data.CategoryPalletStatus ?? {});
                setPalletArray(res.data.CategorysPallet ?? []);
                setPhotoInfo(res.data.Photography.length > 0 ? res.data.Photography[0] : {});
                setPhotoArray(res.data.PhotographyImages ?? []);
                setPhotoBullets(res.data.Photography_Bullets ?? []);
                setAmplifierInfo(res.data.AmplifierStatus ?? {});
                setAmplifierArray(res.data.Amplifiers ?? []);
                setIconInfo(res.data.Iconography.length > 0 ? res.data.Iconography[0] : []);
                setIconArray(res.data.IconsCategorys ?? []);
                setSettings(res.data.Settings ?? {});
                settipographyInfo(res.data.Typography.length > 0 ? res.data.Typography[0] : {})


            }).catch((error) => {
                console.error("error build ->", error)
            });
        };
        fetchData();
    }, []);




    return (
        <div className='bg-white'>
            <div className='row'>
                <div className='downloads_banner_title d-flex flex-column align-items-end'>
                    <p className='fs-5 text-white'>{String(JSON.stringify(settings) !== '{}' ? settings.title : '').toUpperCase()} RESOURCES</p>
                    <p className='title_brand_downloads fs-1 fw-bold'>THE BRAND</p>
                </div>
            </div>

            {JSON.stringify(brandCore) !== '{}' && brandCore.brand_core_show === true &&
                <div className='row row-cols-1 row-cols-md-2 bran_core_standard_container' id='brand-core-standard'>
                    <div className='col'>
                        <img
                            src={brandCore.photo_cover + `?=${performance.now()}`}
                            className="w-100"
                        />
                    </div>
                    <div>
                        <p className='brandcore_top_text'>{settings.title} brand standards</p>
                        <p className='thebrandcoretext'>Brand Description</p>
                        <p className='brandcore_info'>{brandCore.text_cover}</p>
                        <p className='brandcore_info'>{brandCore.text_description}</p>
                    </div>
                </div>
            }
            {JSON.stringify(identityInfo) !== '{}' && identityInfo.indentity_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-identity'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Brand Identity</p>
                    </div>
                    <div className='col-lg-9 '>
                        <div className='brandidentity_logo mb-4'>
                            <img
                                src={identityInfo.image_indentity + `?=${performance.now()}`}
                                className="img_identity_main"
                            />
                        </div>
                        {identityArray.length > 0 &&
                            <div className='row row-cols-3'>
                                {identityArray.map((item, index) => (
                                    <div key={index}>
                                        <div className='col card_color_main'>
                                            <div className='card_colors'>
                                                <div >
                                                    <p className='color_count'>Color {index + 1}</p>
                                                </div>
                                                <div className='colors_specs'>
                                                    <p>RGB {item.color_rgb}</p>
                                                    <p>HEX {item.color_hex}</p>
                                                    <p>CMYK {item.color_cmyk}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            }
            {JSON.stringify(versionsInfo) !== '{}' && versionsInfo.versions_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-versions'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Brand Versions</p>
                    </div>
                    <div className='col-lg-9 '>
                        {versionsArray.map((item, index) => (
                            <div key={index} className='mb-4'>
                                <div className='brandidentity_logo mb-4'>
                                    <img
                                        src={item.image_version + `?=${performance.now()}`}
                                        className="img_identity_main"
                                    />
                                </div>

                                <div className='row row-cols-3'>
                                    {Array(...item.version_colors).map((item2, index2) => (
                                        <div key={index2}>
                                            <div className='col card_color_main'>
                                                <div className='card_colors'>
                                                    <div >
                                                        <p className='color_count'>{item2.title_color}</p>
                                                    </div>
                                                    <div className='colors_specs'>
                                                        <p>RGB {item2.color_rgb}</p>
                                                        <p>HEX {item2.color_hex}</p>
                                                        <p>CMYK {item2.color_cmyk}</p>
                                                        <p>Pantone {item2.pantone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
            {JSON.stringify(constructionInfo) !== '{}' && constructionInfo.construction_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-construction'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Constructions</p>
                    </div>
                    <div className='col-lg-9'>
                        <div className='brandidentity_logo mb-4'>
                            <img
                                src={constructionInfo.image_construction + `?=${performance.now()}`}
                                className="img_identity_main"
                            />
                        </div>

                        <p className='construction-text'>{constructionInfo.text_construction}</p>
                        {constructionSymbols.length > 0 &&
                            <div className='row row-cols-1 row-cols-md-2'>
                                {constructionSymbols.map((item, index) => (
                                    <div key={index} className='mb-4'>
                                        <img
                                            className='symbol-image'
                                            src={item.image_symbol + `?=${performance.now()}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            }
            {JSON.stringify(urlInfo) !== "{}" && urlInfo.url_treatment_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-url'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>URL Treatment</p>
                    </div>
                    <div className='col-lg-9 '>
                        {urlArray.length > 0 &&
                            <>
                                <div className='row row-cols-1 row-cols-md-3 mb-4'>
                                    {urlArray.map((item, index) => (
                                        <div key={index}>
                                            <img
                                                className='url_image'
                                                src={item.image_url + `?=${performance.now()}`}
                                            />
                                        </div>
                                    ))}
                                </div>

                            </>
                        }
                        <p className='url_treatment-text'>{urlInfo.text_url_treatment}</p>
                    </div>
                </div>
            }
            {JSON.stringify(dontsInfo) !== '{}' && dontsInfo.donts_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-donts'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Dont's</p>
                    </div>
                    <div className='col-lg-9'>
                        <div className='row row-cols-1 row-cols-md-3 mb-4'>
                            {dontArray.map((item, index) => (
                                <div key={index}>
                                    <div className='mb-3'>
                                        <img
                                            className='url_image'
                                            src={item.image_dont + `?=${performance.now()}`}
                                        />
                                        <p className='dont_text-info'>{item.text_dont}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            {JSON.stringify(palletInfo) !== '{}' && palletInfo.pallet_color_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-pallet-color'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Color Pallete</p>
                    </div>
                    <div className='col-lg-9'>
                        <div className="">
                            {palletArray.map((item, index) => (
                                <div key={index} className='category_color' >
                                    <p className='text_18'>Category {item.title_category}</p>
                                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
                                        {Array(...item.category_colors).map((item2, index2) => (
                                            <div key={index2}>
                                                <div className='blockColor' style={{ width: '100%', background: item2.color_hex }} />

                                                <p className='colorname mt-2'>{item2.title_color ?? ""}</p>
                                                <div className='colorname_text'>
                                                    <p>HEX - {item2.color_hex ?? ""}</p>
                                                    <p>RGB - {item2.color_rgb ?? ""}</p>
                                                    <p>CMYK - {item2.color_cmyk ?? ""}</p>
                                                    <p>Pantone - {item2.pantone ?? ""}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            {JSON.stringify(tipographyInfo) !== '{}' && tipographyInfo.typography_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-typography'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Typography</p>
                    </div>
                    <div className='col-lg-9'>
                        {tipographyInfo.tagline_sample === null ?
                            null :
                            <div className='brandidentity_logo mb-4'>
                                <p className='text_18'>Tagline Sample</p>
                                <img
                                    src={tipographyInfo.tagline_sample + `?=${performance.now()}`}
                                    className="img_identity_main"
                                />
                            </div>
                        }

                        {tipographyInfo.alphabet === null ?
                            null :
                            <div className='brandidentity_logo mb-4'>
                                <p className='text_18'>Alphabet</p>
                                <img
                                    src={tipographyInfo.alphabet + `?=${performance.now()}`}
                                    className="img_identity_main"
                                />
                            </div>
                        }
                        {tipographyInfo.parragraph === null ?
                            null :
                            <div className='brandidentity_logo mb-4'>
                                <p className='text_18'>Parragraph</p>
                                <img
                                    src={tipographyInfo.parragraph + `?=${performance.now()}`}
                                    className="img_identity_main"
                                />
                            </div>
                        }

                        <div className='mb-4'>
                            <p className='text_16'>{tipographyInfo.description}</p>
                        </div>
                    </div>
                </div>
            }

            {JSON.stringify(photoInfo) !== '{}' && photoInfo.photography_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-photography'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Photography</p>
                    </div>
                    <div className='col-lg-9'>
                        <div className='mb-4'>
                            <p className='text_16'>{photoInfo.text_photography}</p>
                        </div>

                        <div className="row row-cols-1 row-cols-sm-2 mb-4 mt-2">
                            {photoBullets.map((item, index) => (
                                <div className='col' key={index}>
                                    <div className='pb-1'>
                                        <p className='text_16'>{item.text_bullet}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="row row-cols-1 row-cols-sm-3">
                            {photoArray.map((item, index) => (
                                <div className='col' key={index}>
                                    <div className=''>
                                        <img
                                            src={item.image_photography + `?=${performance.now()}`}
                                            className='photography_image'
                                        />
                                        <p>{item.description_photography}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            {JSON.stringify(amplifierInfo) !== '{}' && amplifierInfo.amplifiers_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-amplifier'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Amplifier</p>
                    </div>
                    <div className='col-lg-9'>
                        <div className=''>
                            {amplifierArray.map((item, index) => (
                                <div key={index} className='mb-4'>
                                    <p className='text_18'>{item.title_amplifier}</p>
                                    <p className='text_16 mb-3'>{item.text_amplifier}</p>
                                    <div className="row row-cols-2 row-cols-md-3">
                                        {Array(...item.amplifier_images).map((item2, index2) => (
                                            <div key={index2}>
                                                <img
                                                    className='url_image'
                                                    src={item2.image_pattern + `?=${performance.now()}`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }

            {JSON.stringify(iconInfo) !== '{}' && iconInfo.iconography_show === true &&
                <div className='row row-cols-1 row-cols-md-2 identity_container contianer_comun_standard' id='brand-iconography'>
                    <div className='col-lg-3'>
                        <p className='brand_identity_title'>Iconography</p>
                    </div>
                    <div className='col-lg-9'>
                        <div className='mb-4'>
                            <p className='text_16'>{iconInfo.text_iconography}</p>
                        </div>
                        <div className="">
                            {iconArray.map((item, index) => (
                                <div className='mb-4' key={index}>
                                    <p className='fw-bold mb-4'>{item.title_category}</p>
                                    <div className='row row-cols-2 row-cols-md-4 mb-2'>
                                        {Array(...item.category_images).map((item2, index2) => (
                                            <div>
                                                <img
                                                    className='url_image'
                                                    src={item2.image_category + `?=${performance.now()}`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            <div className='row footer_container'>
                    <div className='btn_canel_footer_container col-md-3'>
                        <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/Client/' + floatingData.id + '/BrandStrategy') }}>
                            Back
                        </button>
                    </div>
                    <div className='text_footer col-6'>
                        <p className='brand_center'>BRAND CENTER</p>
                        <p className='desc_brand_center'>Continue to Downloads</p>
                    </div>
                    <div className='btn_save_footer_container col-md-3'>
                        <button className='btn_save_footer'
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/Client/' + floatingData.id + '/BrandDownloads')
                            }}>
                            Continue
                        </button>
                    </div>
            </div>

        </div >
    )
}
