import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { DeleteButton } from '../Components/DeleteButton';

export const DropzoneValues = ({ index, brandValues, setBrandValues, fetchData }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...brandValues];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index] = {
                    "title_value": brandValues[index].title_value,
                    "text_value": brandValues[index].text_value,
                    "image_value": base64,
                    "image_value_url": reader.result,
                    "id_values": brandValues[index].id_values
                };

                setBrandValues(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });
    return (
        <div className='col'>
            <p className='me-auto'>#{index + 1} Value</p>
            <div className="d-flex mb-2">
                
                
                <div className="values_container-form bg-white mb-2" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    brandValues[index].image_value_url === "" ?
                        <i className="bi bi-image picture_avatar_suerinfo" /> :
                        <img
                            className='img-values-form'
                            src={String(brandValues[index].image_value_url).includes("http") === true ? brandValues[index].image_value_url + `?=${performance.now()}` : brandValues[index].image_value_url}
                        />
                }
                
                </div>
                <div style={{marginLeft:10}}>
                <DeleteButton
                    dataArray={brandValues}
                    setDataArray={setBrandValues}
                    fetchData={fetchData}
                    id={brandValues[index].id_values}
                    index={index}
                    title={'Value'}
                    type={1}
                />
            </div>
            </div>
            <p className='value-label'>#{index + 1} Tittle</p>
            <input
                value={brandValues[index].title_value}
                onChange={(e) => {
                    let sebo = [...brandValues];
                    sebo[index].title_value = e.target.value;
                    setBrandValues(sebo);
                }}
                className='form-control input_settings'
            />
            <p className='value-label'>#{index + 1} Description</p>
            <input
                value={brandValues[index].text_value}
                onChange={(e) => {
                    let sebo = [...brandValues];
                    sebo[index].text_value = e.target.value;
                    setBrandValues(sebo);
                }}
                className='form-control input_settings'
            />
        </div>
    )
}
