import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { SERVICES } from '../../../Services/Services';
import './ChangePassword.style.css';

export const ChangePassword = ({show, setShow, pass, setPass}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (message) => {
        setIsLoading(false);
        Swal.fire({title: message, icon:'error', timer: 5000});
    }

    const changePass = async() => {
        if(String(pass).trim() === "") return handleError('The new password is empty');
        await SERVICES.changePass(pass).then((res) => {
            
            if(res.status !== 200) return handleError('Something wrong');
            Swal.fire({title: 'Password changed', icon:'success'});
            setShow(false);
        }).catch((error) => {
            console.error('error pass new ->', error);
        });
    }


    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                {/* <Modal.Title>Completa el formulario</Modal.Title> */}
            </Modal.Header>
            <div className='modal-body'>
                <form>
                    <label className='form-text'>Write your new Password</label>
                    <input className='form-control' onChange={({ target }) => setPass(target.value)} type={'password'} />
                </form>
            </div>
            <Modal.Footer>
                <button onClick={(e) => {e.preventDefault(); setShow(false)}} className='save_infouser'>
                    Cancel
                </button>
                <button onClick={(e) => { e.preventDefault(); changePass()}} disabled={isLoading} className='save_infouser'>
                    Change Password
                </button>
            </Modal.Footer>
        </Modal>
    )
}
