import Cookies from 'js-cookie';

export const getCookie = (name) => {
    const cookieValue = Cookies.get(name);
    
    if (!cookieValue) {
        return null;
    }
    const cookie = cookieValue.split('j:')
    return cookie[1];
};
