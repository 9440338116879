import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardsBrands } from '../CardsBrands';
import './ProjectsGrid.style.css';

export const ProjectsGrid = ({ projects, fetchData }) => {
    const history = useHistory();
    return (
        <div className='projects_grid_main_container container'>
            <div className="underline_div mt-4">
                <p className='projects_grid_main_Title'>My Brand Guidelines</p>
            </div>
            <div className='d-flex justify-content-end'>
                <button onClick={(e) => {e.preventDefault(); history.push('/CreateBrandGuideline');}} className='save_infouser mt-4 py-2'>
                    Create New Guideline
                </button>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 my-4">
                {projects.map((item, index) => (
                    <CardsBrands fetchData={fetchData} key={index} item={item}/>
                ))}
            </div>
        </div>
    )
}
