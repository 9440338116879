import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../../Services/Services';

export const RestorePass = () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [codeVerify, setCodeVerify] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [newPass2, setNewPass2] = useState("");

    const sendCode = async () => {
        await SERVICES.sendCodeVerification({ "email": email.trim() }).then((res) => {
            if (res.status !== 200) return Swal.fire({ title: res.message.message, icon: 'error', confirmButtonColor: '#000000' });
            Swal.fire({ title: res.data.message, icon: 'success', iconColor: '#000000', confirmButtonColor: '#000000', });
        });
    }

    const validateCode = async () => {
        await SERVICES.validateToken({ "token": code.trim() }).then((res) => {
            if (res.status !== 200) return Swal.fire({ title: res.message.error, icon: 'error', confirmButtonColor: '#000000' });
            setCodeVerify(true);
            // Swal.fire({title: res.data.message, icon:'success', iconColor:'#000000', confirmButtonColor:'#000000',});
        });
    }


    const restore = async () => {
        if (newPass.trim() !== newPass.trim()) return Swal.fire({ title: "The password most be the same", icon: 'error', confirmButtonColor: '#000000' });
        await SERVICES.restorePass({
            "token": code.trim(),
            "password": newPass,
            "password2": newPass2
        }).then((res) => {
            if (res.status !== 200) return Swal.fire({ title: res.message.message, icon: 'error', confirmButtonColor: '#000000' });
            history.push('/Login');
            // Swal.fire({title: res.data.message, icon:'success', iconColor:'#000000', confirmButtonColor:'#000000',});
        });
    }



    return (
        <div className='login-container'>
            <div className="login-form-style">
                <p className='fw-bold fs-4 mb-4'>Forgot password</p>
                <div className='mb-4'>
                    <p className='labels-login mb-2'>Email address</p>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type={'email'}
                        className='form-control inputs-login-page'
                    />
                </div>
                <button onClick={(e) => { e.preventDefault(); sendCode() }} className='btn bg-black text-white mb-2'>
                    Send code verification
                </button>
                <div>
                    <p className='labels-login mb-2'>Verification Code</p>
                    <input
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className='form-control inputs-login-page'
                    />
                </div>

                <div className='mt-3'>
                    <button onClick={(e) => { e.preventDefault(); validateCode() }} className='btn-login'>
                        Validate code
                    </button>
                </div>

                {codeVerify &&
                    <div>
                        <p className='labels-login mb-2'>New password</p>
                        <input
                            value={newPass}
                            onChange={(e) => setNewPass(e.target.value)}
                            className='form-control inputs-login-page'
                        />
                        <p className='labels-login mb-2'>Verify password</p>
                        <input
                            value={newPass2}
                            onChange={(e) => setNewPass2(e.target.value)}
                            className='form-control inputs-login-page'
                        />
                        <div className='mt-3'>
                            <button onClick={(e) => { e.preventDefault(); restore() }} className='btn-login'>
                                Restore Password
                            </button>
                        </div>
                    </div>
                }
                <div className='d-flex justify-content-center mt-4'>
                    <a onClick={(e) => { e.preventDefault(); history.push('/Create-Account') }} className='text-secondary fw-bold'>Join Now</a>
                    <p className='text-secondary'> | </p>
                    <a className='text-secondary'>Lost Password?</a>
                </div>
            </div>
        </div>

    )
}
