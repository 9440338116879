import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { Link } from 'react-router-dom';

export const MissionVision = ({ killSession, text_mission, setText_mission, text_vision, setText_vision, text_mission_show, setText_mission_show, text_vision_show, setText_vision_show }) => {
    let floatingData = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (message, subMessage) => {
        // Swal.fire({title: message, text: subMessage, icon:'error'});
        return setIsLoading(false);
    };

    const fetchData = async () => {
        await SERVICES.getBrandMissionVision(floatingData.id).then((res) => {
            
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            // if (res.status !== 200) return () => { dispatch(logOut()); history.push('/Login') };
            setText_vision(res.data.text_vision ?? "");
            setText_mission(res.data.text_mission ?? "");
            setText_mission_show(res.data.mision_vision_show ?? false);
            setText_vision_show(res.data.vision_show ?? false);
        }).catch((error) => {
            // console.log('Error get core ->', error);
        });
    };

    const modalMision =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/6-Mision.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalVision =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/7-Vision.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }

    useEffect(() => {
        
        fetchData();
    }, []);




    return (
        <div id='BrandMissionVision' className='submenu_maincontainer'>
            <div className='row row-cols-1 row-cols-md-2'>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center mb-4">
                        <p className='label_input_settings me-auto'>Mission <Link onClick={(e) => {modalMision();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!text_mission_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_mission_show(true) }} className="bi bi-square" /></p>}
                        {text_mission_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_mission_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <textarea
                        value={text_mission}
                        onChange={(e) => setText_mission(e.target.value)}
                        className='form-control input_settings'
                        type="textarea"
                        rows={5}
                        onKeyDown={(e) => StringJumpLine(e.code, e, setText_mission)}
                    />
                </div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center mb-4">
                        <p className='label_input_settings me-auto'>Vision <Link onClick={(e) => {modalVision();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!text_vision_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_vision_show(true) }} className="bi bi-square" /></p>}
                        {text_vision_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_vision_show(false) }} className="bi bi-check-square" /></p>}
                    </div>

                    <textarea
                        value={text_vision}
                        onChange={(e) => setText_vision(e.target.value)}
                        className='form-control input_settings'
                        type="textarea"
                        rows={5}
                        onKeyDown={(e) => StringJumpLine(e.code, e, setText_vision)}
                    />
                </div>
            </div>
        </div>
    )
}
