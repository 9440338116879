import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const DropzoneIconographyHere = ({ index, index2, icons, setIcons, item }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                let sebo = [...icons];
                // console.log('reader ->', reader.result);
                sebo[index].Icons[index2].image_category = base64;
                sebo[index].Icons[index2].image_category_url = reader.result;
                setIcons(sebo);
                // setPathUrl(reader.result);
                // setPath64(base64);
            }
        };


        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpeg': ['.jpg'],
        }
    });

    const deleteIcon = async () => {
        // console.log('hola mundo');
        if (String(icons[index].Icons[index2].image_category_url).includes('http') === true) {
            await SERVICES.deleteBrandIconographiesIcons(floatingData.id, icons[index].Icons[index2].id_iconographyimg).then((res) => {
                
                let sebo = [...icons];
                let seboIcons = [...icons[index].Icons]
                seboIcons.splice(index2, 1);
                sebo[index].Icons = seboIcons;
                // fetchData();
                setIcons(sebo);
            }).catch((error) => {
                console.error('error delete icon ->', error);
            });
        } else {
            let sebo = [...icons];
            let seboIcons = [...icons[index].Icons]
            seboIcons.splice(index2, 1);
            sebo[index].Icons = seboIcons;
            // fetchData();
            setIcons(sebo);
        }
    };



    return (
        <div>
            <div className='d-flex align-items-center'>
                <label className='value_input me-auto'>#{index2 + 1} Icon</label>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteIcon();
                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            <div className="img_icon_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    item.image_category_url === "" ?
                        <i className="bi bi-image picture_avatar_suerinfo" /> :
                        <img
                            className='img-iconography'
                            src={String(item.image_category_url).includes("http") === true ? item.image_category_url + `?=${performance.now()}` : item.image_category_url}
                        />
                }
            </div>
        </div>
    );
};

export const DropzoneIcons = ({ index, icons, setIcons }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();

    const deleteBrandIconography = async () => {
        // console.log('hola mundo');
        if (icons[index].id_category !== null) {
            await SERVICES.deleteBrandIconographiesIcons(floatingData.id, icons[index].id_category).then((res) => {
                
                let sebo = [...icons];
                sebo.splice(index, 1);
                // fetchData();
                setIcons(sebo);
            }).catch((error) => {
                console.error('error delete icon ->', error);
            });
        } else {
            let sebo = [...icons];
            sebo.splice(index, 1);
            setIcons(sebo);
        }
    };

    let newOneIcon = {
        "image_category": "",
        "image_category_url": "",
    }
    const modalIconographyCategory=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/29-Iconography-Category-Name.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    return (
        <>
            <div className="d-flex align-items-center">
                <p className='symbol_title me-auto'>Nº{index + 1} - Category Name <Link onClick={(e) => {modalIconographyCategory();}}><i className="bi bi-exclamation-circle" /></Link></p>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteBrandIconography();
                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            <div>

                <input
                    className='form-control input_settings'
                    placeholder='Samples: Web, Stationery, Branding'
                    value={icons[index].title_category}
                    onChange={(e) => {
                        let sebo = [...icons];
                        sebo[index].title_category = e.target.value;
                        setIcons(sebo);
                    }}
                />
                <p className='symbol_title me-auto'>Icons Photos</p>
                <p className='recomendationText'>We recommended square format. Maximum images: 1200x1200px</p>
                <div>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-5'>
                        {Array(...icons[index].Icons).map((item2, index2) => (
                            <div key={index2}>
                                <DropzoneIconographyHere
                                    icons={icons}
                                    index={index}
                                    index2={index2}
                                    item={item2}
                                    setIcons={setIcons}
                                />
                            </div>
                        ))}
                        <div className="add-more-Container_Small mt-3"> 
                            <a onClick={(e) => {
                                e.preventDefault();
                                let sebo = [...icons[index].Icons];
                                let allInfo = [...icons];
                                sebo.push(newOneIcon);
                                allInfo[index].Icons = sebo;
                                setIcons(allInfo);

                            }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /></a>
                            <div className='addNewElement_Small'>Add New Icon</div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
