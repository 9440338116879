import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../../Services/Services';
import { logIn } from '../../../store/auth/ProfileStatus';
import './LoginForm.style.css';
import { getCookie } from '../../../utils/cookies';

export const LoginForm = ({ setMode }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const initSession = async (e) => {
        e.preventDefault();
        
        if (email.trim() === "" || password.trim() === "") return Swal.fire({
            title: 'Empty spaces',
            text: 'Please, complete it.',
            icon: 'warning',
            timer: 5000
        });
        
        await SERVICES.initSession(email, password).then(async (res) => {
            
            let cookieData = getCookie('Authorization');
            const jsonCookie = JSON.parse(cookieData)
            if (jsonCookie) {
                
                window.location.replace(`https://${jsonCookie.name_subdomain}.brandspace.ai/Home`);
                //history.push('/Home');
            } else {
                
                throw new Error('No se pudo obtener la cookie');
            }
        }).catch((error) => {
            // console.error('error login ->', error.message);
            Swal.fire({ title: 'Sorry', text: 'Something wrong', icon: 'error', timer: 5000 })
        });
    };


    return (
        <div className="login-form-style">
            <p className='fw-bold fs-4 mb-4'>Welcome!</p>
            <div className='mb-4'>
                <p className='labels-login mb-2'>Username or Email address</p>
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={'email'}
                    className='form-control inputs-login-page'
                />
            </div>
            <div>
                <p className='labels-login mb-2'>Password</p>
                <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={'password'}
                    className='form-control inputs-login-page'
                />
            </div>
            <div className='mt-3'>
                <p className='labels-login remember-text'>Remember me</p>
            </div>
            
            <div className='mt-3'>
                <button onClick={initSession} className='btn-login'>
                    Login In
                </button>
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <a onClick={(e) => { e.preventDefault(); history.push('/Create-Account') }} className='text-secondary fw-bold'>Join Now</a>
                <p className='text-secondary'> | </p>
                <a onClick={(e) => { e.preventDefault(); history.push('/RestorePassword') }} className='text-secondary'>Lost Password?</a>
            </div>
        </div>
    )
}
