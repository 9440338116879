import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import { SERVICES } from '../../Services/Services';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-scroll'


export const BuildSidebar = () => {
    const history = useHistory();
    let floatingData = useParams();
    const { path } = useRouteMatch();
    const [settings, setSettings] = useState({});
    let url = window.location.href;
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)


    const [optionsStrategy, setOptionsStrategy] = useState([
        {
            "name": "Brand Intro",
            "id": "BrandCore",
            "show": false
        },
        {
            "name": "Brand statement",
            "id": "BrandStatement",
            "show": false
        },
        {
            "name": "Mission & vision",
            "id": "BrandMissionVision",
            "show": false
        },
        {
            "name": "Values",
            "id": "BrandValues",
            "show": false
        },
        {
            "name": "Manifiesto",
            "id": "BrandManifiesto",
            "show": false
        },
        {
            "name": "Personality",
            "id": "BrandPersonality",
            "show": false
        },
        {
            "name": "Tagline",
            "id": "BrandTagline",
            "show": false
        },
    ]);

    const [optionsStandards, setOptionsStandards] = useState([
        {
            name: "The Brand",
            id: 'brand-core-standard',
            "show": false
        },
        {
            name: "Brand Identity",
            id: 'brand-identity',
            "show": false,

        },
        {
            name: "Brand Version",
            id: 'brand-versions',
            "show": false
        },
        {
            name: "Construction",
            id: 'brand-construction',
            "show": false
        },
        {
            name: "URL Treatment",
            id: 'brand-url',
            "show": false
        },
        {
            name: "Don'ts",
            id: 'brand-donts',
            "show": false
        },
        {
            name: "Color Pallete",
            id: 'brand-pallet-color',
            "show": false
        },
        {
            name: "Typography",
            id: 'brand-typography',
            "show": false
        },
        {
            name: "Photography",
            id: 'brand-photography',
            "show": false
        },
        {
            name: "Amplifier",
            id: 'brand-amplifier',
            "show": false
        },
        {
            name: "Iconography",
            id: 'brand-iconography',
            "show": false
        },
    ]);
    const [optionsDownloads, setOptionsDownloads] = useState([
        {
            name: "Logos",
            id: 'brand-logos',
            "show": false
        },
        {
            name: "Brand Powerpoint",
            id: 'brand-power',
            "show": false
        },
        {
            name: "Brand Teams",
            id: 'brand-teams',
            "show": false
        },
        {
            name: "Brand Letterhead",
            id: 'brand-letterhead',
            "show": false
        },
        {
            name: "Brand LinkedIn",
            id: 'brand-linkedin',
            "show": false
        },
        {
            name: "Brand Icons",
            id: 'brand-icons-downloads',
            "show": false
        },
        {
            name: "Brand Flyers",
            id: 'brand-Flyers',
            "show": false
        },
        {
            name: "Brand Assets",
            id: 'brand-assets',
            "show": false
        },
    ]);
    useEffect(() => {
        const fetchData = async () => {
            await SERVICES.getBrandBuildPublic(floatingData.id).then((res) => {
                setSettings(res.data.Settings ?? {});
            }).catch((error) => {

            });
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            let response = await SERVICES.getBrandAllStatusBuild(floatingData.id);
            if (response.status === 200) {
                setOptionsStrategy(values => {
                    values[0].show = response.data.BrandStrategyStatus.statusCore === true ? true : false;
                    values[1].show = response.data.BrandStrategyStatus.statusStatement === true ? true : false;
                    values[2].show = response.data.BrandStrategyStatus.statusMisVis === true ? true : false;
                    values[3].show = response.data.BrandStrategyStatus.statusValues === true ? true : false;
                    values[4].show = response.data.BrandStrategyStatus.statusManifiesto === true ? true : false;
                    values[5].show = response.data.BrandStrategyStatus.statusPersonality === true ? true : false;
                    values[6].show = response.data.BrandStrategyStatus.statusTagline === true ? true : false;
                    return ([
                        ...values
                    ]);
                });

                setOptionsStandards(values => {
                    values[0].show = response.data.BrandStandardStatus.statusCore === true ? true : false;
                    values[1].show = response.data.BrandStandardStatus.statusIdentity === true ? true : false;
                    values[2].show = response.data.BrandStandardStatus.statusVersions === true ? true : false;
                    values[3].show = response.data.BrandStandardStatus.statusConstruction === true ? true : false;
                    values[4].show = response.data.BrandStandardStatus.statusURLTreatment === true ? true : false;
                    values[5].show = response.data.BrandStandardStatus.statusDonts === true ? true : false;
                    values[6].show = response.data.BrandStandardStatus.statusPalletColor === true ? true : false;
                    values[7].show = response.data.BrandStandardStatus.statusTypography === true ? true : false;
                    values[8].show = response.data.BrandStandardStatus.statusPhotography === true ? true : false;
                    values[9].show = response.data.BrandStandardStatus.statusAmplifiers === true ? true : false;
                    values[10].show = response.data.BrandStandardStatus.statusIconography === true ? true : false;
                    return ([
                        ...values
                    ]);
                });

                setOptionsDownloads(values => {
                    values[0].show = response.data.BrandResourceStatus.statusLogos.logo_black_show === true ||
                        response.data.BrandResourceStatus.statusLogos.logo_isotype_show === true ||
                        response.data.BrandResourceStatus.statusLogos.logo_show === true ||
                        response.data.BrandResourceStatus.statusLogos.logo_white_show === true ? true : false;
                    values[1].show = response.data.BrandResourceStatus.statusPowerPoint === true ? true : false;
                    values[2].show = response.data.BrandResourceStatus.statusTeam === true ? true : false;
                    values[3].show = response.data.BrandResourceStatus.statusLetter === true ? true : false;
                    values[4].show = response.data.BrandResourceStatus.statusLinkedln === true ? true : false;
                    values[5].show = response.data.BrandResourceStatus.statusIconography === true ? true : false;
                    values[6].show = response.data.BrandResourceStatus.statusFlyers === true ? true : false;
                    values[7].show = response.data.BrandResourceStatus.statusAssets === true ? true : false;
                    return ([
                        ...values
                    ]);
                });

            }
        }
        fetchData();
    }, [])

    return (
        <div className='sidebar_profile_menu brands_sidebar'>
            <StickyBox offsetTop={10} offsetBottom={20}>
                {settings.logo === null ?
                    <p className='brand_subtheme'>{settings.title}</p>
                    :
                    <img src={settings.logo} className='logo_sidebar' />
                }
                <nav className='navbar'>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className='brand_subtheme' onClick={(e) => { e.preventDefault(); history.push('/Client/' + floatingData.id + '/BrandStrategy') }}>BRAND STRATEGY</li>

                        {optionsStrategy.map((item, index) => (
                            <li key={index} className='nav-item d-flex'>
                                {url.includes('BrandStrategy') === true ?
                                    <Link
                                        to={item.id}
                                        className={item.show === true ? 'me-auto' : 'hide-item'}
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={300}
                                        onClick={closeMenu}
                                    >{item.name}</Link>
                                    :
                                    <NavHashLink
                                        to={'/Client/' + floatingData.id + '/BrandStrategy/' + "#" + item.id}
                                        className={item.show === true ? 'me-auto' : 'hide-item'}
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={300}
                                        onClick={closeMenu}
                                    >{item.name}</NavHashLink>
                                }
                            </li>
                        ))}

                        <li className='brand_subtheme' onClick={(e) => { e.preventDefault(); history.push('/Client/' + floatingData.id + '/BrandStandard') }}>BRAND STANDARDS</li>
                        {optionsStandards.map((item, index) => (
                            <li key={index} className='nav-item d-flex'>
                                {url.includes('BrandStandard') === true ?
                                    <Link
                                        to={item.id}
                                        className={item.show === true ? 'me-auto' : 'hide-item'}
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={300}
                                        onClick={closeMenu}
                                    >{item.name}
                                    </Link>
                                    :
                                    <NavHashLink
                                        to={'/Client/' + floatingData.id + '/BrandStandard/' + "#" + item.id}
                                        className={item.show === true ? 'me-auto' : 'hide-item'}
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={300}
                                        onClick={closeMenu}
                                    >{item.name}
                                    </NavHashLink>
                                }

                            </li>
                        ))}

                        <li className='brand_subtheme' onClick={(e) => { e.preventDefault(); history.push('/Client/' + floatingData.id + '/BrandDownloads') }}>DOWNLOADS</li>
                        {optionsDownloads.map((item, index) => (
                            <li key={index} className='nav-item d-flex'>
                                {url.includes('BrandDownloads') === true ?
                                    <Link
                                        to={item.id}
                                        className={item.show === true ? 'me-auto' : 'hide-item'}
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={300}
                                        onClick={closeMenu}
                                    >{item.name}</Link>
                                    :
                                    <NavHashLink
                                        to={'/Client/' + floatingData.id + '/BrandDownloads/' + "#" + item.id}
                                        className={item.show === true ? 'me-auto' : 'hide-item'}
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={300}
                                        onClick={closeMenu}
                                    >{item.name}</NavHashLink>
                                }
                            </li>
                        ))}
                    </ul>
                </nav>

            </StickyBox>
        </div>
    )
}
