import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FooterDarkDownloads, HomeEmptyProyects, ProjectsGrid } from '../../Layouts';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import './Home.style.css';

export const Home = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [projects, setProjects] = useState([]);

    const handleError = async (message) => {
        dispatch(logOut());
        localStorage.clear();
        Swal.fire({ title: message, icon: 'error' });
        history.push('/Login');
    };

    const fetchData = async () => {
        await SERVICES.getMyBrands().then((res) => {
            
            if (res.status === 401) return handleError(res.message.message);
            if (res.status !== 200) return Swal.fire({ title: 'Something wrong', icon: 'error' });
            setProjects(res.data);
        }).catch((error) => {
            console.log('error home ->', error);
        });
    }

    useEffect(() => {
        
        // localStorage.clear();
        // history.push('/Login')
        fetchData();
    }, []);
    return (
        <>
            <div className={projects.length === 0 ? 'Home_container' : null}>
                {projects.length === 0 && <HomeEmptyProyects />}
                {projects.length > 0 && <ProjectsGrid fetchData={fetchData} projects={projects} />}
            </div>
            {/* <FooterDarkDownloads /> */}
        </>

    )
};
