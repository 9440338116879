import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';

export const DropzoneIconsDownloads = ({ index, icons, setIcons }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...icons];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].path = base64;
                sebo[index].path_url = reader.result;

                setIcons(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });

    const deleteIcon = async () => {
        if (String(icons[index].path_url).includes('http') === true) {
            await SERVICES.deleteBrandIcon(floatingData.id, icons[index].id_iconsFile).then((res) => {
                let sebo = [...icons];
                sebo.splice(index, 1);
                // fetchData();
                setIcons(sebo);
            }).catch((error) => {
                console.error('error delete all logos ->', error);
            });
        } else {
            let sebo = [...icons];
            sebo.splice(index, 1);
            setIcons(sebo);
        }

    };
    return (
        <>
            <div className="d-flex align-items-center">
                    <p className='fw-bold me-auto'>Icon No. {index + 1}</p>
                    <a onClick={(e) => { e.preventDefault(); deleteIcon() }}
                        className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" />
                    </a>
            </div>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    icons[index].path_url === "" ?
                        <i className="bi bi-image picture_avatar_suerinfo" /> :
                        <img
                            className='img-profile-user'
                            src={String(icons[index].path_url).includes('http') === true ? icons[index].path_url + `?=${performance.now()}` : icons[index].path_url}
                        />
                }
            </div>
        </>
    )
}
