import React, { useState } from 'react';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { BrandCore, BrandDownloads, BrandPersonality, BrandStandard, BrandStatement, BrandStrategy, Manifiesto, MissionVision, Settings, SettingSideBar, FooterDarkDownloads } from '../../Layouts';
import { SERVICES } from '../../Services/Services';
import './SettingsBrand.style.css';

export const SettingsBrand = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    let floatingData = useParams();

    const [allStatus, setAllStatus] = useState({});

    const [optionsStrategy, setOptionsStrategy] = useState([
        {
            "name": "Brand Intro",
            "id": "BrandCore",
            "show": false
        },
        {
            "name": "Brand statement",
            "id": "BrandStatement",
            "show": false
        },
        {
            "name": "Mission & vision",
            "id": "BrandMissionVision",
            "show": false
        },
        {
            "name": "Values",
            "id": "BrandValues",
            "show": false
        },
        {
            "name": "Manifiesto",
            "id": "BrandManifiesto",
            "show": false
        },
        {
            "name": "Personality",
            "id": "BrandPersonality",
            "show": false
        },
        {
            "name": "Tagline",
            "id": "BrandTagline",
            "show": false
        },
    ]);

    const [optionsStandards, setOptionsStandards] = useState([
        {
            name: "The Brand",
            id: 'brand-core-standard',
            "show": false
        },
        {
            name: "Brand Identity",
            id: 'brand-identity',
            "show": false,

        },
        {
            name: "Brand Version",
            id: 'brand-versions',
            "show": false
        },
        {
            name: "Construction",
            id: 'brand-construction',
            "show": false
        },
        {
            name: "URL Treatment",
            id: 'brand-url',
            "show": false
        },
        {
            name: "Don'ts",
            id: 'brand-donts',
            "show": false
        },
        {
            name: "Color Pallete",
            id: 'brand-pallet-color',
            "show": false
        },
        {
            name: "Typography",
            id: 'brand-typography',
            "show": false
        },
        {
            name: "Photography",
            id: 'brand-photography',
            "show": false
        },
        {
            name: "Amplifier",
            id: 'brand-amplifier',
            "show": false
        },
        {
            name: "Iconography",
            id: 'brand-iconography',
            "show": false
        },
    ]);
    const [optionsDownloads, setOptionsDownloads] = useState([
        {
            name: "Logos",
            id: 'brand-logos',
            show: allStatus.BrandResourceStatus && (
                allStatus.BrandResourceStatus.statusLogos && (
                    allStatus.BrandResourceStatus.statusLogos.logo_black_show ||
                    allStatus.BrandResourceStatus.statusLogos.logo_isotype_show ||
                    allStatus.BrandResourceStatus.statusLogos.logo_show ||
                    allStatus.BrandResourceStatus.statusLogos.logo_white_show
                )
            ),
        },
        {
            name: "Brand Teams",
            id: 'brand-teams',
            show: allStatus.BrandResourceStatus && allStatus.BrandResourceStatus.statusTeam ? allStatus.BrandResourceStatus.statusTeam : false,
        },
        {
            name: "Brand Letterhead",
            id: 'brand-letterhead',
            show: allStatus.BrandResourceStatus && allStatus.BrandResourceStatus.statusLetter ? allStatus.BrandResourceStatus.statusLetter : false,
        },
        {
            name: "Brand LinkedIn",
            id: 'brand-linkedin',
            show: allStatus.BrandResourceStatus && allStatus.BrandResourceStatus.statusLinkedln ? allStatus.BrandResourceStatus.statusLinkedln : false,
        },
        {
            name: "Brand Icons",
            id: 'brand-icons-downloads',
            show: allStatus.BrandResourceStatus && allStatus.BrandResourceStatus.statusIconography ? allStatus.BrandResourceStatus.statusIconography : false,
        },
        {
            name: "Brand Flyers",
            id: 'brand-Flyers',
            show: allStatus.BrandResourceStatus && allStatus.BrandResourceStatus.statusFlyers ? allStatus.BrandResourceStatus.statusFlyers : false,
        },
        {
            name: "Brand Assets",
            id: 'brand-assets',
            show: allStatus.BrandResourceStatus && allStatus.BrandResourceStatus.statusAssets ? allStatus.BrandResourceStatus.statusAssets : false,
        },
    ]);

    const fetchData = async () => {

        let response = await SERVICES.getBrandAllStatus(floatingData.id);
        if (response.status === 200) {
            setAllStatus(response.data);
            setOptionsStrategy(values => {
                values[0].show = response.data.BrandStrategyStatus.statusCore === true ? true : false;
                values[1].show = response.data.BrandStrategyStatus.statusStatement === true ? true : false;
                values[2].show = response.data.BrandStrategyStatus.statusMisVis === true ? true : false;
                values[3].show = response.data.BrandStrategyStatus.statusValues === true ? true : false;
                values[4].show = response.data.BrandStrategyStatus.statusManifiesto === true ? true : false;
                values[5].show = response.data.BrandStrategyStatus.statusPersonality === true ? true : false;
                values[6].show = response.data.BrandStrategyStatus.statusTagline === true ? true : false;

                return ([
                    ...values
                ]);
            });

            setOptionsStandards(values => {
                values[0].show = response.data.BrandStandardStatus.statusCore === true ? true : false;
                values[1].show = response.data.BrandStandardStatus.statusIdentity === true ? true : false;
                values[2].show = response.data.BrandStandardStatus.statusVersions === true ? true : false;
                values[3].show = response.data.BrandStandardStatus.statusConstruction === true ? true : false;
                values[4].show = response.data.BrandStandardStatus.statusURLTreatment === true ? true : false;
                values[5].show = response.data.BrandStandardStatus.statusDonts === true ? true : false;
                values[6].show = response.data.BrandStandardStatus.statusPalletColor === true ? true : false;
                values[7].show = response.data.BrandStandardStatus.statusTypography === true ? true : false;
                values[8].show = response.data.BrandStandardStatus.statusPhotography === true ? true : false;
                values[9].show = response.data.BrandStandardStatus.statusAmplifiers === true ? true : false;
                values[10].show = response.data.BrandStandardStatus.statusIconography === true ? true : false;
                return ([
                    ...values
                ]);
            });

            setOptionsDownloads(values => {
                values[0].show = response.data.BrandResourceStatus.statusLogos.logo_black_show === true ||
                    response.data.BrandResourceStatus.statusLogos.logo_isotype_show === true ||
                    response.data.BrandResourceStatus.statusLogos.logo_show === true ||
                    response.data.BrandResourceStatus.statusLogos.logo_white_show === true ? true : false;
                values[1].show = response.data.BrandResourceStatus.statusTeam === true ? true : false;
                values[2].show = response.data.BrandResourceStatus.statusLetter === true ? true : false;
                values[3].show = response.data.BrandResourceStatus.statusLinkedln === true ? true : false;
                values[4].show = response.data.BrandResourceStatus.statusIconography === true ? true : false;
                values[5].show = response.data.BrandResourceStatus.statusFlyers === true ? true : false;
                values[6].show = response.data.BrandResourceStatus.statusAssets === true ? true : false;
                return ([
                    ...values
                ]);
            });

        }
    }

    useEffect(() => {
        fetchData();
    }, [window.location.href])

    return (
        <div className='d-flex flex-column'>
            <div className='my_account_container container'>
                <SettingSideBar
                    fetchData={fetchData}
                    optionsDownloads={optionsDownloads}
                    optionsStandards={optionsStandards}
                    optionsStrategy={optionsStrategy}
                />
                <div className='content_forms'>
                    <Switch>
                        <Route exact path={path + '/BrandStrategy'}>
                            <BrandStrategy fetchDataSidebar={fetchData} />
                        </Route>
                        <Route exact path={path + '/BrandStandard'}>
                            <BrandStandard fetchDataSidebar={fetchData} />
                        </Route>
                        <Route exact path={path + '/BrandDownloads'}>
                            <BrandDownloads fetchDataSidebar={fetchData} />
                        </Route>
                        <Route exact path={path + '/Settings'} component={Settings} />
                    </Switch>
                </div>

            </div>
            <FooterDarkDownloads />
        </div>

    )
}
