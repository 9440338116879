import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { BrandAssets } from '../BrandAssets';
import { BrandFlyers } from '../BrandFlyers/BrandFlyers';
import { BrandIconsDownloads } from '../BrandIconsDownloads/BrandIconsDownloads';
import { BrandLetterhead } from '../BrandLetterhead';
import { BrandLinkedin } from '../BrandLinkedin';
import { BrandLogos } from '../BrandLogos';
import { BrandPowerPoint } from '../BrandPowerPoint';
import { BrandTeams } from '../BrandTeams/BrandTeams';
import { getCookie } from '../../utils/cookies';
import StickyBox from "react-sticky-box";
import LoadingModal from '../../Components/LoadingModal';

export const BrandDownloads = ({ fetchDataSidebar }) => {
    const history = useHistory();
    let floatingData = useParams();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const [url_friendly, setUrl_friendly] = useState("");
    const [project, setProject] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    //Brand Power Point
    const [pathPp, setPathPp] = useState("");
    const [pathPpUrl, setPathPpUrl] = useState("");
    const [power_show, setPower_show] = useState(false);
    //End power point

    //Brand Logos
    const [logo, setLogo] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [logo_zip, setLogo_zip] = useState("");
    const [logo_show, setLogo_show] = useState(false);
    const [logo_black, setLogo_black] = useState("");
    const [logo_black_url, setLogo_black_url] = useState("");
    const [logo_black_zip, setLogo_black_zip] = useState("");
    const [logo_black_show, setLogo_black_show] = useState(false);
    const [logo_white, setLogo_white] = useState("");
    const [logo_white_url, setLogo_white_url] = useState("");
    const [logo_white_zip, setLogo_white_zip] = useState("");
    const [logo_white_show, setLogo_white_show] = useState(false);
    const [logo_isotype, setLogo_isotype] = useState("");
    const [logo_isotype_url, setLogo_isotype_url] = useState("");
    const [logo_isotype_zip, setLogo_isotype_zip] = useState("");
    const [logo_isotype_show, setLogo_isotype_show] = useState(false);
    //End Brand Logos

    //Brand Teams
    const [teams_show, setTeams_show] = useState(false);
    const [path_teams, setPath_teams] = useState("");
    const [path_teams_url, setPath_teams_url] = useState("");
    //End Brand Teams


    //Brand LetterHead
    const [letter_show, setLetter_show] = useState(false);
    const [path_letterhead, setPath_letterhead] = useState("");
    const [path_letterhead_url, setPath_letterhead_url] = useState("");
    //End Brand LetterHead

    //Brand Linkedin
    const [linked_show, setLinked_show] = useState(false);
    const [path_linkedin, setPath_linkedin] = useState("");
    const [path_linkedin_url, setPath_linkedin_url] = useState("");
    //End Linkedin


    //Brand Icons
    const [icon_show, setIcon_show] = useState(false);
    const [icons, setIcons] = useState([
        {
            "id_iconsFile": null,
            "path": "",
            "path_url": "",
        }
    ]);
    //End Icons


    //Brand Flyers

    const [flyers_show, setFlyers_show] = useState(false);
    const [flyers, setFlyers] = useState([
        {
            "flyers_show": false,
            "id_flyers": null,
            "path": "",
            "path_url": "",
        }
    ]);
    //End Brand Flyers

    //Brand Assets
    const [assets_show, setAssets_show] = useState(false);
    const [assets, setAssets] = useState([
        {
            "asset_title": "",
            "id_asset": null,
            "path": "",
            "path_url": ""
        },
    ]);
    //End brand assets

    const saveAll = async () => {
        //POST logos
        await SERVICES.addBrandLogos({
            "logo": logo,
            "logo_black": logo_black,
            "logo_white": logo_white,
            "logo_isotype": logo_isotype,
            "logo_show": logo_show,
            "logo_black_show": logo_black_show,
            "logo_white_show": logo_white_show,
            "logo_isotype_show": logo_isotype_show,
            "logo_zip": logo_zip,
            "logo_black_zip": logo_black_zip,
            "logo_white_zip": logo_white_zip,
            "logo_isotype_zip": logo_isotype_zip,
        }, floatingData.id).then((res) => {
            console.error('res save add brands logos ->', res);
        });
        //End Post logos
        //Add Brand PowerPoint
        await SERVICES.addBrandPowerPoint({
            "path": pathPp,
            "power_show": power_show,
        }, floatingData.id).then((res) => {
            // console.log('res save add  PowerPoint ->', res);
        });
        //End pp
        //POST Teams
        await SERVICES.addBrandTeams({
            teams_show: teams_show,
            path: path_teams,
        }, floatingData.id).then((res) => {
            // console.log('res save teams ->', res);
        }).catch((error) => {
            console.error('error ->', error);
        });
        //End post teams

        //POST Letterhead
        await SERVICES.addBrandLetterhead({
            letter_show: letter_show,
            path: path_letterhead,
        }, floatingData.id).then((res) => {
            // console.log('res save letterhead ->', res);
        });
        //End post letterhead

        //POST Linkedin
        await SERVICES.addBrandLinkedin({
            linked_show: linked_show,
            path: path_linkedin,
        }, floatingData.id).then((res) => {
            // console.log('res save linkedin ->', res);
        });
        //End post linkedin

        //POST Brand FLyers
        await SERVICES.addBrandFlyers({
            "status": [
                {
                    "Flyers_show": flyers_show
                }
            ],
            "Flyers": flyers
        }, floatingData.id).then((res) => {
            // console.log('res save flyers ->', res);
        }).catch((error) => { });
        //end post flyers
        //POST Brand Icons

        await SERVICES.addBrandIconsDownload({
            "status": [
                {
                    "icon_show": icon_show
                }
            ],
            "Icons": icons
        }, floatingData.id).then((res) => {
            // console.log('res save icons ->', res);
        });
        //end post icons

        //POST assets
        await SERVICES.addBrandAssets({
            "status": [
                {
                    "assets_show": assets_show
                }
            ],
            "Assets": assets
        }, floatingData.id).then((res) => {
            // console.log('res save assets ->', res);
        });

        //end post assets

        fetchData();

    };

    const killSession = async () => {
        Swal.fire({ title: 'Your token expired', icon: 'error', confirmButtonColor: 'red' });
        dispatch(logOut());
        history.push('/Login');
    }

    const fetchData = async () => {
        let isValidToken = true;
        //GET logos
        await SERVICES.getBrandLogosDownloads(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                setLogoUrl(res.data.logo ?? "");
                setLogo_show(res.data.logo_show ?? false);
                setLogo_black_url(res.data.logo_black ?? "");
                setLogo_black_show(res.data.logo_black_show ?? false);
                setLogo_white_url(res.data.logo_white ?? "");
                setLogo_white_show(res.data.logo_white_show ?? false);
                setLogo_isotype_url(res.data.logo_isotype ?? "");
                setLogo_isotype_show(res.data.logo_isotype_show ?? false);
            }
        });
        //End logos
        //Get brand powerpoint
        await SERVICES.getBrandPowerPoint(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                // setPath_teams_url(res.data.path ?? "");
                // setTeams_show(res.data.teams_show ?? true);
            }
        });
        //end powerpoint
        //GET Teams
        await SERVICES.getBrandTeams(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                setPath_teams_url(res.data.path ?? "");
                setTeams_show(res.data.teams_show ?? false);
            }
        });
        //End teams

        //GET letterhead
        await SERVICES.getBrandLetterhead(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                setLetter_show(res.data.letter_show ?? false);
                setPath_letterhead_url(res.data.path ?? "");
            }
        });
        //End letterhead

        //Brand Linkedin
        await SERVICES.getBrandLinkedin(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                setPath_linkedin_url(res.data.path ?? "");
                setLinked_show(res.data.linked_show ?? false);
            }
        });
        //End brand linkedin

        //Brand Icons
        await SERVICES.getBrandIconsDownloads(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data.Icons.length > 0) {
                let iconos = [];
                res.data.Icons.map((item, index) => {
                    iconos.push({
                        "id_iconsFile": item.id_iconsFile,
                        "path": "",
                        "path_url": item.path,
                    });
                });
                setIcons(iconos);

            }
            setIcon_show(res.data.IconStatus ? res.data.IconStatus.icon_show : false);
        });
        //End brand icons

        //GET flyers
        await SERVICES.getBrandFlyers(floatingData.id).then((res) => {
            
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                let flyersmio = [];
                setFlyers_show(res.data.StatusFlyers.Flyers_show ?? false);
                res.data.Flyers.map((item, index) => {
                    flyersmio.push({
                        "id_flyers": item.id_flyers,
                        "path": "",
                        "path_url": item.path,
                    });
                });
                if (flyersmio.length > 0) {
                    setFlyers(flyersmio);
                }
            }
        });
        //End flyers

        //GET Assets
        await SERVICES.getBrandAssets(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                let newAssets = [];
                res.data.Assets.map((item, index) => {
                    newAssets.push({
                        "asset_title": item.asset_title,
                        "id_asset": item.id_asset,
                        "path": "",
                        "path_url": item.path
                    });
                });
                if (newAssets.length > 0) {
                    setAssets(newAssets);
                }
                // console.log('res data assets ->', res.data);
                setAssets_show(res.data.StatusAssets === null ? false : res.data.StatusAssets.assets_show);
            }
        }).catch((error) => {
            console.error('error assets ->', error)
        });

        await SERVICES.getMyBrandsSettings(floatingData.id).then((res) => {

            if (res.data.found === true) {
                setProject(res.data.data_settings);
                setUrl_friendly(res.data.url_friendly);
            }
        }).catch((error) => {
            console.error('Error ->', error);
        });
        //End get assets
    }

    useEffect(() => {
        fetchData();
    }, []);

    const deleteLogo = async () => {

    }
    const copyToClipboard = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        navigator.clipboard.writeText(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + url_friendly + '/BrandStrategy');
        // navigator.clipboard.writeText(`http://localhost:3000/Client/` + url_friendly + '/BrandStrategy');
    };
    const ViewProject = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        window.open(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + url_friendly + '/BrandDownloads');
        //window.open(`http://localhost:3000/Client/` + url_friendly + '/BrandDownloads', '_blank');
    }

    return (
        <div>
            <LoadingModal isLoading={isLoading} />
            <StickyBox offsetTop={0} offsetBottom={20}>
                <div className="title_submenu_container">
                    <p className='submenu_title'>{project.title} Guideline</p>
                    <p className='me-1' onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard();
                        Swal.fire({ title: "URL Coppied Correctly", icon: "success", showCancelButton: false, showConfirmButton: false, text: 'You can share de public URL now', timer: 2500, })
                    }}><i className="bi bi-share" /> Share</p>
                    <p style={{cursor: "pointer"}} className='me-1' onClick={(e) => {
                        e.preventDefault();
                        ViewProject()
                    }}>
                        <i style={{cursor: "pointer"}} className="bi bi-search" /> Preview</p>
                    <button onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/Settings') }} className='settings_button me-1'>
                        <span className='bi bi-gear gearIcon'></span><span className='me-1'>Settings</span>
                    </button>
                    <button onClick={(e) => {
                        e.preventDefault();
                        setIsLoading(true);
                        saveAll();
                        setTimeout(() => {
                            fetchDataSidebar();
                            setIsLoading(false);
                            Swal.fire({ title: "Info Saved Correctly", icon: "success", confirmButtonColor: "#000000" })
                        }, 10000);
                    }} id='button-save-brandform' className='save_exit_button'>
                        SAVE
                    </button>
                </div>
            </StickyBox>
            <div id='brand-logos'>
                <BrandLogos
                    fetchData={fetchData}
                    logo={logo}
                    setLogo={setLogo}
                    logoUrl={logoUrl}
                    setLogoUrl={setLogoUrl}
                    logo_show={logo_show}
                    setLogo_show={setLogo_show}
                    logo_black={logo_black}
                    setLogo_black={setLogo_black}
                    logo_black_url={logo_black_url}
                    setLogo_black_url={setLogo_black_url}
                    logo_black_show={logo_black_show}
                    setLogo_black_show={setLogo_black_show}
                    logo_white={logo_white}
                    setLogo_white={setLogo_white}
                    logo_white_url={logo_white_url}
                    setLogo_white_url={setLogo_white_url}
                    logo_white_show={logo_white_show}
                    setLogo_white_show={setLogo_white_show}
                    logo_isotype={logo_isotype}
                    setLogo_isotype={setLogo_isotype}
                    logo_isotype_url={logo_isotype_url}
                    setLogo_isotype_url={setLogo_isotype_url}
                    logo_isotype_show={logo_isotype_show}
                    setLogo_isotype_show={setLogo_isotype_show}
                    logo_black_zip={logo_black_zip}
                    logo_isotype_zip={logo_isotype_zip}
                    logo_white_zip={logo_white_zip}
                    logo_zip={logo_zip}
                    setLogo_black_zip={setLogo_black_zip}
                    setLogo_isotype_zip={setLogo_isotype_zip}
                    setLogo_white_zip={setLogo_white_zip}
                    setLogo_zip={setLogo_zip}
                />
            </div>

            <BrandPowerPoint
                pathPp={pathPp}
                pathPpUrl={pathPpUrl}
                power_show={power_show}
                setPathPp={setPathPp}
                setPathPpUrl={setPathPpUrl}
                setPower_show={setPower_show}
            />
            <div id='brand-teams'>
                <BrandTeams
                    fetchData={fetchData}
                    path_teams={path_teams}
                    path_teams_url={path_teams_url}
                    setPath_teams={setPath_teams}
                    setPath_teams_url={setPath_teams_url}
                    setTeams_show={setTeams_show}
                    teams_show={teams_show}
                />
            </div>
            <div id='brand-letterhead'>
                <BrandLetterhead
                    fetchData={fetchData}
                    letter_show={letter_show}
                    path_letterhead={path_letterhead}
                    path_letterhead_url={path_letterhead_url}
                    setLetter_show={setLetter_show}
                    setPath_letterhead={setPath_letterhead}
                    setPath_letterhead_url={setPath_letterhead_url}
                />
            </div>
            <div id='brand-linkedin'>
                <BrandLinkedin
                    fetchData={fetchData}
                    linked_show={linked_show}
                    path_linkedin={path_linkedin}
                    path_linkedin_url={path_linkedin_url}
                    setLinked_show={setLinked_show}
                    setPath_linkedin={setPath_linkedin}
                    setPath_linkedin_url={setPath_linkedin_url}
                />
            </div>
            <div id='brand-icons-downloads'>
                <BrandIconsDownloads
                    fetchData={fetchData}
                    icons={icons}
                    setIcons={setIcons}
                    icon_show={icon_show}
                    setIcon_show={setIcon_show}
                />
            </div>
            <div id='brand-Flyers'>
                <BrandFlyers
                    fetchData={fetchData}
                    flyers={flyers}
                    setFlyers={setFlyers}
                    flyers_show={flyers_show}
                    setFlyers_show={setFlyers_show}
                />
            </div>
            <div id='brand-assets'>
                <BrandAssets
                    fetchData={fetchData}
                    assets={assets}
                    setAssets={setAssets}
                    assets_show={assets_show}
                    setAssets_show={setAssets_show}
                />
            </div>

            <div className='footer_container'>
                <div className='row'>
                    <div className='btn_canel_footer_container col-md-3'>
                        <button className='btn_cancel_footer'
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/MyBrands/' + floatingData.id + '/BrandStandard')
                            }}>
                            Back
                        </button>
                    </div>
                    <div className='text_footer col-md-6'>
                        <p className='brand_center'>BRAND CENTER</p>
                        <p className='desc_brand_center'>Are you finish?</p>
                    </div>
                    <div className='btn_save_footer_container col-md-3'>
                        <button className='btn_save_footer'
                            onClick={(e) => {
                                e.preventDefault();
                                setIsLoading(true);
                                saveAll();
                                setTimeout(() => {
                                    fetchDataSidebar();
                                    setIsLoading(false);
                                    Swal.fire({ title: "Downloads Saved Correctly", icon: "success", confirmButtonColor: "#000000" });
                                    history.push('/Home/')
                                }, 10000);
                            }}>
                            Finish
                        </button>
                    </div>
                </div>

            </div>
            {/* <div className='d-flex justify-content-end pb-4'>
                <button onClick={(e) => {
                    e.preventDefault();
                    saveAll();
                    Swal.fire({ title: "Info Saved Correctly", icon: "success", confirmButtonColor: "#000000" })
                }} id='button-save-settings-brands' className='save_exit_button'>
                    SAVE
                </button>
            </div> */}
        </div>
    )
}
