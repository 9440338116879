import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const LoadingModal = ({ isLoading = false }) => {
    return (
        <Modal
            show={isLoading}
            backdrop="static"
            keyboard={false}
            centered={true}
        >
            <div className="d-flex p-5">
                <Spinner className='mx-auto my-auto' animation="border" role="status" />
            </div>
        </Modal>
    )
}

export default LoadingModal