import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../../Services/Services';
import { logOut } from '../../../store/auth/ProfileStatus';
import './NewBrandForm.style.css';

export const NewBrandForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [brandName, setBrandName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const closeSession = async(res) => {
        localStorage.clear();
        dispatch(logOut());
        Swal.fire({title:`${res.message.message}`, icon:'error'})
        history.push('Login');
    };

    // Crea el proyecto
    const createBrandGuideline = async() => {
        if(brandName.trim() === "") return Swal.fire({title:`The title can't be empty`, timer:5000, icon: 'error', confirmButtonColor:'#000000'});
        await SERVICES.createBrand({title: brandName}).then((res) => {
            let brand = res.data.brand_content.url_brand
            
            if(res.status === 401) return closeSession(res);
            if(res.status !== 200) return Swal.fire({title:'Something wrong', icon:'error'})
            Swal.fire({title:'Brand created!', icon:'success', timer:5000});
            // history.push('/Home'); 
            history.push(`/MyBrands/${brand}/BrandStrategy`);
        }).catch((error) => {
            console.error('error form brand ->', error)
        })
    }

    return (
        <div className='form_brand_container'>
            <p className='new_brand_title mb-3'>Title of your new Brand Guideline</p>
            <input
                value={brandName}
                onChange={(value) => setBrandName(value.target.value)}
                className='form-control input_brand_form mb-3'
            />
            <div className='btn_brand_form_container mt-4'>
                <button onClick={(e) => {e.preventDefault(); history.goBack()}} className='btn_brand_form btn btn-secondary text-secondary'>
                    Cancel
                </button>
                <button onClick={(e) => {e.preventDefault(); createBrandGuideline()}} className='btn_brand_form bg-black text-white'>
                    Create
                </button>
            </div>
        </div>
    )
}
