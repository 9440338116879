import React from "react";
import { Route, Redirect } from "react-router-dom";

import { getCookie } from '../utils/cookies';

const Loged = ({ component: Component, ...restOfProps }) => {
    let cookieData = getCookie('Authorization');
    const jsonCookie = JSON.parse(cookieData)
    const isAuthenticated = !!jsonCookie
    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/Login" />
                )
            }
        />
    );
}


export default Loged;
