import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';
import { StringJumpLine } from './StringJumpLine';

export const DropZoneDonts = ({ index, donts, setDonts }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...donts];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].image64 = base64;
                sebo[index].imgaUrl = reader.result;

                setDonts(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });


    const deleteBrandDont = async () => {
        if (String(donts[index].imgaUrl).includes('http') === true) {
            await SERVICES.deleteBrandDont(floatingData.id, donts[index].id_dont).then((res) => {
                
                let sebo = [...donts];
                sebo.splice(index, 1);
                // fetchData();
                setDonts(sebo);
            }).catch((error) => {
                console.log('error delete dont ->', error);
            });
        } else {
            let sebo = [...donts];
            sebo.splice(index, 1);
            setDonts(sebo);
        }
    };


    return (
        <div>
            <div className="d-flex justifi-content-center">
                <p className='logo_dont_aplication me-auto'>Logo Don`t Application Image</p> 
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteBrandDont();
                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>

            <div className="img_infouser_container bg-white mb-4" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    donts[index].imgaUrl === "" ?
                        <i className="bi bi-image picture_avatar_suerinfo" /> :
                        <img
                            className='img-profile-user'
                            src={donts[index].imgaUrl}
                        />
                }
            </div>

            <div className="d-flex justifi-content-center">
                <p className='logo_dont_aplication'>Don`t Application Description</p>
            </div>
            <textarea
                value={donts[index].text_dont}
                onChange={(e) => {
                    let sebo = [...donts];
                    sebo[index].text_dont = e.target.value;
                    setDonts(sebo);
                }}
                className='form-control input_settings'
                placeholder='Sample: We are a creative agency'
                rows={3}
                onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                        let sebo = [...donts];
                        sebo[index].text_dont = e.target.value;
                        setDonts(sebo);
                    }
                }}
            />

        </div>
    )
}
