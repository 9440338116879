import React from 'react'
import { useParams } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export const BrandPalleteColor = ({ text_primary_show, setText_primary_show, pallet, setPallet }) => {
    let floatingData = useParams();
    let newOne = {
        "title_category": "",
        "id_category": null,
        "colors": [
            {
                "id_color": null,
                "title_color": "",
                "color_rgb": "",
                "color_hex": "",
                "color_cmyk": "",
                "pantone": ""
            },
        ]
    };
    let newOneColor = {
        "id_color": null,
        "title_color": "",
        "color_rgb": "",
        "color_hex": "",
        "color_cmyk": "",
        "pantone": ""
    }

    const deleteCategory = async (index) => {
        
        if (pallet[index].id_category !== null) {
            await SERVICES.deleteColorPalletCategory(floatingData.id, pallet[index].id_category).then((res) => {
                
                if (res.status !== 200) return Swal.fire({ title: 'Sorry, something went wrong', icon: 'error' });
                let newValues = [...pallet];
                newValues.splice(index, 1);
                setPallet(newValues);
            }).catch((error) => {
                console.error('Error ->', error);
            });
        } else {
            let newValues = [...pallet];
            newValues.splice(index, 1);
            setPallet(newValues);
        }
    }
    const deleteCategoryColor = async (index, index2) => {
        
        if (pallet[index].colors[index2].id_color !== null) {
            await SERVICES.deleteColorPalletById(floatingData.id, pallet[index].id_category, pallet[index].colors[index2].id_color).then((res) => {
                
                if (res.status !== 200) return Swal.fire({ title: 'Sorry, something went wrong', icon: 'error' });
                let newValues = [...pallet];
                let newColors = [...pallet[index].colors];
                newColors.splice(index2, 1);
                newValues[index].colors = newColors;
                setPallet(newValues);
            }).catch((error) => {
                console.error('Error ->', error);
            });
        } else {
            let newValues = [...pallet];
            let newColors = [...pallet[index].colors];
            newColors.splice(index2, 1);
            newValues[index].colors = newColors;
            setPallet(newValues);
        }
    }

    const modalColorPallete=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/14-Color-Pallete-Category-Name.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalColorPalleteName=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/15-Color-Pallete-Name-Color.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalColorPalleteColor=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/16-Color-Pallete-Colors.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }


    return (
        <>
            <div className='inputs_settings_container'>
                <div className="d-flex mb-3">
                    <p className='label_input_settings me-auto'>Color Pallete </p>
                    <div className="d-flex justify-content-end">
                        {!text_primary_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_primary_show(true) }} className="bi bi-square" /></p>}
                        {text_primary_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_primary_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                </div>

                {Array(...pallet).map((item, index) => (
                    <div key={index}>
                        <div className="d-flex align-items-center">
                            <label className='me-auto'>Color Palette Name <Link onClick={(e) => {modalColorPallete();}}><i className="bi bi-exclamation-circle" /></Link></label>
                            <a onClick={(e) => {
                                e.preventDefault();
                                deleteCategory(index);
                            }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
                        </div>
                        <input
                            className='form-control input_settings'
                            placeholder='Samples: Primary Colors, Secondary Colors, Background Colors'
                            value={item.title_category}
                            onChange={(e) => {
                                let sebo = [...pallet];
                                sebo[index].title_category = e.target.value;
                                setPallet(sebo);
                            }}
                        />
                        <div className="row row-cols-1 row-cols-md-3">
                            {Array(...item.colors).map((item2, index2) => (
                                <div key={index2}>
                                    <div className="d-flex align-items-center">
                                        <label className='me-auto'>#{index2 + 1} Color Name Title <Link onClick={(e) => {modalColorPalleteName();}}><i className="bi bi-exclamation-circle" /></Link></label>
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            deleteCategoryColor(index, index2);
                                        }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
                                    </div>
                                    <input
                                        className='form-control input_settings'
                                        placeholder='Sample Grass Green'
                                        value={item2.title_color}
                                        onChange={(e) => {
                                            let sebo = [...pallet];
                                            sebo[index].colors[index2].title_color = e.target.value;
                                            setPallet(sebo);
                                        }}
                                    />
                                    <label>RGB - Red, Green, Blue <Link onClick={(e) => {modalColorPalleteColor();}}><i className="bi bi-exclamation-circle" /></Link></label>
                                    <input
                                        className='form-control input_settings'
                                        value={item2.color_rgb}
                                        onChange={(e) => {
                                            let sebo = [...pallet];
                                            sebo[index].colors[index2].color_rgb = e.target.value;
                                            setPallet(sebo);
                                        }}
                                    />
                                    <label>HEX - (Hexadecimal) <Link onClick={(e) => {modalColorPalleteColor();}}><i className="bi bi-exclamation-circle" /></Link></label>
                                    <input
                                        className='form-control input_settings'
                                        value={item2.color_hex}
                                        onChange={(e) => {
                                            let sebo = [...pallet];
                                            sebo[index].colors[index2].color_hex = e.target.value;
                                            setPallet(sebo);
                                        }}
                                    />
                                    <label>CMYK - Cyan, Magenta, Yellow, Black <Link onClick={(e) => {modalColorPalleteColor();}}><i className="bi bi-exclamation-circle" /></Link></label>
                                    <input
                                        className='form-control input_settings'
                                        value={item2.color_cmyk}
                                        onChange={(e) => {
                                            let sebo = [...pallet];
                                            sebo[index].colors[index2].color_cmyk = e.target.value;
                                            setPallet(sebo);
                                        }}
                                    />
                                    <label>Pantone <Link onClick={(e) => {modalColorPalleteColor();}}><i className="bi bi-exclamation-circle" /></Link></label>
                                    <input
                                        className='form-control input_settings'
                                        value={item2.pantone}
                                        onChange={(e) => {
                                            let sebo = [...pallet];
                                            sebo[index].colors[index2].pantone = e.target.value;
                                            setPallet(sebo);
                                        }}
                                    />
                                </div>
                            ))}
                            <div className="add-more-Container-top mt-3">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    // console.log('click ->',)
                                    let sebo = [...pallet[index].colors];
                                    // console.log('sevo colores ->', sebo);
                                    let allInfo = [...pallet];
                                    // console.log('all info we ->', allInfo);
                                    sebo.push(newOneColor);
                                    allInfo[index].colors = sebo;
                                    setPallet(allInfo);
                                }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Color</a>
                            </div>
                        </div>
                    </div>
                ))}
            <div className="d-flex justify-content-end">
                <a onClick={(e) => {
                    e.preventDefault();
                    
                    let newObj = [...pallet];
                    newObj.push(newOne);
                    
                    setPallet(newObj);
                }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> New Color Pallete</a>
            </div>
        </div >
        </>
    )
}
