import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';

export const DropzoneIsotype = ({ logo_isotype, setLogo_isotype, logo_isotype_url, setLogo_isotype_url, logo_isotype_show, setLogo_isotype_show }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setLogo_isotype_url(reader.result);
                setLogo_isotype(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })
    return (
        // <div className='inputs_settings_container'>
        <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                logo_isotype_url === "" ?
                    <div className='text-center'>
                        <i className="bi bi-image picture_avatar_suerinfo" />
                        <p className='placeholder_img'>Drag & drop your Isotype Image here or browse</p>
                        <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                    </div> :
                    <img
                        className='img-profile-user'
                        src={String(logo_isotype_url).includes("http") === true ? logo_isotype_url + `?=${performance.now()}` : logo_isotype_url}
                    />
            }
        </div>
        // </div>
    )
}
