import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';

export const BrandLinkedin = ({ fetchData, linked_show, setLinked_show, path_linkedin, setPath_linkedin, path_linkedin_url, setPath_linkedin_url }) => {
    let floatingData = useParams();
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setPath_linkedin_url(reader.result);
                setPath_linkedin(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })

    const deleteLinkedin = async () => {
        await SERVICES.deleteBrandLinkedin(floatingData.id).then((res) => {
            setPath_linkedin("");
            setPath_linkedin_url("");
            fetchData();
        }).catch((error) => {
            console.error('error delete all logos ->', error);
        });
    }


    return (
        <div className='inputs_settings_container'>
            <div className="d-flex justifi-content-center">
                <p className='label_input_settings me-auto'>Add LinkedIn Background Banner file <i className="bi bi-exclamation-circle" /></p>
                {!linked_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLinked_show(true) }} className="bi bi-square" /></p>}
                {linked_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLinked_show(false) }} className="bi bi-check-square" /></p>}
            </div>
            <div className="d-flex justify-content-end">
                <a onClick={(e) => { e.preventDefault(); deleteLinkedin() }} className='addNew'>
                    <i className="bi bi-dash-circle-fill sizeCircle" />
                    Remove Linkedin
                </a>
            </div>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    path_linkedin_url === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop your LinkedIn Backgroundhere or browse</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={String(path_linkedin_url).includes("http") === true ? path_linkedin_url + `?=${performance.now()}` : path_linkedin_url}
                        />
                }
            </div>
        </div>
    )
}
