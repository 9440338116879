import React from 'react';
import './MembershipCardContainer.style.css';

export const MembershipCardContainer = ({ membership }) => {
    return (
        <div>
            <div className='card_membership_container col'>
                <p className='title_membership_card'>{membership.title}</p>
                <p className='text_membership_card'>{membership.text}</p>
                <ul className='beneficios'>
                    {membership.content.map((item, index) => (
                        <li key={index}>{item.contentson}</li>
                    ))}
                </ul>
                <div className="button_membership_container d-flex justify-content-center">
                    <button className='btn-membership'>
                        Select
                    </button>
                </div>
            </div>

        </div>
    )
}
