import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { DropZoneHelper } from '../../Helpers/Dropzone';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandVersions = ({ BrandVersionImages, setBrandVersionImages, versions_show, setVersions_show }) => {

    let newone = {
        "id_version": null,
        "title_version": "",
        "image_version": "",
        "image_version_url": "",
        "colors_version": [
            {
                "id_version_colors": null,
                "title_color": "",
                "color_rgb": "",
                "color_hex": "",
                "color_cmyk": "",
                "pantone": ""
            },
        ]
    }
    const modalLogoVersions=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/5-Logo-Versions-Image.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    
    return (
        <>
            <div className='inputs_settings_container'>
                <div className="d-flex">
                    <p className='label_input_settings me-auto'>Brand Logo Versions <Link onClick={(e) => {modalLogoVersions();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    {!versions_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setVersions_show(true) }} className="bi bi-square" /></p>}
                    {versions_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setVersions_show(false) }} className="bi bi-check-square" /></p>}
                </div>


                {Array(...BrandVersionImages).map((item, index) => (
                    <DropZoneHelper key={index}
                        index={index}
                        BrandVersionImages={BrandVersionImages}
                        setBrandVersionImages={setBrandVersionImages}
                    />
                ))}
                <div className="d-flex justify-content-end">
                    <a onClick={(e) => {
                        e.preventDefault();
                        
                        let newObj = [...BrandVersionImages];
                        newObj.push(newone);
                        
                        setBrandVersionImages(newObj);
                    }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Brand Version</a>
                </div>
            </div>
        </>
    )
}
