import React from 'react';
import { useState } from 'react';
import './Invoices.style.css';

export const Invoices = () => {
    const [data, setData] = useState([
        {
            date: "2022/02/02",
            plan: "Free",
            id: 1,
            total: 150
        },
        {
            date: "2022/02/02",
            plan: "Free",
            id: 2,
            total: 150
        },
        {
            date: "2022/02/02",
            plan: "Free",
            id: 3,
            total: 150
        },
        {
            date: "2022/02/02",
            plan: "Free",
            id: 4,
            total: 150
        },
    ])
    return (
        <div className='infouser_container'>
            <div className="infouser_header border-bottom">
                <p className='profileTitle'>My profile</p>
            </div>
            <p className='subtitle_Profile mt-3 mb-4'>Invoices</p>
            <div className='titleTable-Container row'>
                
                <p className='col titleTable'>DATE</p>
                <p className='col titleTable'>INVOICE #</p>
                <p className='col titleTable'>PLAN</p>
                <p className='col titleTable'>TOTAL BILLED</p>
                
            </div>
            <div className='title_table_invoices_content mt-3 row'>
                {/* {data.map((item, index) => (
                    <div className='invoice_line d-flex'>
                        <p className='col invoice'>{item.date}</p>
                        <p className='col invoice'>{item.id}</p>
                        <p className='col invoice free_word'>{item.plan}</p>
                        <p className='col invoice '>{item.total}</p>
                    </div>
                ))} */}
            </div>
        </div>
    )
}
