import React, { useCallback } from 'react'
import { DropZonePhotography } from '../../Helpers/DropzonePhotography';
import { DropZoneUrl } from '../../Helpers/DropzoneUrl';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandPhotography = ({ text_photography, setText_photography, text_photography_show, setText_photography_show, photografies, setPhotografies, bullet, setBullet }) => {
    let newOneBullet = {
        "text_bullet": "",
        "id_Photographybullet": null
    };
    let newone = {
        "id_Photographyimg": null,
        // "description_photography": "",
        "image_photography": "",
        "image_photography_url": "",
    }

    const modalPhotography=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/22-Photography.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalPhotographyBullets=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/23-Photography%20Bullets.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalPhotographyPhotos=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/24-Photography-Photos.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    
    return (
        <>

            <div className='inputs_settings_container'>
                <div className="d-flex mb-3">
                    <p className='label_input_settings me-auto'>Photography <Link onClick={(e) => {modalPhotography();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    <div className="d-flex justifi-content-center">

                        {!text_photography_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_photography_show(true) }} className="bi bi-square" /></p>}
                        {text_photography_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_photography_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                </div>
                <p className='me-auto symbol_title'>Description Photography here</p> 
                <textarea
                    value={text_photography}
                    onChange={(e) => { setText_photography(e.target.value) }}
                    className='form-control input_settings'
                    type="textarea"
                    rows={4}
                    onKeyDown={(e) => StringJumpLine(e.code, e, setText_photography)}
                />
                <div className='row row-cols-1 row-cols-md-2'>
                    {Array(...bullet).map((item, index) => (
                        <div key={index}>
                            <label className='symbol_title'>#{index + 1} Photo Category <Link onClick={(e) => {modalPhotographyBullets();}}><i className="bi bi-exclamation-circle" /></Link></label>
                            <input
                                value={item.text_bullet}
                                onChange={(e) => {
                                    let sebo = [...bullet];
                                    sebo[index].text_bullet = e.target.value;
                                    setBullet(sebo);
                                }}
                                className='form-control input_settings'
                            />
                        </div>
                    ))}
                    
                </div>
                <div className="d-flex justify-content-end mx-4">
                        <a onClick={(e) => {
                            e.preventDefault();
                            
                            let newObj = [...bullet];
                            newObj.push(newOneBullet);
                            
                            setBullet(newObj);

                        }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add Photo Category</a>
                    </div> 
                <p className='value_input'>Photography <Link onClick={(e) => {modalPhotographyPhotos();}}><i className="bi bi-exclamation-circle" /></Link></p>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x820px</p>
                <div className='row row-cols-1 row-cols-md-3'>
                    {Array(...photografies).map((item, index) => (
                        <DropZonePhotography
                            key={index}
                            index={index}
                            photografies={photografies}
                            setPhotografies={setPhotografies}
                        />
                    ))}
                    <div className="add-more-Container-top mt-3">
                        <a onClick={(e) => {
                            e.preventDefault();
                            
                            let newObj = [...photografies];
                            newObj.push(newone);
                            
                            setPhotografies(newObj);

                        }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add Photo</a>
                    </div>


                </div>
            </div>
        </>
    )
}
