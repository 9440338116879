import axios from "axios";
import { URIS } from "./Uris";
axios.defaults.withCredentials = true

export const SERVICES = {
    async initSession(email, password) {
        try {
            let response = await axios.post(URIS.signin, {
                user: email,
                password: password,
            });
            return response.data;
        } catch (error) {
            console.error(error)
        }
    },
    async showUser() {
        try {
            let response = await axios.get(URIS.showUser);
            return response.data;
        } catch (error) {
            console.error(error)
        }
    },
    async createAcccount(email, password, name, agency, subdomain) {
        try {
            let response = await axios.post(URIS.signup, {
                "email": email,
                "password": password,
                "name": name,
                "agency": agency,
                "subdomain": subdomain
            });
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async updateAccount(display_name, name, description, avatar) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {

            let response = await axios.put(URIS.showUser, { name: name, display_name: display_name, description: description, avatar: avatar },);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async changePass(newPass) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.put(URIS.restore_password_session, { password: newPass },);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async validateToken(token) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(URIS.verify_token, { token: token.token },);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async restorePass(data) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.put(URIS.restore_password, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async createBrand(data) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(URIS.createbrand, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async sendCodeVerification(data) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(URIS.forgot_password, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandCore(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_core}/${brandId}`, data,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandCore(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_core}/${brandId}`,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandStatement(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_statement}/${brandId}`, data,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandStatement(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_statement}/${brandId}`,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandMissionVision(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_mission_vision}/${brandId}`, data,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandValues(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_values}/${brandId}`, data,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandMissionVision(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_mission_vision}/${brandId}`);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPersonality(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_personality}/${brandId}`, data,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPersonalityArrays(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_tones}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandPersonality(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_personality}/${brandId}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandManifiesto(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_manifiesto}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandIdentity(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_identity}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandCoreStandar(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_core_standard}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandVersions(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_versions}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandConstructions(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_constructions}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandUrlTreatmentText(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_url_treatments}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandIdentityColors(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_identity_color}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandUrlTreatmentImages(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_url_treatments_image}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandDonts(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_donts}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPhotoText(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_photographys}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPhotographys(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_photographys_images}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPhotographysBullet(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_photographys_bullets}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPrimaryCollor(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_colors_pallete}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandSecondaryCollor(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_secondary_color}/${brandId}`, data,);

            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandTypography(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_typography}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandBackgroundCollor(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_background_color}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandIconographyText(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_iconographys}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandIconographyIcons(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_iconographys_icons}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandAmplifier(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_amplifier}/${brandId}`, data,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPatternText(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_pattern_description}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPatterImages(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_pattern_images}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandBackgroundText(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_background_description}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandBackgroundImages(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_background_images}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandConstructionsSymbols(data, brandId) {
        
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_constructions_symbols}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandManifiesto(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_manifiesto}/${brandId}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandAllStatus(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brands_status}/${brandId}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandAllStatusBuild(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brands_status_build}/${brandId}`);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandLogosDownloads(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_logos_resources}/${brandId}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandLogos(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            
            let response = await axios.post(`${URIS.brand_logos_resources}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandPowerPoint(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_powerpoint_resources}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error(error)
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async downloadLogos(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.download_resource_logos}/${brandId}`, { path: data.path, title: data.title }, { responseType: 'arraybuffer' },);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async downloadIcons(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.download_resource_icons}/${brandId}`, { Iconography: data }, { responseType: 'arraybuffer' },);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async downloadFlyers(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.download_resource_flyers}/${brandId}`, { path: data.path }, { responseType: 'arraybuffer' },);
            
            return { data: response.data, status: response.status, headers: response.headers };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async downloadAssets(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.download_resource_assets}/${brandId}`, { path: data.path, title: data.title }, { responseType: 'arraybuffer' },);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
   
    async getBrandPowerPoint(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_powerpoint_resources}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandTeams(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_teams_resources}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandTeams(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_teams_resources}/${brandId}`, data,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandLinkedin(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_linkedln_resources}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandFlyers(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_flyers_resources}/${brandId}`,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandAssets(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_assets_resources}/${brandId}`,);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandLinkedin(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_linkedln_resources}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandIconsDownload(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_icons_resources}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandAssets(data, brandId) {
        
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_assets_resources}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandLetterhead(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_letterhead_resources}/${brandId}`);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandLetterhead(data, brandId) {
        
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_letterhead_resources}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async updateSettings(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.put(`${URIS.brand_settings}/${brandId}`, data);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async updateUrlFriendly(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.put(`${URIS.brand_url_friendly}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandFlyers(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_flyers_resources}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async addBrandTaglines(data, brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.post(`${URIS.brand_taglines}/${brandId}`, data);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandTaglines(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_taglines}/${brandId}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandIconsDownloads(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_icons_resources}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandCoreStandard(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_core_standard}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandIdentity(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_identity}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandConstruction(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_constructions}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandVersions(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_versions}/${brandId}`,);

            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandBuildPublic(brandId) {
        try {
            // let response = await axios.get(`${URIS.public_brands_strategy}/${brandId}`);
            let response = await axios.get(`${URIS.public_brands_strategy}/${brandId}`);

            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandBuildPublicDownloads(brandId) {
        try {

            let response = await axios.get(`${URIS.brands_public_resources}/${brandId}`);
            // let response = await axios.get(`${URIS.brands_public_resources}/${brandId}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandBuildPublicStandard(brandId) {
        try {
            let response = await axios.get(`${URIS.brands_public_standards}/${brandId}`);
            // let response = await axios.get(`${URIS.brands_public_standards}/${brandId}`);

            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandValues(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_values}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandUrlTreatment(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_url_treatments}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandDonts(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_donts}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getPalleteColors(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_colors_pallete}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandTypography(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_typography}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandPhotography(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_photographys}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandAmplifier(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_amplifier}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getBrandIconography(brandId) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(`${URIS.brand_iconographys}/${brandId}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getMyBrands() {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(URIS.mybrands,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async getMyBrandsSettings(id) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.get(URIS.brand_settings + `/${id}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrand(id) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.delete_brand}/${id}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteTone(idBrand, id) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_tones_personality}/${idBrand}/${id}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteCommunication(idBrand, id) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_tones_communication}/${idBrand}/${id}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandLogos(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_logos_resources}/${idBrand}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandTeamsBack(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_teams_resources}/${idBrand}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandLinkedin(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_linkedln_resources}/${idBrand}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            console.error('error ->', error);
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteAllBrandIcons(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_icons_resources}/${idBrand}`,);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteAllBrandFlyers(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_flyers_resources}/${idBrand}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteAllBrandAssets(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_assets_resources}/${idBrand}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandIcon(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_icons_resources}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandFlyer(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_flyers_resources}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandIdentityColor(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_identity_color}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandTagline(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_taglines}/${idBrand}/${idIcon}`);
            
            return { data: response.data, status: response.status };
        } catch (error) {
            
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandVersion(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_versions}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandUrl(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_url_treatments_image}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandDont(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_donts}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandFullAmplifier(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_amplifier}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandAmplifierImg(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_amplifier_images}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandPatternImg(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_pattern_images}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandBackgroundImg(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_background_images}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandIconographiesIcons(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_iconographys_icons}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandPhotography(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_photographys_images}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandSecondaryCollor(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_secondary_color}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandBackgroundCollor(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_background_color}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandAsset(idBrand, idIcon) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_assets_resources}/${idBrand}/${idIcon}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandLetterHead(idBrand) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_letterhead_resources}/${idBrand}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteBrandValuesById(idBrand, idValue) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.delete_brand_value}/${idBrand}/${idValue}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteSymbolById(idBrand, idValue) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_constructions_symbols}/${idBrand}/${idValue}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteColorPalletCategory(idBrand, idValue) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_colors_pallete}/${idBrand}/${idValue}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
    async deleteColorPalletById(idBrand, idCategory, idColor) {
        let usuario = localStorage.getItem("USER");
        usuario = JSON.parse(usuario);
        try {
            let response = await axios.delete(`${URIS.brand_colors_pallete}/${idBrand}/${idCategory}/${idColor}`);
            return { data: response.data, status: response.status };
        } catch (error) {
            const resultError = { error: true, message: JSON.parse(error.request.response), status: error.response.status }
            return resultError;
        }
    },
};
