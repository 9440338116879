import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';

export const BrandLetterhead = ({ fetchData, letter_show, setLetter_show, path_letterhead, setPath_letterhead, path_letterhead_url, setPath_letterhead_url }) => {
    let floatingData = useParams();

    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setPath_letterhead_url(reader.result);
                setPath_letterhead(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })

    const deleteLeterHead = async () => {
        await SERVICES.deleteBrandLetterHead(floatingData.id).then((res) => {
            setPath_letterhead("");
            setPath_letterhead_url("");
            fetchData();
        }).catch((error) => {
            console.error('error delete all logos ->', error);
        });
    }
    return (
        <div className='inputs_settings_container'>
            <div className="d-flex justifi-content-center">
                <p className='label_input_settings me-auto'>Add Letterhead File <i className="bi bi-exclamation-circle" /></p>
                {!letter_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLetter_show(true) }} className="bi bi-square" /></p>}
                {letter_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLetter_show(false) }} className="bi bi-check-square" /></p>}
            </div>
            <div className="d-flex justify-content-end">
                <a onClick={(e) => { e.preventDefault(); deleteLeterHead() }} className='addNew'>
                    <i className="bi bi-dash-circle-fill sizeCircle" />
                    Remove Letterhead
                </a>
            </div>

            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    path_letterhead_url === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop your Letterhead file here or browse</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={String(path_letterhead_url).includes("http") === true ? path_letterhead_url + `?=${performance.now()}` : path_letterhead_url}
                        />
                }
            </div>
        </div>
    )
}
