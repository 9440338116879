import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';


import { SERVICES } from '../Services/Services';

export const DropZoneSymbolsContructions = ({ index, brandConstructionsSymbols, setBrandConstructionsSymbols }) => {
    let floatingData = useParams();
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...brandConstructionsSymbols];
                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].image64 = base64;
                sebo[index].imgaUrl = reader.result;
                setBrandConstructionsSymbols(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });


    const deleteSymbol = async () => {
        
        if (String(brandConstructionsSymbols[index].imgaUrl).includes('http')) {
            await SERVICES.deleteSymbolById(floatingData.id, brandConstructionsSymbols[index].id_symbol).then((res) => {
                
                if (res.status !== 200) return Swal.fire({ title: 'Sorry, something went wrong', icon: 'error' });
                let newValues = [...brandConstructionsSymbols];
                newValues[index] = {
                    'image64': "",
                    "imgaUrl": "",
                    "id_symbol": null,
                }
                setBrandConstructionsSymbols(newValues);
            }).catch((error) => {
                console.error('Error ->', error);
            });
        } else {
            let newValues = [...brandConstructionsSymbols];
            newValues[index] = {
                'image64': "",
                "imgaUrl": "",
                "id_symbol": null,
            }
            setBrandConstructionsSymbols(newValues);
        }
    }
    return (
        <div>
            <div className="d-flex align-items-center">
                <p className='symbol_title me-auto'>Symbol N.{index + 1}</p>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteSymbol();
                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    brandConstructionsSymbols[index].imgaUrl === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop your Symbol here or browse.</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={brandConstructionsSymbols[index].imgaUrl}
                        />
                }

            </div>
        </div>
    )
}
