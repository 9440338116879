import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { Link } from 'react-router-dom';

export const BrandStatement = ({ killSession, brand_statement, setBrand_statement, photoCover, setPhotoCover, photoCoverUrl, setPhotoCoverUrl, show_brandStatementText, setShow_brandStatementText, show_brandStatementImg, setShow_brandStatementImg }) => {
    let floatingData = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setPhotoCoverUrl(reader.result);
                setPhotoCover(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })

    const handleError = (message, subMessage) => {
        // Swal.fire({title: message, text: subMessage, icon:'error'});
        return setIsLoading(false);
    };

    const fetchData = async () => {
        await SERVICES.getBrandStatement(floatingData.id).then((res) => {
            
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                setBrand_statement(res.data.brand_statement ?? "");
                setPhotoCoverUrl(res.data.photo_statement ?? "");
                setShow_brandStatementText(res.data.brand_statement_show ?? false);
            }

        }).catch((error) => {
            console.error('Error get core ->', error);
        });
    };

    const modalBrandStatement =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/5-Brand-Statement.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalBrandPhoto =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/4-Brand-Statement-Photo-Representative.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });

    }

    useEffect(() => {
       
        fetchData();
    }, []);




    return (
        <div id='BrandStatement' className='submenu_maincontainer'>
            <div className='row row-cols-1 row-cols-md-2'>
            <div className='inputs_settings_container col'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Brand Statement Photo <Link onClick={(e) => {modalBrandPhoto();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    </div>
                    <p className='recomendationText'>We recommended portrait format. Maximum images: 800x1200px</p>
                    <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            photoCoverUrl === "" ?
                                <div className='text-center'>
                                <i className="bi bi-image picture_avatar_suerinfo" />
                                <p className='placeholder_img'>Drag & drop your brand statement photo cover here.</p>
                                <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                                </div>:
                                <img
                                    className='img-profile-user'
                                    src={String(photoCoverUrl).includes("http") === true ? photoCoverUrl + `?=${performance.now()}` : photoCoverUrl}
                                />
                        }
                    </div>
                </div>
                <div className='inputs_settings_container col'>
                    <div className="d-flex justifi-content-center" style={{marginBottom:30}}>
                        <p className='label_input_settings me-auto'>Brand Statement <Link onClick={(e) => {modalBrandStatement();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!show_brandStatementText && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setShow_brandStatementText(true) }} className="bi bi-square" /></p>}
                        {show_brandStatementText && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setShow_brandStatementText(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <p className='recomendationText'></p>
                    <textarea
                        value={brand_statement}
                        onChange={(e) => setBrand_statement(e.target.value)}
                        className='form-control input_settings'
                        type="textarea"
                        rows={6}
                        onKeyDown={(e) => StringJumpLine(e.code, e, setBrand_statement)}
                    />
                </div>
                
            </div>
        </div>
    )
}
