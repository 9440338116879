import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { DropZoneHelper } from '../../Helpers/Dropzone';
import { DropZoneDonts } from '../../Helpers/DropzoneDonts';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandDonts = ({ donts, setDonts, donts_show, setDonts_show }) => {

    let newone = {
        'image64': "",
        "imgaUrl": "",
        "text_dont": "",
        "id_dont": null
    }

    const modalDontsLogo = () => {
        Swal.fire({
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/12-Don_ts-applications-logo.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const dontsText = 'Show';
    const dontsIcon = donts_show ? 'bi bi-check-square' : 'bi bi-square';

    return (
        <>
            <div className='inputs_settings_container'>
                <div className="d-flex">
                    <p className='label_input_settings me-auto'>Don'ts application logo <Link onClick={(e) => { modalDontsLogo(); }}><i className="bi bi-exclamation-circle" /></Link></p>
                    <p className="ShowText">
                        {dontsText}{" "}
                        <i onClick={() => {console.log('valor => ', !donts_show); setDonts_show(!donts_show)}} className={dontsIcon} />
                    </p></div>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x800px</p>
                <div className="row row-cols-1 row-cols-md-3">
                    {Array(...donts).map((item, index) => (
                        <DropZoneDonts key={index}
                            index={index}
                            donts={donts}
                            setDonts={setDonts}
                        />
                    ))}
                    <div className="add-more-Container-top mt-3">
                        <a onClick={(e) => {
                            e.preventDefault();
                            let newObj = [...donts];
                            newObj.push(newone);
                            setDonts(newObj);
                        }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Logo Don`t Application</a>
                    </div>
                </div>
            </div>
        </>
    )
}
