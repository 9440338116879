import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../Services/Services';
import { logIn, logOut } from '../../store/auth/ProfileStatus';
import { ChangePassword } from '../Forms/ChangePassword';
import './InfoUser.style.css';
import Cookies from 'js-cookie';

export const InfoUser = () => {
    const { user } = useSelector((state) => state.authSession);
    const dispatch = useDispatch();
    const history = useHistory();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [agency, setAgency] = useState("");
    const [dname, setDname] = useState("");
    const [description, setDescription] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [imgb64, setImgb64] = useState("");
    const inputFile = useRef(null);
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const [show, setShow] = useState(false);
    const [pass, setPass] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setImgUrl(reader.result);
                setImgb64(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })



    const showError = async (res) => {
        Swal.fire({ title: `${res.message.message}`, text: `${res.message.error === false ? "Token not valid" : "Something wrong"}`, icon: 'error', color: 'black' });
        localStorage.clear();
        dispatch(logOut());
        history.push('/Login');
    };

    const updateUser = async (e) => {
        e.preventDefault();
        
        let res = await SERVICES.updateAccount(dname, name, description, imgb64);
        
        if (res.status === 401) return showError(res);
        
        if (res.status !== 200) return Swal.fire({ title: "Something wrong", text: "Try it later" });
       
        let newUser = await SERVICES.showUser();
        
        // newUser.token = user.token;
        localStorage.setItem("USER", JSON.stringify(newUser));
        dispatch(logIn(newUser));
        history.push("/Home");
        Swal.fire({ title: "updated!", icon: "success" });
    }

    const handleError = async(message) => {
        dispatch(logOut());
        localStorage.clear();
        Swal.fire({title:message, icon:'error'});
        history.push('/Login');
    };

    const fetchData = async () => {
        await SERVICES.getMyBrands().then((res) => {
            if(res.status === 401) return handleError(res.message.message);
        }).catch((error) => {
            console.error('error home ->', error);
        });
    }

    useEffect(() => {
        fetchData();

        let usuario = localStorage.getItem("USER");
        setName(user !== null ? user.name : "");
        setAgency(user !== null ? user.name_agency : "");
        setEmail(user !== null ? user.email : "");
        setDname(user !== null ? user.display_name : "");
        setDescription(user !== null ? user.description : "");
        setImgUrl(user !== null ? user.avatar : "");
    }, [user]);

    return (
        <div className='infouser_container'>
            <ChangePassword pass={pass} setPass={setPass} show={show} setShow={setShow} />
            <div className="infouser_header border-bottom">
                <p className='profileTitle'>My profile</p>
            </div>
            <div className="form_userinfo">
                <p className='subtitle_Profile mt-3 mb-4'>Account</p>
                <div className="formuser_container ">
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
                        <div className='mb-4'>
                            <p className='label_infouser'>Name</p>
                            <input
                                value={name ?? ""}
                                onChange={(e) => setName(e.target.value)}
                                aria-multiline={true}
                                className='form-control'
                            />
                        </div>
                        <div className='mb-4'>
                            <p className='label_infouser'>Display name publicy as</p>
                            <input
                                value={dname ?? ""}
                                onChange={(e) => setDname(e.target.value)}
                                className='form-control'
                            />
                        </div>
                        <div className='mb-4'>
                            <p className='label_infouser'>Email Address</p>
                            <input
                                value={email ?? ""}
                                onChange={(e) => setEmail(e.target.value)}
                                className='form-control'
                            />
                        </div>
                        <div className='mb-4'>
                            <p className='label_infouser'>Company</p>
                            <input
                                value={agency ?? ""}
                                onChange={(e) => setAgency(e.target.value)}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="">
                        <p className='label_infouser'>Avatar</p>
                        <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                imgUrl === "" ?
                                    <i className="bi bi-image picture_avatar_suerinfo" /> :
                                    <img
                                        className='img-profile-user'
                                        src={String(imgUrl).includes('http') === true ? imgUrl + `?=${performance.now()}` : imgUrl}
                                    />
                            }
                        </div>
                    </div>
                    <div className="">
                        <p className='label_infouser'>Description</p>
                        <textarea
                            value={description ?? ""}
                            rows={4}
                            onChange={(e) => setDescription(e.target.value)}
                            className='form-control input_description_userinfo'
                        />
                    </div>
                    <div className="my-4 saveinfobuttoncontainer">
                        <button onClick={async (e) => { e.preventDefault(); Cookies.remove('Authorization', {path: '/', domain: '.brandspace.ai'}); dispatch(logOut()); history.push('/Login'); }} className='cancel_infouser me-4'>
                            Log Out
                        </button>
                        <button onClick={(e) => { e.preventDefault(); setShow(true); console.log('pase') }} className='save_infouser me-4'>
                            Change Password
                        </button>
                        <button onClick={updateUser} className='save_infouser'>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
