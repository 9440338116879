import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { BrandBuildStrategy, BuildDownloads, BuildSidebar, BuildStandard, FooterDarkDownloads } from '../../Layouts';
import { SERVICES } from '../../Services/Services';
import './ViewBrand.style.css';

export const ViewBrand = () => {
    let floatingData = useParams();
    const { path } = useRouteMatch();

    useEffect(() => {
        const fetchData = async () => {

        };
        fetchData();
    }, []);


    return (
        <div className='d-flex flex-column'>
            <div className='my_account_container container'>
                <BuildSidebar />
                <div className='content_my_account'>
                    <Switch>
                        <Route path={path + '/BrandStrategy'} component={BrandBuildStrategy} />
                        <Route exact path={path + '/BrandStandard'} component={BuildStandard} />
                        <Route exact path={path + '/BrandDownloads'} component={BuildDownloads} />
                    </Switch>
                </div>
                
            </div>
        </div>

    )
}
