import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { Link } from 'react-router-dom';

export const Manifiesto = ({ killSession, text_manifiesto, setText_manifiesto, photoCover, setPhotoCover, photoCoverUrl, setPhotoCoverUrl, text_manifiesto_show, setText_manifiesto_show, photo_manifiesto_show, setPhoto_manifiesto_show }) => {
    let floatingData = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setPhotoCoverUrl(reader.result);
                setPhotoCover(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })

    const handleError = (message, subMessage) => {
        // Swal.fire({title: message, text: subMessage, icon:'error'});
        return setIsLoading(false);
    };

    const fetchData = async () => {
        await SERVICES.getBrandManifiesto(floatingData.id).then((res) => {
            
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            setText_manifiesto(res.data.text_manifiesto ?? "");
            setText_manifiesto_show(res.data.manifiesto_show ?? false);
            setPhotoCoverUrl(res.data.photo_manifiesto ?? "");
        }).catch((error) => {
            // console.log('Error get core ->', error);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const modalManifiesto =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/9-Manifiesto.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalManifiestoPhoto =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/10-Manifiesto-Photo.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }


    return (
        <div id='BrandManifiesto' className='submenu_maincontainer'>
            <div className='row row-cols-md-2'>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center" style={{ marginBottom: 30 }}>
                        <p className='label_input_settings me-auto'>Manifiesto <Link onClick={(e) => {modalManifiesto();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        
                    </div>

                    <textarea
                        value={text_manifiesto}
                        onChange={(e) => setText_manifiesto(e.target.value)}
                        className='form-control input_settings'
                        type="textarea"
                        rows={6}
                        placeholder='Sample: Manifiesto description'
                        onKeyDown={(e) => StringJumpLine(e.code, e, setText_manifiesto)}
                    />
                </div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Brand Manifiesto Photo Representative <Link onClick={(e) => {modalManifiestoPhoto();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!text_manifiesto_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_manifiesto_show(true) }} className="bi bi-square" /></p>}
                        {text_manifiesto_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_manifiesto_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <p className='recomendationText'>We recommended portrait format. Maximum images: 800x1200px</p>
                    <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            photoCoverUrl === "" ?
                                <div className='text-center'>
                                    <i className="bi bi-image picture_avatar_suerinfo" />
                                    <p className='placeholder_img'>Drag & drop your brand Manifiesto photo cover here.</p>
                                    <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                                </div> :
                                <img
                                    className='img-profile-user'
                                    src={String(photoCoverUrl).includes("http") === true ? photoCoverUrl + `?=${performance.now()}` : photoCoverUrl}
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
