import React from 'react';
import { DropzoneIcons } from '../../Helpers/DropzoneIcons';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandIconography = ({ text_iconography, setText_iconography, text_iconography_show, setText_iconography_show, icons, setIcons }) => {

    let newone = {
        "title_category": "",
        "id_category": null,
        "Icons": [
            {
                "image_category": "",
                "image_category_url": "",
                "id_iconographyimg": null
            }
        ]
    };
    const modalIconographyDesc=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/28-Iconography-Description.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }

    return (
        <div>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center mb-3">
                    <p className='label_input_settings me-auto'>Iconography <Link onClick={(e) => {modalIconographyDesc();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    {!text_iconography_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_iconography_show(true) }} className="bi bi-square" /></p>}
                    {text_iconography_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_iconography_show(false) }} className="bi bi-check-square" /></p>}

                </div>
                <p className='me-auto symbol_title mt-2'>Description here</p>
                <input
                    value={text_iconography}
                    onChange={(e) => { setText_iconography(e.target.value) }}
                    className='form-control input_settings'
                />
                {Array(...icons).map((item, index) => (
                    <DropzoneIcons
                        key={index}
                        icons={icons}
                        index={index}
                        setIcons={setIcons}
                    />
                ))}
                <div className="d-flex justify-content-end">
                    <a onClick={(e) => {
                        e.preventDefault();
                        let newObj = [...icons];
                        newObj.push(newone);
                        setIcons(newObj);

                    }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Category</a>
                </div>
            </div>
        </div>
    )
}
