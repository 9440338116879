import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const DropzoneTypographyHere = ({ path64, setPath64, pathUrl, setPathUrl }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setPathUrl(reader.result);
                setPath64(base64);
            }
        };


        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });
    return (
        <div className="img_infouser_container bg-white mb-4" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                pathUrl === "" ?
                    <div className='text-center'>
                        <i className="bi bi-image picture_avatar_suerinfo" />
                        <p className='placeholder_img'>Drag & drop your Image here or browse.</p>
                        <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                    </div> :
                    <img
                        className='img-profile-user'
                        src={String(pathUrl).includes("http") === true ? pathUrl + `?=${performance.now()}` : pathUrl}
                    />
            }
        </div>
    );
};

const modalAddTypography=() =>{
    Swal.fire({ 
        imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/18-Typography.jpg',
        imageAlt: 'Help Image',
        showConfirmButton: false
    });
}
const modalAlphabet=() =>{
    Swal.fire({ 
        imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/19-Alphabet.jpg',
        imageAlt: 'Help Image',
        showConfirmButton: false
    });
}
const modalParragraph=() =>{
    Swal.fire({ 
        imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/20-Parragraph.jpg',
        imageAlt: 'Help Image',
        showConfirmButton: false
    });
}
const modalDescription=() =>{
    Swal.fire({ 
        imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/21-Description.jpg',
        imageAlt: 'Help Image',
        showConfirmButton: false
    });
}

export const BrandTypography = ({ tagline_sample
    , setTagline_sample
    , alphabet
    , setAlphabet
    , parragraph
    , setParragraph
    , typography_show
    , setTypography_show, tagline_sample_url
    , setTagline_sample_url
    , alphabet_url
    , setAlphabet_url
    , parragraph_url
    , setParragraph_url
    , description
    , setDescription }) => {
    return (
        <>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center mb-3">
                    <p className='label_input_settings me-auto'>Add Typography <Link onClick={(e) => {modalAddTypography();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    {!typography_show && <p>Show <i onClick={(e) => { e.preventDefault(); setTypography_show(true) }} className="bi bi-square" /></p>}
                    {typography_show && <p>Show <i onClick={(e) => { e.preventDefault(); setTypography_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='me-auto symbol_title'>Tagline Sample</p>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x250px</p>
                <DropzoneTypographyHere
                    path64={tagline_sample}
                    pathUrl={tagline_sample_url}
                    setPath64={setTagline_sample}
                    setPathUrl={setTagline_sample_url}
                />
                <p className='me-auto symbol_title'>Alphabet  <Link onClick={(e) => {modalAlphabet();}}><i className="bi bi-exclamation-circle" /></Link></p>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x330px</p>
                <DropzoneTypographyHere
                    path64={alphabet}
                    pathUrl={alphabet_url}
                    setPath64={setAlphabet}
                    setPathUrl={setAlphabet_url}
                />
                <p className='me-auto symbol_title'>Paragraph here <Link onClick={(e) => {modalParragraph();}}><i className="bi bi-exclamation-circle" /></Link></p>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x250px</p>
                <DropzoneTypographyHere
                    path64={parragraph}
                    pathUrl={parragraph_url}
                    setPath64={setParragraph}
                    setPathUrl={setParragraph_url}
                />
                <p className='me-auto symbol_title'>Description <Link onClick={(e) => {modalDescription();}}><i className="bi bi-exclamation-circle" /></Link></p>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className='form-control input_settings'
                    rows={4}
                    onKeyDown={(e) => StringJumpLine(e.code, e, setDescription)}
                />
            </div>
        </>
    )
}
