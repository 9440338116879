import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DropzoneValues } from '../../Helpers/DropzoneValues';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { Link } from 'react-router-dom';

export const BrandStrategyValues = ({ killSession, show_values, setShow_values, brandValues, setBrandValues, fetchData }) => {
    let floatingData = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);


    

    useEffect(() => {
        // console.log('floating data ->', floatingData);

        fetchData();
    }, []);

    const modalValues =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/8-Values.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }

    return (
        <div id='BrandValues' className='submenu_maincontainer'>
            <div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Values <Link onClick={(e) => {modalValues();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!show_values && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setShow_values(true) }} className="bi bi-square" /></p>}
                        {show_values && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setShow_values(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                        {Array(...brandValues).map((item, index) => (
                            <DropzoneValues
                                fetchData={fetchData}
                                key={index}
                                brandValues={brandValues}
                                setBrandValues={setBrandValues}
                                index={index}
                            />
                        ))}
                        <div className="add-more-Container_Small mt-3">
                            <a onClick={(e) => {
                                e.preventDefault();
                                let newObj = [...brandValues];
                                newObj.push({
                                    "id_values": null,
                                    "title_value": "",
                                    "text_value": "",
                                    "image_value": "",
                                    "image_value_url": ""
                                });
                                setBrandValues(newObj);
                            }}><i className="bi bi-plus-circle-fill sizeCircle" /> </a>
                            <div className='addNewElement_Small'>Add New Value</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
