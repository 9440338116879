import React from 'react';
import { NewBrandForm } from '../../Layouts';
import './CreateBrandGuideline.style.css';

export const CreateBrandGuideline = () => {
    return (
        <div className='new_brand_container'>
            <NewBrandForm/>
        </div>
    )
}
