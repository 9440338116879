import React, { useState } from 'react';
import { LoginForm, SignUpForm } from '../../../Layouts/Forms';
import './Login.style.css';

export const Login = () => {
    const [mode, setMode] = useState("login");
    return (
        <div className='login-container'>
            {mode == "login" && <LoginForm setMode={setMode}/>}
            {mode == "signup" && <SignUpForm setMode={setMode}/>}
        </div>
    )
}
