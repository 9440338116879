import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../Services/Services';
import './Settings.style.css';
 
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

export const Settings = () => {
  const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
  let floatingData = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [textlinkcolor, settextlinkColor] = useColor("hex", "#000000");

  const [formValues, setFormValues] = useState({
    title: "",
    primary_description: "",
    secondary_description: "",
    text_font: "",
    text_size: "",
    text_link_color: "",
    text_color: "",
    status: "",
    logo: "",
    logo_url: "",
    brand_photo_cover: "",
    brand_photo_cover_url: "",
    button_color: "",
    button_color_hover: ""
  });
  const [url_friendly, setUrl_friendly] = useState("");

  const onChange = (e, type) => {
    setFormValues({ ...formValues, [type]: e.target.value });
  };

  const OnChangeDropzone = ({ type, type_url }) => {


    const onDrop = useCallback((acceptedFiles) => {
      // setIsLoading(true);
      const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
      const file = acceptedFiles[0];
      const reader = new FileReader();
      const img = new Image()

      // validate file type
      if (file === undefined) return console.log("no file uploaded");
      if (!validFileFormats.includes(file.type))
        return console.log("file format not supported");

      // convert to base64 an set pp icon badge
      reader.readAsDataURL(file);


      // image validate res
      reader.onload = () => {
        img.src = reader.result

        img.onload = () => {
          const base64 = getBase64StringFromDataURL(reader.result);
          setFormValues({ ...formValues, [type]: base64, [type_url]: reader.result });
          // setPhotoCoverUrl(reader.result);
          // setPhotoCover(base64);
        }
      };
      // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple: false,
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg'],
        'image/jpg': ['.jpg'],
        'image/svg': ['.svg'],
      }
    });


    return (
      <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
        <input {...getInputProps()} />
        {
          formValues[type_url] === "" ?
            <i className="bi bi-image picture_avatar_suerinfo" /> :
            <img
              className='img-profile-user'
              src={String(formValues[type_url]).includes("http") === true ? formValues[type_url] + `?=${performance.now()}` : formValues[type_url]}
            />
        }
      </div>
    )
  }

  // onChange={(e) => onChange(e, "password")}


  const fetchData = async () => {
    await SERVICES.getMyBrandsSettings(floatingData.id).then((res) => {
      
      if (res.data.found === true) {
        setFormValues({
          ...formValues,
          ["title"]: res.data.data_settings.title,
          ["primary_description"]: res.data.data_settings.primary_description,
          ["secondary_description"]: res.data.data_settings.secondary_description,
          ["text_font"]: res.data.data_settings.text_font,
          ["text_size"]: res.data.data_settings.text_size,
          ["text_link_color"]: res.data.data_settings.text_link_color,
          ["text_color"]: res.data.data_settings.text_color,
          ["status"]: res.data.data_settings.status,
          ["logo_url"]: res.data.data_settings.logo ?? "",
          ["brand_photo_cover_url"]: res.data.data_settings.brand_photo_cover ?? "",
          ["button_color"]: res.data.data_settings.button_color,
          ["button_color_hover"]: res.data.data_settings.button_color_hover,
        });
        setUrl_friendly(res.data.url_friendly);
      }
    }).catch((error) => {
      console.error('error settings ->', error);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveSettings = async () => {
    
    await SERVICES.updateSettings(formValues, floatingData.id).then((res) => {
      
    });
    await SERVICES.updateUrlFriendly({ url_friendly }, floatingData.id).then((res) => {
      
    }).catch((error) => {
      console.error("error change url ->", error);
    });
    fetchData();
    history.push('/MyBrands/' + floatingData.id + '/BrandStrategy');
    Swal.fire({ title: "Settings Saved Correctly", icon: "success", showCancelButton: false, showConfirmButton: false, timer: 2000, })
    
  };

  return (
    <div>
      <div className="title_submenu_container">
        <p onClick={(e) => { e.preventDefault(); console.log('formvalues ->', formValues) }} className='submenu_title'>Settings</p>
        <button onClick={(e) => {
          e.preventDefault();
          saveSettings();
        }} className='save_exit_button'>
          Save and Exit
        </button>
      </div>
      <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Your URL Friendly <i className="bi bi-exclamation-circle" /></p>
        <input
          value={url_friendly}
          onChange={(e) => setUrl_friendly(e.target.value)}
          className='form-control input_settings'
        />
      </div>
      <div className='inputs_settings_container'>
        {/* <i className="bi bi-exclamation-circle" /> */}
        <p className='label_input_settings me-auto'>Change Guidelines Title </p>
        <input
          value={formValues.title}
          onChange={(e) => onChange(e, "title")}
          className='form-control input_settings'
        />
      </div>
      {/* <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Guidelines Primary Description <i className="bi bi-exclamation-circle" /></p>
        <input
          value={formValues.primary_description}
          onChange={(e) => onChange(e, "primary_description")}
          className='form-control input_settings'
        />
      </div> */}
      {/* <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Guidelines Secondary Description <i className="bi bi-exclamation-circle" /></p>
        <input
          value={formValues.secondary_description}
          onChange={(e) => onChange(e, "secondary_description")}
          className='form-control input_settings'
        />
      </div> */}
      {/* <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Headlines Text Font <i className="bi bi-exclamation-circle" /></p>
        <select
          value={formValues.text_font}
          onChange={(e) => onChange(e, "text_font")}
          className='form-control input_settings'
        >
          <option value="System-Font">System Font</option>
          <option value="Lato">Lato</option>
          <option value="Open-Sans">Open Sans</option>
          <option value="Roboto">Roboto</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Poppins">Poppins</option>
          <option value="Source-Sans-Pro">Source Sans Pro</option>
          <option value="Oswald">Oswald</option>
          <option value="Inter">Inter</option>
          <option value="Raleway">Raleway</option>
          <option value="Nunito">Nunito</option>
        </select>
      </div> */}
      {/* <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Headlines Text Size <i className="bi bi-exclamation-circle" /></p>
        <select
          value={formValues.text_size}
          onChange={(e) => onChange(e, "text_size")}
          className='form-control input_settings'
        >
          <option value="System-size">System Size</option>
          <option value="20">20 px</option>
          <option value="30">30 px</option>
          <option value="40">40 px</option>
          <option value="50">50 px</option>
        </select>
      </div> */}
      {/* <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Text Link Color <i className="bi bi-exclamation-circle" /></p>
        <ColorPicker
          width={400}
          height={200}
          color={textlinkcolor}
          onChange={(color) => {console.log('color ->', color); 
          setFormValues({ ...formValues, ["text_link_color"]: color.hex });
          settextlinkColor(color)
        }}
          dark
        />
        <input
          value={formValues.text_link_color}
          // onChange={(e) => onChange(e, "text_link_color")}
          disabled={true}
          className='form-control input_settings'
        />
      </div> */}
      {/* <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Text Color <i className="bi bi-exclamation-circle" /></p>
        <input
          value={formValues.text_color}
          onChange={(e) => onChange(e, "text_color")}
          className='form-control input_settings'
        />
      </div>
      <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Buttons Color <i className="bi bi-exclamation-circle" /></p>
        <input
          value={formValues.button_color}
          onChange={(e) => onChange(e, "button_color")}
          className='form-control input_settings'
        />
      </div>
      <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Change Buttons Hover Color <i className="bi bi-exclamation-circle" /></p>
        <input
          value={formValues.button_color_hover}
          onChange={(e) => onChange(e, "button_color_hover")}
          className='form-control input_settings'
        />
      </div> */}
      <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Upload Logo <i className="bi bi-exclamation-circle" /></p>
        <p className='recomendationText'>Maximum allowed image size 2 MB</p>
        <OnChangeDropzone
          type={"logo"}
          type_url={"logo_url"}
        />
      </div>
      <div className='inputs_settings_container'>
        <p className='label_input_settings me-auto'>Add your Photo Cover <i className="bi bi-exclamation-circle" /></p>
        <p className='recomendationText'>We recommended square format. Maximum images: 800x800px</p>
        <OnChangeDropzone
          type={"brand_photo_cover"}
          type_url={"brand_photo_cover_url"}
        />
      </div>

    </div>
  )
}
