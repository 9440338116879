import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { logOut } from '../../store/auth/ProfileStatus';
import { getCookie } from '../../utils/cookies';
import { SERVICES } from '../../Services/Services';
import { logIn } from '../../store/auth/ProfileStatus';


import './Navbar.style.css';

export const Navbar = () => {
    const history = useHistory();
    const { user } = useSelector((state) => state.authSession);
    let url = window.location.href; 
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    // console.log('usuar nav ->', user);

    const redirecToMyBrand = () => {

    }

    const fetchData = async () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        
        if (jsonCookie) {
            
            // let usuario = await SERVICES.showUser();
            // dispatch(logIn(usuario));
            // window.location.replace(`https://${usuario.name_subdomain}.brandspace.ai/Home`);
            
            // history.push('/Home');
        } else {
            
            dispatch(logOut());
            // throw new Error('No se pudo obtener la cookie');
        }
    }

    useEffect(() => {
        fetchData();
        // console.log('mi posicion ->', window.location.href);
        // if (user){
        //     if (window.location.href.startsWith("https://brandspace.ai") === true || window.location.href.startsWith("https://www.brandspace.ai") === true) {
        //         window.location.replace(`https://${user.name_subdomain}.brandspace.ai/Home`);
        //     }
        // }
        
    }, [window.location.href, user])

    return (
        <>
            {url.includes('ViewBrand') !== true &&
                <nav className={`navbar navbar-expand-lg navbar-container ${!user ? 'd-none' : ''}`}>
                    <div className="container header">
                        <div className="agency">
                            <p className="navbar-brand Navbar-title" >{user !== null ? user.name_agency : ""}</p>
                        </div>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item pe-5">
                                    <a className="nav-link subtitles-navbar" aria-current="page" href="/Home">My brand guidelines</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link subtitles-navbar" href="/CreateBrandGuideline">Create new guidelines</a>
                                </li>
                            </ul>
                            <div className="d-flex" role="search">
                                <button
                                    onClick={(e) => { e.preventDefault(); history.push('/MyAccount/InfoUser') }}
                                    className="btn-profile-navbar"
                                >
                                    {user !== null ?
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className="userName">{user.name}</p>
                                            <img
                                                src={user.avatar + `?=${performance.now()}`}
                                                className='navbar_user_img ms-2'
                                            />
                                        </div>
                                        : ""}
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            }
        </>
    )
}
