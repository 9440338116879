import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import './SettingSideBar.style.css';
import StickyBox from "react-sticky-box";
import { NavHashLink } from 'react-router-hash-link';
import { SERVICES } from '../../Services/Services';
import { Link } from 'react-scroll'


export const SettingSideBar = ({ fetchData, optionsStrategy, optionsStandards, optionsDownloads }) => {
    const history = useHistory();
    let url = window.location.href;
    let floatingData = useParams();
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)

    return (
        <div className='sidebar_profile_menu brands_sidebar'>
            <StickyBox offsetTop={0} offsetBottom={20}>
                <nav className='navbar'>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className='brand_subtheme' onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandStrategy'); console.log('status alv ->', optionsStrategy) }}>BRAND STRATEGY</li>
                        {optionsStrategy.map((item, index) => (
                            <li key={index} className='nav-item d-flex'>
                                {url.includes('BrandStrategy') === true ?
                                    <Link
                                        to={item.id}
                                        className='me-auto'
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={300}
                                        onClick={closeMenu}
                                    >
                                        {item.name}
                                    </Link>
                                    :
                                    <NavHashLink
                                        to={'/MyBrands/' + floatingData.id + '/BrandStrategy/' + "#" + item.id}
                                        className='me-auto'
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={300}
                                        onClick={closeMenu}
                                    >
                                        {item.name}
                                    </NavHashLink>
                                }
                                {!item.show && <i className="bi bi-circle" />}
                                {item.show && <i className="bi bi-check" />}
                            </li>
                        ))}
                        <li className='brand_subtheme' onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandStandard') }}>BRAND STANDARDS</li>
                        {optionsStandards.map((item, index) => (
                            <li key={index} className='nav-item d-flex'>
                                {url.includes('BrandStandard') === true ?
                                    <Link
                                        to={item.id}
                                        className='me-auto'
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={300}
                                        onClick={closeMenu}
                                    >
                                        {item.name}

                                    </Link>
                                    :
                                    <NavHashLink
                                        to={'/MyBrands/' + floatingData.id + '/BrandStandard/' + "#" + item.id}
                                        className='me-auto'
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={300}
                                        onClick={closeMenu}
                                    >
                                        {item.name}
                                    </NavHashLink>
                                }
                                {!item.show && <i className="bi bi-circle" />}
                                {item.show && <i className="bi bi-check" />}
                            </li>
                        ))}
                        <li
                            className='brand_subtheme'
                            onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandDownloads') }}>
                            DOWNLOADS
                        </li>
                        {optionsDownloads.map((item, index) => (
                            <li key={index} className='nav-item d-flex'>
                                {url.includes('BrandDownloads') === true ?
                                    <Link
                                        to={item.id}
                                        className='me-auto'
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={300}
                                        onClick={closeMenu}
                                    >
                                        {item.name}

                                    </Link>
                                    :
                                    <NavHashLink
                                        to={'/MyBrands/' + floatingData.id + '/BrandDownloads/' + "#" + item.id}
                                        className='me-auto'
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={300}
                                        onClick={closeMenu}
                                    >
                                        {item.name}
                                    </NavHashLink>
                                }
                                {!item.show && <i className="bi bi-circle" />}
                                {item.show && <i className="bi bi-check" />}
                            </li>
                        ))}
                    </ul>

                </nav>
            </StickyBox>
        </div>
    )
}
