import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { Link } from 'react-router-dom';

export const BrandPersonality = ({ killSession, text_personality, setText_personality, setText_communication, text_personality_show, setText_personality_show, tones_Personality, setTones_Personality, tones_Communication, setTones_Communication }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let floatingData = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const newOnePersonality = {
        "text_perso_tone": "",
        "percentage_perso_tone": "",
        "id_tones_perso": null,

    };
    const newOneComunication = {
        "text_comu_tone": "",
        "percentage_comu_tone": "",
        "id_tones_comu": null,
    }


    const fetchData = async () => {
        await SERVICES.getBrandPersonality(floatingData.id).then((res) => {
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            // if (res.status !== 200) return () => { dispatch(logOut()); history.push('/Login') };
            setText_personality(res.data.Person.text_personality ?? "");
            setText_personality_show(res.data.Person.personality_show ?? false);
            // Array().length
            if (res.data.tones_perso.length > 0) {
                setTones_Personality(res.data.tones_perso);
            }
            if (res.data.tones_comu.length > 0) {
                setTones_Communication(res.data.tones_comu);
            }
        }).catch((error) => {
            // console.log('Error get core ->', error);
        });
    };


    const deleteTone = async (index) => {
        if (tones_Personality[index].id_tones_perso !== null) {
            await SERVICES.deleteTone(floatingData.id, tones_Personality[index].id_tones_perso).then((res) => {
                
                let sebo = [...tones_Personality];
                sebo.splice(index, 1);
                // fetchData();
                setTones_Personality(sebo);
            }).catch((error) => {
                console.error('error delete tone ->', error);
            });
        } else {
            let sebo = [...tones_Personality];
            sebo.splice(index, 1);
            setTones_Personality(sebo);
        }
    };

    const deleteCommunication = async (index) => {
        if (tones_Communication[index].id_tones_comu !== null) {
            
            await SERVICES.deleteCommunication(floatingData.id, tones_Communication[index].id_tones_comu).then((res) => {
                
                let sebo = [...tones_Communication];
                sebo.splice(index, 1);
                // fetchData();
                setTones_Communication(sebo);
            }).catch((error) => {
                console.error('error delete comunication ->', error);
            });
        } else {
            
            let sebo = [...tones_Communication];
            sebo.splice(index, 1);
            setTones_Communication(sebo);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const modalPersonality =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/9-Manifiesto.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }


    return (
        <div id='BrandPersonality' className='submenu_maincontainer'>
            <div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Personality and Communication Tone <Link onClick={(e) => {modalPersonality();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!text_personality_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_personality_show(true) }} className="bi bi-square" /></p>}
                        {text_personality_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_personality_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <p className='recomendationText'>Description here</p>
                    <textarea
                        value={text_personality}
                        onChange={(e) => setText_personality(e.target.value)}
                        className='form-control input_settings'
                        type="textarea"
                        rows={4}
                        onKeyDown={(e) => StringJumpLine(e.code, e, setText_personality)} 
                    // placeholder='Sample: We are a creative agency'
                    />
                    <div className="row row-cols-1 row-cols-md-2 mb-2">
                        <div>
                            <p className='fw-bold'>Personality Tone</p>
                        </div>
                        <div>
                            <p className='fw-bold'>Comunication Tone</p>
                        </div>
                    </div>
                    <div className="row row-cols-1- row-cols-md-2">
                        <div>
                            {Array(...tones_Personality).map((item, index) => (
                                <div key={index} className='row row-cols-1 row-cols-sm-2 mt-3'>
                                    <div className='row row-cols-1'>
                                        <div className='row row-cols-sm-2' style={{alignContent:'center', alignItems:'center', height: 30}}>
                                        <label className='value-label'>#{index + 1} Noun</label>
                                        </div>
                                        <input
                                            placeholder='Sample: Friendly'
                                            className='form-control py-3'
                                            value={item.text_perso_tone}
                                            onChange={(e) => {
                                                let sebo = [...tones_Personality];
                                                sebo[index].text_perso_tone = e.target.value;
                                                setTones_Personality(sebo);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className='row row-cols-sm-2' style={{alignContent:'center', alignItems:'center'}}>
                                            <label className='value-label'>Percent</label>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                deleteTone(index)
                                            }} className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" /></a>
                                        </div>
                                        <input
                                            placeholder='%'
                                            className='form-control py-3'
                                            value={item.percentage_perso_tone}
                                            onChange={(e) => {
                                                let sebo = [...tones_Personality];
                                                sebo[index].percentage_perso_tone = e.target.value;
                                                setTones_Personality(sebo);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex justify-content-end mx-4">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    
                                    let newObj = [...tones_Personality];
                                    newObj.push(newOnePersonality);
                                    
                                    setTones_Personality(newObj);
                                }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New</a>
                            </div> 
                        </div>

                        <div>
                            {Array(...tones_Communication).map((item, index) => (
                                <div key={index} className='row row-cols-1 row-cols-sm-2 mt-3'>
                                    <div className='row row-cols-1'>
                                        <div className='row row-cols-sm-2' style={{alignContent:'center', alignItems:'center', height: 30}}>
                                        <label className='value-label'>#{index + 1} Noun</label>
                                        </div>
                                        <input
                                            placeholder='Sample: Friendly'
                                            className='form-control py-3'
                                            value={item.text_comu_tone}
                                            onChange={(e) => {
                                                let sebo = [...tones_Communication];
                                                sebo[index].text_comu_tone = e.target.value;
                                                setTones_Communication(sebo);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className='row row-cols-sm-2' style={{alignContent:'center', alignItems:'center'}}>
                                            <label className='value-label'>Percent</label>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                deleteCommunication(index)
                                            }} className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" /></a>
                                        </div>
                                        <input
                                            placeholder='%'
                                            className='form-control py-3'
                                            value={item.percentage_comu_tone}
                                            onChange={(e) => {
                                                let sebo = [...tones_Communication];
                                                sebo[index].percentage_comu_tone = e.target.value;
                                                setTones_Communication(sebo);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex justify-content-end mx-4">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    
                                    let newObj = [...tones_Communication];
                                    newObj.push(newOneComunication);
                                    
                                    setTones_Communication(newObj);
                                }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
