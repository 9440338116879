import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandCoreStandard = ({
    textCover
    , setTextCover
    , textDescription
    , setTextDescription
    , setPhotoCoverBrand
    , photoCoverBrandUrl
    , setPhotoCoverBrandUrl
    , text_cover_show
    , setText_cover_show
}) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        try {
            const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
            const file = acceptedFiles[0];
            const reader = new FileReader();
            const img = new Image()

            // validate file type
            if (file === undefined) return console.log("no file uploaded");
            if (!validFileFormats.includes(file.type))
                return console.log("file format not supported");

            // convert to base64 an set pp icon badge
            reader.readAsDataURL(file);


            // image validate res
            reader.onload = () => {
                img.src = reader.result

                img.onload = () => {
                    const base64 = getBase64StringFromDataURL(reader.result);
                    setPhotoCoverBrandUrl(reader.result);
                    setPhotoCoverBrand(base64);
                }
            };
        } catch (error) {
            console.error('error load image ->', error)
        }
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })
    const modalBrandDescriptionPhoto=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/1-Brand-Standard-Intro-Photo-Cover.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalBrandDescription =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/3-Brand-Identity-Logo.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }



    return (
        <>
            <div className="row row-cols-1 row-cols-md-2">
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Brand Description Photo Cover <Link onClick={(e) => {modalBrandDescriptionPhoto();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    </div>

                    <p className='recomendationText'>We recommended square format. Maximum images: 800x800px</p>
                    <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            photoCoverBrandUrl === "" ?

                                <div className='text-center'>
                                    <i className="bi bi-image picture_avatar_suerinfo" />
                                    <p className='placeholder_img'>Drag & drop your brand standard intro photo cover here.</p>
                                    <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                                </div> :
                                <img
                                    className='img-profile-user'
                                    src={String(photoCoverBrandUrl).includes("http") === true ? photoCoverBrandUrl + `?=${performance.now()}` : photoCoverBrandUrl}
                                />
                        }
                    </div>
                </div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Add your Brand Description <Link onClick={(e) => {modalBrandDescription();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!text_cover_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_cover_show(true) }} className="bi bi-square" /></p>}
                        {text_cover_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_cover_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <p className='recomendationText'>We recommended place a branding text guide</p>

                    <textarea
                        value={textCover}
                        onChange={(e) => { setTextCover(e.target.value) }}
                        className='form-control input_settings'
                        type="textarea"
                        rows={6}
                        onKeyDown={(e) => StringJumpLine(e.code, e, setTextCover)}
                    />

                </div>
            </div>
        </>
    )
}
