import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';

export const DropzoneAssets = ({ index, assets, setAssets }) => {
    let floatingData = useParams();
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...assets];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].path = base64;
                sebo[index].path_url = reader.result;

                setAssets(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });


    const deleteAsset = async () => {
        if (String(assets[index].path_url).includes('http') === true) {
           
            await SERVICES.deleteBrandAsset(floatingData.id, assets[index].id_asset).then((res) => {
                
                let sebo = [...assets];
                sebo.splice(index, 1);
                // fetchData();
                setAssets(sebo);
            }).catch((error) => {
                // console.log('error delete all logos ->', error);
            });
        } else {
            let sebo = [...assets];
            sebo.splice(index, 1);
            setAssets(sebo);
        }
    };

    return (
        <>
            <div>

                <div className="d-flex align-items-center">
                    <p className='fw-bold me-auto'>Asset No. {index + 1}</p>
                    <a onClick={(e) => { e.preventDefault(); deleteAsset() }}
                        className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" />
                    </a>
                </div>
                <input
                    value={assets[index].asset_title}
                    onChange={(e) => {
                        let sebo = [...assets];
                        sebo[index].asset_title = e.target.value;
                        setAssets(sebo);
                    }}
                    className='form-control input_settings'
                    placeholder='Asset Title'
                />
                <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        assets[index].path_url === "" ?
                            <div className='text-center'>
                                <i className="bi bi-image picture_avatar_suerinfo" />
                                <p className='placeholder_img'>Drag & drop your file here or browse</p>
                                <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                            </div> :
                            <img
                                className='img-profile-user'
                                src={String(assets[index].path_url).includes('http') === true ? assets[index].path_url + `?=${performance.now()}` : assets[index].path_url}
                            />
                    }
                </div>
            </div>


        </>
    )
}
