import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { InfoUser, Invoices, Memberships, ProfileSidebar, FooterDarkDownloads } from '../../../Layouts';
import './MyAccount.style.css';

export const MyAccount = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    // console.log('pathh ->', path)

    useEffect(() => {
        // history.push(path + '/InfoUser')
    }, []);
    
    
    return (
        <div className='my_account_container container'>
            <ProfileSidebar/>
            <div className='content_my_account'>
                <Switch>
                    <Route path={path + '/InfoUser'} component={InfoUser}/>
                    <Route path={path + '/Invoices'} component={Invoices}/>
                    <Route path={path + '/Memberships'} component={Memberships}/>
                    {/* <Redirect to={path + '/InfoUser'}/> */}
                </Switch>
            </div>
        </div>
    );
};
