import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { DropzoneBlackLogo } from '../../Helpers/Logos/DropzoneBackLogo';
import { DropzoneIsotype } from '../../Helpers/Logos/DropzoneIsotype';
import { DropzoneLogo } from '../../Helpers/Logos/DropzoneLogo';
import { DropzoneWhiteLogo } from '../../Helpers/Logos/DropzoneWhiteLogo';
import { SERVICES } from '../../Services/Services';


const DropzoneZip = ({ value, setValue }) => {
    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                // console.log("Called", reader);
                baseURL = reader.result;
                const partes = baseURL.split("base64,");
                const cadenaDecodificada = partes[1];
                // console.log(cadenaDecodificada);
                resolve(cadenaDecodificada);
            };
            // console.log(fileInfo);
        });
    };
    const onDrop = useCallback((acceptedFiles) => {
        // console.log('acepted files alv ->', acceptedFiles[0]);
        let file = acceptedFiles[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                // console.log("File Is", file["base64"]);
                setValue(file["base64"]);
            })
            .catch(err => {
                console.error(err);
            });
    });

    const { getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'application/zip': ['.zip'],
            'application/x-rar-compressed': ['.rar']
        }
    });    

    return (
        <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                value === "" ?
                    <div className='text-center'>
                        <i className="bi bi-file-zip picture_avatar_suerinfo" />
                        <p className='placeholder_img'>Drag & drop your Zip File here or browse</p>
                        <p className='format_image_style'>(.zip)</p>
                    </div> :
                    <i className="bi bi-file-zip"></i>
            }
        </div>
    );
}

export const BrandLogos = ({ fetchData, logo, setLogo, logoUrl, setLogoUrl, logo_show, setLogo_show, logo_black, setLogo_black, logo_black_url, setLogo_black_url, logo_black_show, setLogo_black_show, logo_white, setLogo_white, logo_white_url, setLogo_white_url, logo_white_show, setLogo_white_show, logo_isotype, setLogo_isotype, logo_isotype_url, setLogo_isotype_url, logo_isotype_show, setLogo_isotype_show, logo_zip
    , setLogo_zip
    , logo_black_zip
    , setLogo_black_zip
    , logo_white_zip
    , setLogo_white_zip
    , logo_isotype_zip
    , setLogo_isotype_zip }) => {
    let floatingData = useParams();

    const deleteallLogos = async () => {
        await SERVICES.deleteBrandLogos(floatingData.id).then((res) => {
            
            setLogo("");
            setLogoUrl("");
            setLogo_black("");
            setLogo_black_url("");
            setLogo_isotype("");
            setLogo_isotype_url("");
            setLogo_white("");
            setLogo_white_url("");
            fetchData();
        }).catch((error) => {
            console.error('error delete all logos ->', error);
        });
    }

    return (
        <div className='submenu_maincontainer'>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Add your Logo file <i className="bi bi-exclamation-circle" /></p>
                    {!logo_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_show(true) }} className="bi bi-square" /></p>}
                    {logo_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='recomendationText'>We recommended maximum size 1M </p>
                <div className="row row-cols-1 row-cols-md-2">
                    <div className='col'>
                        <DropzoneLogo
                            logo={logo}
                            logoUrl={logoUrl}
                            logo_show={logo_show}
                            setLogo={setLogo}
                            setLogoUrl={setLogoUrl}
                            setLogo_show={setLogo_show}
                        />
                    </div>
                    <div className='col'>
                        <DropzoneZip
                            setValue={setLogo_zip}
                            value={logo_zip}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <a onClick={(e) => { e.preventDefault(); deleteallLogos() }} className='addNew'>
                        <i className="bi bi-dash-circle-fill sizeCircle" />
                        Delete Logos
                    </a>
                </div>
            </div>

            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Add your Black Logo <i className="bi bi-exclamation-circle" /></p>
                    {!logo_black_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_black_show(true) }} className="bi bi-square" /></p>}
                    {logo_black_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_black_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='recomendationText'>We recommended maximum size 1M </p>
                <div className="row row-cols-1 row-cols-md-2">
                    <div className='col'>
                        <DropzoneBlackLogo
                            logo_black={logo_black}
                            logo_black_show={logo_black_show}
                            logo_black_url={logo_black_url}
                            setLogo_black={setLogo_black}
                            setLogo_black_show={setLogo_black_show}
                            setLogo_black_url={setLogo_black_url}
                        />
                    </div>
                    <div className='col'>
                        <DropzoneZip
                            setValue={setLogo_black_zip}
                            value={logo_black_zip}
                        />
                    </div>
                </div>
            </div>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Add your White Logo<i className="bi bi-exclamation-circle" /></p>
                    {!logo_white_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_white_show(true) }} className="bi bi-square" /></p>}
                    {logo_white_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_white_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='recomendationText'>We recommended maximum size 1M </p>
                <div className="row row-cols-1 row-cols-md-2">
                    <div className='col'>
                        <DropzoneWhiteLogo
                            logo_white={logo_white}
                            logo_white_show={logo_white_show}
                            logo_white_url={logo_white_url}
                            setLogo_white={setLogo_white}
                            setLogo_white_show={setLogo_white_show}
                            setLogo_white_url={setLogo_white_url}
                        />
                    </div>
                    <div className='col'>
                        <DropzoneZip
                            setValue={setLogo_white_zip}
                            value={logo_white_zip}
                        />
                    </div>
                </div>
            </div>

            <div className='inputs_settings_container'>

                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Add your Isotype<i className="bi bi-exclamation-circle" /></p>
                    {!logo_isotype_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_isotype_show(true) }} className="bi bi-square" /></p>}
                    {logo_isotype_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setLogo_isotype_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='recomendationText'>We recommended maximum size 1M </p>
                <div className="row row-cols-1 row-cols-2">
                    <div className='col'>
                    <DropzoneIsotype
                        logo_isotype={logo_isotype}
                        logo_isotype_show={logo_isotype_show}
                        logo_isotype_url={logo_isotype_url}
                        setLogo_isotype={setLogo_isotype}
                        setLogo_isotype_show={setLogo_isotype_show}
                        setLogo_isotype_url={setLogo_isotype_url}
                    />
                    </div>
                    <div className='col'>
                    <DropzoneZip
                        setValue={setLogo_isotype_zip}
                        value={logo_isotype_zip}
                    />
                    </div>
                </div>

            </div>
        </div>
    )
}
