import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandIdentity = ({ photoLogoIdentity, setPhotoLogoIdentity, photoLogoIdentityUrl, setPhotoLogoIdentityUrl, identitycolors, setIdentitycolors, image_indentity_show, setImage_indentity_show }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    let newOne = {
        "color_rgb": "",
        "color_hex": "",
        "color_cmyk": "",
        "pantone": "",
        "id_identity_colors": null
    };

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                const base64 = getBase64StringFromDataURL(reader.result);
                // console.log('base ->', base64);
                setPhotoLogoIdentityUrl(reader.result);
                setPhotoLogoIdentity(base64);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });

    const deleteIdentityColor = async (index) => {
        if (identitycolors[index].id_identity_colors !== null) {
            await SERVICES.deleteBrandIdentityColor(floatingData.id, identitycolors[index].id_identity_colors).then((res) => {
                
                let sebo = [...identitycolors];
                sebo.splice(index, 1);
                // fetchData();
                setIdentitycolors(sebo);
            }).catch((error) => {
                console.error('error delete identity color ->', error);
            });
        } else {
            let sebo = [...identitycolors];
            sebo.splice(index, 1);
            setIdentitycolors(sebo);
        }
    };

    const modalBrandIdentityLogo=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/3-Brand-Identity-Logo.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalBrandIdentityColors =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/4%20-Brand-Identity-Logo-Colors.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    return (
        <>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Brand Identity Logo <Link onClick={(e) => {modalBrandIdentityLogo();}}><i className="bi bi-exclamation-circle" /></Link></p>

                    {!image_indentity_show && <i onClick={(e) => { e.preventDefault(); setImage_indentity_show(true) }} className="bi bi-square" />}
                    {image_indentity_show && <i onClick={(e) => { e.preventDefault(); setImage_indentity_show(false) }} className="bi bi-check-square" />}
                </div>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x800px</p>
                <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        photoLogoIdentityUrl === "" ?
                            <div className='text-center'>
                                <i className="bi bi-image picture_avatar_suerinfo" />
                                <p className='placeholder_img'>Drag & drop you brand identity logo here.</p>
                                <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                            </div> :
                            <img
                                className='img-profile-user'
                                src={String(photoLogoIdentityUrl).includes("http") === true ? photoLogoIdentityUrl + `?=${performance.now()}` : photoLogoIdentityUrl}
                            />
                    }
                </div>
            </div>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Brand Identity Colors <Link onClick={(e) => {modalBrandIdentityColors();}}><i className="bi bi-exclamation-circle" /></Link></p>
                </div>
                <div className='row row-cols-sm-2 row-cols-md-3'>
                    {Array(...identitycolors).map((item, index) => (
                        <div key={index}>
                            <div className="d-flex align-items-center">
                                <p className='fw-bold me-auto'>Color N.{index + 1}</p>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    deleteIdentityColor(index);
                                }} className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" /></a>
                            </div>
                            <div>
                                <p className='identity_color_text'>RGB - Red, Green, Blue</p>
                                <input
                                    className='form-control input_colors'
                                    placeholder='Sample Grass Green'
                                    value={identitycolors[index].color_rgb}
                                    onChange={(e) => {
                                        let sebo = [...identitycolors];
                                        sebo[index].color_rgb = e.target.value;
                                        setIdentitycolors(sebo);
                                    }}
                                />
                            </div>
                            <div>
                                <p className='identity_color_text'>HEX - (Hexadecimal)</p>
                                <input
                                    className='form-control input_colors'
                                    value={identitycolors[index].color_hex}
                                    onChange={(e) => {
                                        let sebo = [...identitycolors];
                                        sebo[index].color_hex = e.target.value;
                                        setIdentitycolors(sebo);
                                    }}
                                />
                            </div>
                            <div>
                                <p className='identity_color_text'>CMYK - Cyan, Magenta, Yellow, Black </p>
                                <input
                                    className='form-control input_colors'
                                    value={identitycolors[index].color_cmyk}
                                    onChange={(e) => {
                                        let sebo = [...identitycolors];
                                        sebo[index].color_cmyk = e.target.value;
                                        setIdentitycolors(sebo);
                                    }}
                                />
                            </div>
                            <div>
                                <p className='identity_color_text'>Pantone</p>
                                <input
                                    className='form-control input_colors'
                                    value={identitycolors[index].pantone}
                                    onChange={(e) => {
                                        let sebo = [...identitycolors];
                                        sebo[index].pantone = e.target.value;
                                        setIdentitycolors(sebo);
                                    }}
                                />
                            </div>
                        </div>
                        
                        // </div>
                    ))}
                     <div className="add-more-Container-top mt-3">
                        <a onClick={(e) => {
                            e.preventDefault();
                            let newObj = [...identitycolors];
                            newObj.push(newOne);
                            setIdentitycolors(newObj);
                        }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Color</a>
                    </div>
                </div>
            </div>
        </>
    )
}
