import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';



export const authSession = createSlice({
    name: 'ProfileStatus',
    initialState:  {
        user: null
    },
    reducers: {
        logOut: (state, action) => {  
            Cookies.remove('Authorization', {path: '/', domain: '.brandspace.ai'});
            state.user = null;
        },
        logIn: (state, action) => {
            state.user = action.payload;
        }
    },
})

export const {logOut, logIn} = authSession.actions;
