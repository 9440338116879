import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneZip = ({ value, setValue }) => {
    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                // console.log("Called", reader);
                baseURL = reader.result;
                const partes = baseURL.split("base64,");
                const cadenaDecodificada = partes[1];

                resolve(cadenaDecodificada);
            };

        });
    };
    const onDrop = useCallback((acceptedFiles) => {
        let file = acceptedFiles[0];

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                // console.log("File Is", file["base64"]);
                setValue(file["base64"]);
            })
            .catch(err => {
                console.error(err);
            });
    });

    const { getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'application/vnd.apple.keynote': ['.key'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/pdf': ['.pdf']
        }
    });

    return (
        <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                value === "" ?
                    <div className='text-center'>
                        <i className="bi bi-image picture_avatar_suerinfo" />
                        <p className='placeholder_img'>Drag & drop your PowerPoint Template here or browse</p>
                        <p className='format_image_style'>(.pptx, .pdf and .key)</p>
                    </div> :
                    <i className="bi bi-file-earmark-zip"></i>
            }
        </div>
    );
}

export const BrandPowerPoint = ({ pathPp, setPathPp, pathPpUrl, setPathPpUrl, power_show, setPower_show }) => {

    return (
        <div className='inputs_settings_container'>
            <div className="d-flex justifi-content-center">
                <p className='label_input_settings me-auto'>Add PowerPoint Template file <i className="bi bi-exclamation-circle" /></p>
                {!power_show &&
                    <p>Show <i onClick={(e) => { e.preventDefault(); setPower_show(true) }} className="bi bi-square" /></p>
                }
                {power_show &&
                    <p>Show <i onClick={(e) => { e.preventDefault(); setPower_show(false) }} className="bi bi-check-square" /></p>
                }
            </div>
            {/* <p className='recomendationText'>We recommended square format. Maximum images: 800x800px</p> */}
            <DropzoneZip
                setValue={setPathPp}
                value={pathPp}
            />
        </div>
    )
}
