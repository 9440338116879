import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import './BuildDownload.style.css';
import Swal from 'sweetalert2';

export const BuildDownloads = () => {
    let floatingData = useParams();
    const history = useHistory();
    const [assets, setAssets] = useState([]);
    const [flyers, setFlyers] = useState([]);
    const [iconography, setIconography] = useState([]);
    const [letterhead, setLetterhead] = useState({});
    const [linkedin, setLinkedin] = useState({});
    const [logos, setLogos] = useState({});
    const [powerpoint, setPowerpoint] = useState({});
    const [teams, setTeams] = useState({});
    const [settings, setSettings] = useState({});
    const [showFlyers, setShowFlyers] = useState(true);
    const [showIcons, setShowIcons] = useState(true);
    const [showAssets, setShowAssets] = useState(true);

    const [color, changeColor] = useState("#fff");

    document.body.style.backgroundColor = color;

    useEffect(() => {
        const fetchData = async () => {
            await SERVICES.getBrandBuildPublicDownloads(floatingData.id).then((res) => {
                // console.log("res build downloads ->", res);
                if (res.status !== 200) return;
                setAssets(res.data.AssetsFile.lenght === 0 ? [] : res.data.AssetsFile);
                setPowerpoint(res.data.PowerPoint ?? {});
                setShowAssets(res.data.AssetsStatus.assets_show ?? true);
                setFlyers(res.data.FlyersFile.lenght === 0 ? [] : res.data.FlyersFile);
                setShowFlyers(res.data.FlyersStatus.Flyers_show ?? true);
                setIconography(res.data.Iconography.lenght === 0 ? [] : res.data.Iconography);
                setShowIcons(res.data.IconsStatus.icon_show ?? true);
                setLetterhead(res.data.LetterheadFile ?? {});
                setLinkedin(res.data.LinkedlnFile ?? {});
                setLogos(res.data.Logos ?? {});
                setTeams(res.data.TeamsBack ?? {});
                setSettings(res.data.Settings ?? {});

            }).catch((error) => {
                // console.log("error build ->", error)
            });
        };
        fetchData();
    }, []);

    const downloadSourceLogos = async (path, title, brand_name) => {

        const data = {
            path: path,
            title: title
        }
        await SERVICES.downloadLogos(data, floatingData.id).then((res) => {

            // Crear un objeto Blob a partir del array buffer
            const blob = new Blob([res.data], { type: 'application/zip' });

            // Crear un enlace de descarga
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `brand_${brand_name}_${title}.zip`;

            // Simular un click en el enlace de descarga
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            Swal.fire({ title: "Error when downloading logo", icon: "error", confirmButtonColor: "#000000" })
        })
    };
    //falta probar con powerpoint
    const downloadSourceFlyers = async (info, title, brand_name) => {

        const data = {
            path: info.path,
        }
        await SERVICES.downloadFlyers(data, floatingData.id).then(async (res) => {
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `brand_${brand_name}_${title}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            Swal.fire({ title: "Error when downloading flyer", icon: "error", confirmButtonColor: "#000000" })
        })
    };
    const downloadDoc = async (info, title, brand_name) => {

        const data = {
            path: info,
        }
        await SERVICES.downloadFlyers(data, floatingData.id).then(async (res) => {

            // Crear un objeto Blob a partir del array buffer
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            // Crear un enlace de descarga
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `brand_${brand_name}_${title}`;

            // Simular un click en el enlace de descarga
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            Swal.fire({ title: "Error when downloading doc", icon: "error", confirmButtonColor: "#000000" })
        })
    };
    const downloadSourceIcons = async (icons, brand_name) => {

        await SERVICES.downloadIcons(icons, floatingData.id).then((res) => {
            // Crear un objeto Blob a partir del array buffer
            const blob = new Blob([res.data], { type: 'application/zip' });

            // Crear un enlace de descarga
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `brand_${brand_name}_icons.zip`;

            // Simular un click en el enlace de descarga
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            Swal.fire({ title: "Error when downloading icons", icon: "error", confirmButtonColor: "#000000" })
        })
    };
    //cuando agregues los assets, ocupar este
    const downloadSourceAssets = async (path, title, brand_name) => {

        const data = {
            path: path,
            title: title
        }
        await SERVICES.downloadAssets(data, floatingData.id).then((res) => {
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `brand_${brand_name}_${title}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            Swal.fire({ title: "Error when downloading asset", icon: "error", confirmButtonColor: "#000000" })
        })
    };

    return (
        <div>
            <div className='row downloads_banner_title'>
                <p className='title_resource_downloads'>{String(settings.title).toUpperCase()} RESOURCES</p>
                <p className='title_brand_downloads fs-1 fw-bold'>DOWNLOADS</p>
            </div>
            {JSON.stringify(logos) !== '{}' &&
                <>
                    {logos.logo !== null && logos.logo_show === true &&
                        <div className='row row-cols-1 row-cols-md-2 logo_image_build_container'>
                            <div className=''>
                                <img
                                    src={logos.logo}
                                    className='logo_image_build'
                                    alt='logo'
                                />
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <p className='title-principal-logo'>{settings.title} Logo</p>
                                <p className='info_text_download'>Package contains: logo in PNG, JPG and PDF versions.</p>
                                <button onClick={(e => {
                                    e.preventDefault();
                                    if (logos.logo_zip !== null) {
                                        downloadSourceLogos(logos.logo_zip, "logos", String(settings.title));
                                    }
                                })} className='download_button_logo'>
                                    {logos.logo_zip !== null ? "Download" : "Download not available"}
                                </button>
                            </div>
                        </div>
                    }
                    {/* Set of Logos */}
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 logos_container' id='brand-logos'>
                        {logos.logo_black !== null && logos.logo_black_show === true &&
                            <div className='col '>
                                <div className='card_logo_container m-2'>
                                    <div>
                                        <img
                                            src={logos.logo_black + `?=${performance.now()}`}
                                            className="img_card_logo mb-5"
                                            alt='logo black'
                                        />
                                        <p className='titles_logo'>{settings.title} Black Logo</p>
                                        <p className='info_logo_download mb-3'>Package contains: logo in PNG, JPG and PDF versions.</p>
                                        <div className="button_download_container">
                                            <button onClick={(e => {
                                                e.preventDefault();
                                                if (logos.logo_black_zip !== null) {
                                                    downloadSourceLogos(logos.logo_black_zip, "logo_black", String(settings.title));
                                                }
                                            })} className='btn_download_dark'>
                                                {logos.logo_black_zip !== null ? "Download" : "Download not available"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {logos.logo_white !== null && logos.logo_white_show === true &&
                            <div className='col '>
                                <div className='card_logo_container m-2'>
                                    <div>
                                        <img
                                            src={logos.logo_white + `?=${performance.now()}`}
                                            className="img_card_logo mb-5"
                                            alt='logo white'
                                        />
                                        <p className='titles_logo'>{settings.title} White Logo</p>
                                        <p className='info_logo_download mb-3'>Package contains: logo in PNG, JPG and PDF versions.</p>
                                        <div className="button_download_container">
                                            <button onClick={(e => {
                                                e.preventDefault();
                                                if (logos.logo_white_zip !== null) {
                                                    downloadSourceLogos(logos.logo_white_zip, "logo_white", String(settings.title));
                                                }
                                            })} className='btn_download_dark'>
                                                {logos.logo_white_zip !== null ? "Download" : "Download not available"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {logos.logo_isotype !== null && logos.logo_isotype_show === true &&
                            <div className='col '>
                                <div className='card_logo_container m-2'>
                                    <div>
                                        <img
                                            src={logos.logo_isotype + `?=${performance.now()}`}
                                            className="img_card_logo mb-5"
                                            alt='logo isotype'
                                        />
                                        <p className='titles_logo'>{settings.title} Isotype</p>
                                        <p className='info_logo_download mb-3'>Package contains: logo in PNG, JPG and PDF versions.</p>
                                        <div className="button_download_container">
                                            <button onClick={(e => {
                                                e.preventDefault();
                                                if (logos.logo_isotype_zip !== null) {
                                                    downloadSourceLogos(logos.logo_isotype_zip, "logo_isotype", String(settings.title));
                                                }
                                            })} className='btn_download_dark'>
                                                {logos.logo_isotype_zip !== null ? "Download" : "Download not available"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* Set of Icons */}
                    {iconography.length > 0 && showIcons &&
                        <>
                            <div className='row hrSection'>
                                <hr />
                            </div>
                            <div className='row row-cols-md-2 icons_download_container' id='brand-icons-downloads'>
                                <div className='col row row-cols-3 row-cols-md-3 image_icons_container'>
                                    {iconography.map((item, index) => (
                                        <div key={index}>
                                            <div>
                                                <img
                                                    src={item.path + `?=${performance.now()}`}
                                                    className="icon_img mb-2"
                                                    alt={`iconography ${index + 1}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='col d-flex flex-column justify-content-center'>
                                    <p className='title-principal-logo'>{settings.title} Icons</p>
                                    <p className='info_text_download'>Package contains: logo in PNG, JPG and PDF versions.</p>
                                    <button onClick={(e => {
                                        e.preventDefault();
                                        if (iconography.length > 0) {
                                            downloadSourceIcons(iconography, String(settings.title));
                                        }
                                    })} className='download_button_logo'>
                                        {iconography.length > 0 ? "Download" : "Download not available"}
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                    {powerpoint.power_show || teams.teams_show || letterhead.letter_show
                        ? <div className='row hrSection'>
                            <hr />
                        </div> : null
                    }
                    {/* Power Point, Microsoft Bakcgroun and LetterHead */}
                    <div className='row row-cols-1 row-cols-sm-3 row-cols-md-3 icons_download_container'>
                        {powerpoint.power_show &&
                            <div className='col' id='brand-power'>
                                <div className='col card_logo_container m-2'>
                                    <p className='titles_logo'>PowerPoint <br />Template</p>
                                    <div className="d-flex flex-column align-items-center mt-4">
                                        <button onClick={(e => {
                                            e.preventDefault();
                                            if (powerpoint.path !== null) {
                                                downloadDoc(powerpoint.path, 'powerpoint', String(settings.title));
                                            }
                                        })} className='btn_download_dark'>
                                            {powerpoint.path !== null ? "Download" : "Download not available"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {teams.teams_show &&
                            <div className='col' id='brand-teams'>
                                <div className='col card_logo_container m-2'>
                                    <p className='titles_logo'>Microsoft Teams <br />Background</p>
                                    <div className="d-flex flex-column align-items-center mt-4">
                                        <button onClick={(e => {
                                            e.preventDefault();
                                            if (teams.path !== null) {
                                                downloadSourceFlyers(teams, 'Background_Teams', String(settings.title));
                                            }
                                        })} className='btn_download_dark'>
                                            {teams.path !== null ? "Download" : "Download not available"}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        }
                        {letterhead.letter_show && (
                            <div className='col' id='brand-letterhead'>
                                <div className='col card_logo_container m-2'>
                                    <p className='titles_logo'>Letterhead <br /><br /></p>
                                    <div className="d-flex flex-column align-items-center mt-4">
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            if (letterhead.path !== null) {
                                                downloadSourceFlyers(letterhead, 'letterhead', String(settings.title));
                                            }
                                        }} className='btn_download_dark'>
                                            {letterhead.path !== null ? "Download" : "Download not available"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Section Backgroun Image */}
                    {linkedin.linked_show === true &&
                        <>
                            <div className='row hrSection'>
                                <hr />
                            </div>
                            <div className='background_banner_container' id='brand-linkedin'>
                                <div className='row row-cols-md-2 icons_download_container'>
                                    <div className='col-6'>
                                        <img
                                            src={linkedin.path + `?=${performance.now()}`}
                                            className="background_image_banner"
                                            alt='banner'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <p className='title-principal-logo'>{settings.title} LinkedIn <br />Background Banner</p>
                                        <button onClick={(e => {
                                            e.preventDefault();
                                            if (linkedin.path !== null) {
                                                downloadSourceFlyers(linkedin, 'Background_LinkedIn', String(settings.title));
                                            }
                                        })} className='download_button_logo'>
                                            {linkedin.path !== null ? "Download" : "Download not available"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/* Section de Flyers */}
                    {
                        showFlyers ? (
                            <>
                                <div className='row hrSection'>
                                    <hr />
                                </div>
                                <div className='row flyers_container' id='brand-Flyers'>
                                    <div className='flyer-title'>
                                        <p className='title-principal-logo'>{settings.title} Flyers</p>
                                    </div>
                                    {flyers.length > 0 &&
                                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
                                            {flyers.map((item, index) => (
                                                <div key={index} className='col'>
                                                    <div className='card_logo_container my-4'>
                                                        <img
                                                            src={item.path + `?=${performance.now()}`}
                                                            className="img_card_logo mb-3"
                                                            alt={`Flyer_${index + 1}`}
                                                        />
                                                        <p className='flyer_title'>Flyer {index + 1}</p>
                                                        <div className="button_download_container">
                                                            <button onClick={(e => {
                                                                e.preventDefault();
                                                                if (item.path !== null) {
                                                                    downloadSourceFlyers(item, `Flyer_${index + 1}`, String(settings.title));
                                                                }
                                                            })} className='btn_download_dark'>
                                                                {item.path !== null ? "Download" : "Download not available"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </>
                        ) : null
                    }
                    {
                        showAssets ? (
                            <>
                                <div className='row hrSection'>
                                    <hr />
                                </div>
                                <div className='row flyers_container' id='brand-assets'>
                                    <div className='flyer-title'>
                                        <p className='title-principal-logo'>{settings.title} Assets</p>
                                    </div>
                                    {assets.length > 0 &&
                                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
                                            {assets.map((item, index) => (
                                                <div key={index} className='col'>
                                                    <div className='card_logo_container my-4'>
                                                        <img
                                                            src={item.path + `?=${performance.now()}`}
                                                            className="img_card_logo mb-3"
                                                            alt={`Asset_${index + 1}`}
                                                        />
                                                        <p className='flyer_title'>Asset {index + 1}</p>
                                                        <div className="button_download_container">
                                                            <button onClick={(e => {
                                                                e.preventDefault();
                                                                if (item.path !== null) {
                                                                    downloadSourceFlyers(item, `Asset_${index + 1}`, String(settings.title));
                                                                }
                                                            })} className='btn_download_dark'>
                                                                {item.path !== null ? "Download" : "Download not available"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </>
                        ) : null
                    }
                </>
            }
            <div className='row footer_container'>
                <div className='btn_canel_footer_container col-md-3'>
                    <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/Client/' + floatingData.id + '/BrandStandard') }}>
                        Back
                    </button>
                </div>
                <div className='text_footer col-6'>
                    <p className='brand_center'>BRAND CENTER</p>
                    <p className='desc_brand_center'></p>
                </div>
                <div className='btn_save_footer_container col-md-3'>
                    {/* <button className='btn_save_footer'
                            onClick={(e) => {

                                history.push('/Client/' + floatingData.id + '/BrandDownloads')
                            }}>
                            Continue
                        </button> */}
                </div>
            </div>
        </div>
    )
}
