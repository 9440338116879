import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './HomeEmptyProyects.style.css';

export const HomeEmptyProyects = () => {
    const history = useHistory();
    
    return (
        <div className='home_empty_container'>
            <i className="bi bi-window-fullscreen icon-home-window" />
            <button onClick={(e) => {e.preventDefault(); history.push('/CreateBrandGuideline')}} className='create_guideline_home_btn'>
                Create Your First Brand Guideline
            </button>
        </div>
    )
}
