import moment from 'moment/moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteBrandModal } from '../DeleteBrandModal';
import './CardsBrands.style.css';
import { getCookie } from '../../utils/cookies';
import Swal from 'sweetalert2';

export const CardsBrands = ({ item, fetchData }) => {
    const history = useHistory();
    const [viewModalDelete, setViewModalDelete] = useState(false);
    
    const navToBrand = () => {
        history.push(`/MyBrands/${item.url_brand}/BrandStrategy`)
    }
    const copyToClipboard = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        navigator.clipboard.writeText(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + item.url_friendly + '/BrandStrategy');
    };
    const ViewProject = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        window.open(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + item.url_friendly + '/BrandStrategy');
        // window.open(`http://localhost:3000/Client/` + item.url_friendly + '/BrandStrategy', '_blank');
    }

    return (
        <div className="">
            <div className="col card_project_grid">
                {item.brand_photo_cover !== null ?
                    <img
                        src={item.brand_photo_cover}
                        className='img_card_brand'
                    />
                    :
                    <div className="CoverPhoto">
                        <p className='textCoverPhoto'>{item.title}</p>
                    </div>
                }

                <p className='date_text_card_brand'>{moment(item.created_At).format('MM/DD/YYYY')}</p>
                <p className='title_card_text'>{item.title}</p>
                <p className='createdby_card_brand'>Created By {item.display_name}</p>
                
                <div className='row row-cols-2 row-cols-sm-4 row-cols-md-4 d-flex justify-content-center mt-2'>
                    <div onClick={(e) => { e.preventDefault(); ViewProject() }} className='col icon_cards_brands_container'>
                        <i className="bi bi-search" />
                        <p className="option-proyect">View</p>
                    </div>
                    
                    <div onClick={(e) => { 
                        e.preventDefault(); 
                        navToBrand() }} 
                        className='col icon_cards_brands_container'>
                        <i className="bi bi-pencil" />
                        <p className="option-proyect">Edit</p>
                    </div>
                    
                    <div onClick={(e) => { 
                        e.preventDefault(); 
                        copyToClipboard()
                        Swal.fire({ title: "URL Coppied Correctly", icon: "success", showCancelButton: false, showConfirmButton: false, text:'You can share de public URL now', timer: 2500, })
                        }} className='col icon_cards_brands_container'>
                        <i className="bi bi-share" />
                        <p className="option-proyect">Share</p>
                    </div>
                    <div onClick={(e) => { e.preventDefault(); setViewModalDelete(true); }} className='col icon_cards_brands_container'>
                        <i className="bi bi-trash3" />
                        <p className="option-proyect">Delete</p>
                    </div>
                </div>
            </div>
            <DeleteBrandModal
                brand={item}
                fetchData={fetchData}
                setViewModalDelete={setViewModalDelete}
                viewModalDelete={viewModalDelete}
            />
        </div>
    )
}
