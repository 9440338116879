import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { Navbar } from "../Layouts";
import { LoginForm, SignUpForm } from "../Layouts/Forms";
import { CreateBrandGuideline, Home, Login, MyAccount, RestorePass, SettingsBrand, ViewBrand } from "../Pages";
import { logIn } from "../store/auth/ProfileStatus";
import Loged from "./Loged";
import NoLoged from "./NoLog";
import { getCookie } from '../utils/cookies';
import { SERVICES } from '../Services/Services';

const AppRoutes = () => {
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fethcData = async () => {
            // localStorage.clear();
            let cookieData = getCookie('Authorization');
            const jsonCookie = JSON.parse(cookieData)
            // console.log('cookie ->', cookieData)
            if (jsonCookie) {
                let usuario = await SERVICES.showUser();
                dispatch(logIn(usuario));
            }
        }
        fethcData();
    }, []);
    //checar las rutas we, si jala con las cookies la sesion entre subdominios en el que eres miembro
    //pero el problema es cuando no lo eres y te redirreciona directamente a brandspace.ai sin subdominio
    //aunque yo en el back tengo puesto que si no es miembro lo mande al home de su subdominio pero en vez de enviarlo ahi
    //lo manda al home de la url que no tiene dominio, ta raro, aunque si pongo nuevamente a mano su subdominio en el navegador
    //si le muestra su home en el subdominio
    return (
        <div>
            <Navbar/>
            <Switch>
                <Loged path={"/Home"} component={Home} />
                <Route path={"/Client/:id"} component={ViewBrand}/>
                <NoLoged path={"/Login"} component={Login} />
                <NoLoged path={"/RestorePassword"} component={RestorePass} />
                <NoLoged path={"/Create-Account"} component={SignUpForm} />
                <Loged path={"/CreateBrandGuideline"} component={CreateBrandGuideline} />
                <Loged path={"/MyAccount"} component={MyAccount} />
                <Loged path={"/MyBrands/:id"} component={SettingsBrand} />
                {/* <Loged path={"/MyBrands/BrandStategy/:id"} component={SettingsBrand} /> */}
                <Redirect exact from="/" to={"/Login"} />
                {/* <Route path={'/Home'} component={Home} />*/}
            </Switch>
        </div>
    );
};
export default AppRoutes;
