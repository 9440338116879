
import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import { BrandConstruction } from '../BrandConstruction/BrandConstruction';
import { BrandCoreStandard } from '../BrandCoreStandard/BrandCoreStandard';
import { BrandDonts } from '../BrandDonts/BrandDonts';
import { BrandIdentity } from '../BrandIdentity/BrandIdentity';
import { BrandUrl } from '../BrandUrl/BrandUrl';
import { BrandVersions } from '../BrandVersions/BrandVersions';
import { BrandPhotography } from '../BrandPhotography/BrandPhotography';
import { BrandAmplifier } from '../BrandAmplifier';
import { BrandPalleteColor } from '../BrandPalleteColor/BrandPalleteColor';
import { BrandIconography } from '../BrandIconography/BrandIconography';
import { BrandTypography } from '../BrandTypography';
import { useDispatch } from 'react-redux';
import { logOut } from '../../store/auth/ProfileStatus';
import { getCookie } from '../../utils/cookies';
import StickyBox from 'react-sticky-box';
import LoadingModal from '../../Components/LoadingModal';

export const BrandStandard = ({ fetchDataSidebar }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    let floatingData = useParams();
    const { path } = useRouteMatch();
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [url_friendly, setUrl_friendly] = useState("");
    const [project, setProject] = useState("");

    const killSession = async () => {
        Swal.fire({ title: 'Your token expired', icon: 'error', confirmButtonColor: 'red' });
        dispatch(logOut());
        history.push('/Login');
    }

    //Brand Standard core
    const [textCover, setTextCover] = useState("");
    const [textDescription, setTextDescription] = useState("");
    const [photoCoverBrand, setPhotoCoverBrand] = useState("");
    const [photoCoverBrandUrl, setPhotoCoverBrandUrl] = useState("");
    const [text_cover_show, setText_cover_show] = useState(false);
    const [text_description_show, setText_description_show] = useState(false);
    const [photo_cover_show, setPhoto_cover_show] = useState(false);

    //End brand standar core

    //Brand identity
    const [photoLogoIdentity, setPhotoLogoIdentity] = useState("");
    const [photoLogoIdentityUrl, setPhotoLogoIdentityUrl] = useState("");
    const [identitycolors, setIdentitycolors] = useState([
        {
            "color_rgb": "",
            "color_hex": "",
            "color_cmyk": "",
            "pantone": "",
            "id_identity_colors": null
        },
    ]);
    const [image_indentity_show, setImage_indentity_show] = useState(false);
    //End brand identity

    //Brand Version images
    const [versions_show, setVersions_show] = useState(false);
    const [BrandVersionImages, setBrandVersionImages] = useState([
        {
            "id_version": null,
            "title_version": "",
            "image_version": "",
            "image_version_url": "",
            "colors_version": [
                {
                    "id_version_colors": null,
                    "title_color": "",
                    "color_rgb": "",
                    "color_hex": "",
                    "color_cmyk": "",
                    "pantone": ""
                },
            ]
        },
    ]);
    //End Brand version images

    //Brand Construction
    const [text_construction, setText_construction] = useState("");
    const [image_construction, setImage_construction] = useState("");
    const [image_constructionUrl, setImage_constructionUrl] = useState("");
    const [brandConstructionsSymbols, setBrandConstructionsSymbols] = useState([
        {
            'image64': "",
            "imgaUrl": "",
            "id_symbol": null,
        },
        {
            'image64': "",
            "imgaUrl": "",
            "id_symbol": null,

        }
    ]);
    const [text_construction_show, setText_construction_show] = useState(false);
    const [image_construction_show, setImage_construction_show] = useState(false);
    //End Brand Construction

    //Brand Url Treatment
    const [url_text, setUrl_text] = useState("");
    const [text_url_treatment_show, setText_url_treatment_show] = useState(false);

    const [urlImages, setUrlImages] = useState([
        {
            "id_url_img": null,
            "image_url": "",
            "image_path": "",
            "id_brand": null
        }
    ]);

    //End Url Treatment

    //Brand dont
    const [donts_show, setDonts_show] = useState(false);
    const [donts, setDonts] = useState([
        {
            'image64': "",
            "imgaUrl": "",
            "text_dont": "",
            "id_dont": null
        },
    ]);

    //End Brand donts

    //Brand Pallete Colors
    const [text_primary_show, setText_primary_show] = useState(false);
    const [pallet, setPallet] = useState([
        {
            "title_category": "",
            "id_category": null,
            "colors": [
                {
                    "id_color": null,
                    "title_color": "",
                    "color_rgb": "",
                    "color_hex": "",
                    "color_cmyk": "",
                    "pantone": ""
                },
            ]
        },
    ])

    //End Brand Pallete Colors

    //Brand Photography
    const [text_photography, setText_photography] = useState("");
    const [text_photography_show, setText_photography_show] = useState(false);
    const [photografies, setPhotografies] = useState([
        {
            "id_Photographyimg": null,
            // "description_photography": "",
            "image_photography": "",
            "image_photography_url": "",
        },
    ])
    const [bullet, setBullet] = useState([
        {
            "text_bullet": "",
            "id_Photographybullet": null
        },
    ])
    //End Brand Photography

    //Brand amplifier

    const [amplifiers_show, setAmplifiers_show] = useState(false);
    const [amplifiers, setAmplifiers] = useState([
        {
            "id_amplipattern": null,
            "title_amplifier": "",
            "text_amplifier": "",
            "PatternImages": [
                {
                    "image_pattern": "",
                    "image_pattern_url": "",
                    "id_amplipatternimg": null
                }
            ]
        }
    ]);
    //End Brand Amplifier

    //Bran Iconography
    const [text_iconography, setText_iconography] = useState("");
    const [text_iconography_show, setText_iconography_show] = useState(false);
    const [icons, setIcons] = useState([
        {
            "title_category": "",
            "id_category": null,
            "Icons": [
                {
                    "image_category": "",
                    "image_category_url": "",
                    "id_iconographyimg": null
                }
            ]
        }
    ]);
    //End Iconography

    //Brand Typography
    const [tagline_sample, setTagline_sample] = useState("");
    const [tagline_sample_url, setTagline_sample_url] = useState("");
    const [alphabet, setAlphabet] = useState("");
    const [alphabet_url, setAlphabet_url] = useState("");
    const [parragraph, setParragraph] = useState("");
    const [parragraph_url, setParragraph_url] = useState("");
    const [description, setDescription] = useState("");
    const [typography_show, setTypography_show] = useState(false);
    //End typography

    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');


    const onDrop = useCallback((acceptedFiles) => {
        setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                const base64 = getBase64StringFromDataURL(reader.result);
                // console.log('base ->', base64);
                setPhotoCoverBrandUrl(reader.result);
                setPhotoCoverBrand(base64);
            }
        };
        setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })


    const fetchData = async () => {

        await SERVICES.getMyBrandsSettings(floatingData.id).then((res) => {
            if (res.data.found === true) {
                setProject(res.data.data_settings);
                setUrl_friendly(res.data.url_friendly);
            }
        }).catch((error) => {
            console.error('Error ->', error);
        });

        await SERVICES.getBrandCoreStandard(floatingData.id).then((res) => {
            // console.log('res getbrand cores tandard ->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                // console.log('si paso getbrandcorestandard');
                setPhotoCoverBrandUrl(res.data.photo_cover ?? "");
                setTextCover(res.data.text_cover ?? "");
                setTextDescription(res.data.text_description ?? "");
                setText_cover_show(res.data.brand_core_show ?? false);
                // setText_cover_show()
                //text_description_show
            }
        }).catch((error) => {

        });
        await SERVICES.getBrandIdentity(floatingData.id).then((res) => {
            // console.log('res identity ->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                // console.log('imagen indenti ->', res.data.Identity.image_indentity)
                setPhotoLogoIdentityUrl(res.data.Identity.image_indentity ?? "");
                setImage_indentity_show(res.data.Identity.indentity_show ?? false);
                if (res.data.IdentityColors.length > 0) {
                    setIdentitycolors(res.data.IdentityColors)
                }
            }
        }).catch((error) => {

        });
        await SERVICES.getBrandVersions(floatingData.id).then((res) => {
            // console.log('res versions ->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                setVersions_show(res.data.VersionStatus.versions_show ?? false);
                let items = [];
                res.data.Versions.map((item, index) => {
                    if (item.image_version !== null) {
                        // console.log('version tal ->', item);
                        if (item.version_colors.length !== 0) {

                            items.push(
                                {
                                    "id_version": item.id_version,
                                    "title_version": "",
                                    "image_version": item.image_version,
                                    "image_version_url": item.image_version,
                                    "colors_version": item.version_colors
                                }
                            )
                        }

                    };
                });

                setBrandVersionImages(items.length === 0 ? [{
                    "id_version": null,
                    "title_version": "",
                    "image_version": "",
                    "image_version_url": "",
                    "colors_version": [
                        {
                            "id_version_colors": null,
                            "title_color": "",
                            "color_rgb": "",
                            "color_hex": "",
                            "color_cmyk": "",
                            "pantone": ""
                        },
                    ]
                }] : items);

            }
        }).catch((error) => {

        });
        await SERVICES.getBrandConstruction(floatingData.id).then((res) => {
            // console.log('res contructions ->', res);
            // if (res.status === 200) {
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            setText_construction(res.data.Constructions.text_construction ?? "");
            setImage_constructionUrl(res.data.Constructions.image_construction ?? "");
            setImage_construction_show(res.data.Constructions.construction_show ?? false);
            setBrandConstructionsSymbols([
                {
                    'image64': "",
                    "imgaUrl": res.data.ConstructionsSymbol[0].image_symbol,
                    "id_symbol": res.data.ConstructionsSymbol[0].id_symbol,
                },
                {
                    'image64': "",
                    "imgaUrl": res.data.ConstructionsSymbol[1].image_symbol,
                    "id_symbol": res.data.ConstructionsSymbol[1].id_symbol,
                }
            ]);
            // }
        }).catch((error) => {

        });
        await SERVICES.getBrandUrlTreatment(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                let newArray = [];
                let imagesUrlTreat = [...res.data.urlimage];
                imagesUrlTreat.map((item, index) => {
                    newArray.push({
                        "id_brand": item.id_brand ?? null,
                        "image_url": "",
                        "image_path": item.image_url,
                        "id_url_img": item.id_url_img,
                    },);
                });

                setUrl_text(res.data.url.text_url_treatment ?? "")
                setText_url_treatment_show(res.data.url.url_treatment_show ?? false);
                if (newArray.length > 0) {
                    setUrlImages(newArray);
                }
                // console.log('si paso getbrandcorestandard')
            }
        }).catch((error) => {

        });
        await SERVICES.getBrandDonts(floatingData.id).then((res) => {
            // console.log('res get brand dontsss -------->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                let oldArray = [...res.data.donts];
                let newObjDont = [];
                oldArray.map((item, index) => {
                    newObjDont.push({
                        'image64': "",
                        "imgaUrl": item.image_dont,
                        "text_dont": item.text_dont,
                        "id_dont": item.id_dont ?? null
                    });
                })
                // console.log('new obj dont ->', newObjDont);
                if (newObjDont.length > 0) {
                    setDonts(newObjDont)
                }
                setDonts_show(res?.data?.dontsStatus?.donts_show);
            }
        }).catch((error) => {

        });
        await SERVICES.getPalleteColors(floatingData.id).then((res) => {
            // console.log('res get pallet colors ->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                setText_primary_show(res.data.CategoryStatus.pallet_color_show ?? false);
                let allColors = [...res.data.Categorys];
                let finalParseColors = [];
                allColors.map((item, index) => {

                    finalParseColors.push({
                        "title_category": item.title_category ?? "",
                        "id_category": item.id_category,
                        "colors": item.category_colors
                    },)
                });
                if (finalParseColors.length > 0) {
                    setPallet(finalParseColors);
                }
            }
        }).catch((error) => {

        });
        await SERVICES.getBrandTypography(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.data !== null) {
                // console.log('si paso ');
                setAlphabet_url(res.data.alphabet ?? "");
                setParragraph_url(res.data.parragraph ?? "");
                setTagline_sample_url(res.data.tagline_sample ?? "");
                // setUpper_lower(res.data.upper_lower ?? "");
                setDescription(res.data.description ?? "");
                setTypography_show(res.data.typography_show ?? false);
            }
        }).catch((error) => {

        });
        await SERVICES.getBrandPhotography(floatingData.id).then((res) => {

            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                // console.log('si paso getbrandcorestandard')
                setText_photography(res.data.photography.text_photography ?? "");
                setText_photography_show(res.data.photography.photography_show ?? false);
                let imgs = [...res.data.photos];
                let newImgs = [];
                imgs.map((item, index) => {
                    newImgs.push({
                        "id_Photographyimg": item.id_Photographyimg,
                        "description_photography": item.description_photography ?? "",
                        "image_photography": "",
                        "image_photography_url": item.image_photography ?? "",
                    })
                });
                // console.log('new images ->', newImgs)
                if (res.data.bullets.length > 0) {
                    setBullet(res.data.bullets)
                }
                if (newImgs.length > 0) {
                    setPhotografies(newImgs);
                }

            }
        }).catch((error) => {

        });
        await SERVICES.getBrandAmplifier(floatingData.id).then((res) => {
            // console.log('res get bran amplifier ->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                setAmplifiers_show(res.data.AmplifierStatus !== null ? res.data.AmplifierStatus.amplifiers_show : false);
                let arrayTest = [...res.data.Amplifiers];
                let items = [];
                arrayTest.map((item, index) => {
                    let objToSend = {
                        "id_amplipattern": item.id_amplipattern,
                        "title_amplifier": item.title_amplifier ?? "",
                        "text_amplifier": item.text_amplifier ?? "",
                        "PatternImages": [

                        ]
                    }
                    {
                        Array(...item.amplifier_images).map((item2, index2) => {
                            // console.log('item 2 ->', item2)
                            objToSend.PatternImages.push({

                                "image_pattern": "",
                                "image_pattern_url": item2.image_pattern,
                                "id_amplipatternimg": item2.id_amplipatternimg

                            })
                        })
                    }
                    items.push(objToSend);
                    // console.log('object to send ->', objToSend)
                });
                if (items.length > 0) {
                    setAmplifiers(items);
                }


            }
        }).catch((error) => {

        });
        await SERVICES.getBrandIconography(floatingData.id).then((res) => {
            // console.log('res get iconografy ->', res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            if (res.status === 200) {
                setText_iconography(res.data.IconDescription.text_iconography ?? "");
                setText_iconography_show(res.data.IconDescription.iconography_show ?? false);
                if (res.data.IconsCategorys.length > 0) {

                    let newIcons = [];
                    let oldIcons = [...res.data.IconsCategorys];
                    oldIcons.map((item, index) => {
                        let objeToPush = {
                            "title_category": item.title_category,
                            "id_category": item.id_category,
                            "Icons": []
                        }

                        let elements = [];

                        item.category_images.map((item2, index2) => {

                            elements.push({
                                "image_category": "",
                                "image_category_url": item2.image_category,
                                "id_iconographyimg": item2.id_iconographyimg
                            })
                        });

                        if (elements.length > 0) {

                            objeToPush.Icons = elements;
                            newIcons.push(objeToPush);
                        }
                    });

                    if (newIcons.length > 0) {
                        setIcons(newIcons);
                    }
                }

                // console.log('si paso getbrandcorestandard');
            }
        }).catch((error) => {

        });
    }
    useEffect(() => {
        fetchData();
    }, []);

    const saveAllInfo = async () => {
        await SERVICES.addBrandCoreStandar({
            text_cover: textCover,
            photo_cover: photoCoverBrand,
            text_description: textDescription,
            brand_core_show: text_cover_show,
        }, floatingData.id).then((res) => console.log('res brand core standat ->'));
        await SERVICES.addBrandIdentity({
            image_indentity: photoLogoIdentity,
            indentity_show: image_indentity_show
        }, floatingData.id).then((res) => console.log('res brand identity standat ->'));
        await SERVICES.addBrandIdentityColors({ "identitycolors": identitycolors }, floatingData.id).then((res) => console.log('res identity colors ->'));


        // console.log("brands state we ->", BrandVersionImages)
        let dataVersions = {
            "status": [
                {
                    "versions_show": versions_show
                }
            ],
            "versions": BrandVersionImages
        }
        // BrandVersionImages.map((item, index) => {
        //     // console.log("item ->", item);
        //     let newObj = item;
        //     // newObj.image_version = newObj.image_version;
        //     dataVersions.versions.push(newObj);
        // });

        // console.log("Data version ->", dataVersions);


        await SERVICES.addBrandVersions(dataVersions, floatingData.id).then((res) => console.log('res brand versions standat ->'));
        // console.log('data construction ->', {
        //     "text_construction": text_construction,
        //     "image_construction": image_construction,
        // })
        await SERVICES.addBrandConstructions({
            "text_construction": text_construction,
            "image_construction": image_construction,
            "construction_show": image_construction_show
        }, floatingData.id).then((res) => { console.log('res save constructions ->') });
        let simbolos = {
            "symbols": [
            ]
        }
        brandConstructionsSymbols.map((item, index) => {

            simbolos.symbols.push({ image_symbol: item.image64, id_symbol: item.id_symbol });
        });
        // console.log("info data simbolos ->", simbolos);
        await SERVICES.addBrandConstructionsSymbols(simbolos, floatingData.id).then((res) => { console.log('res simbolos ->') });
        await SERVICES.addBrandUrlTreatmentText({ text_url_treatment: url_text, url_treatment_show: text_url_treatment_show, }, floatingData.id).then((res) => { console.log("res save url text ->") });
        let urlImg = {
            "imagesurl": [

            ]
        }
        urlImages.map((item, index) => {
            urlImg.imagesurl.push({ image_url: item.image_url, id_brand: item.id_brand });
        })
        // console.log("imagenes weee ------>", urlImg)
        await SERVICES.addBrandUrlTreatmentImages(urlImg, floatingData.id).then((res) => {setUrlImages([])});

        let dataDonts = {
            "status":
            {
                "donts_show": donts_show
            },
            "donts": []
        }
        // console.log("info data donts ->", dataDonts);
        donts.map((item, index) => {
            // console.log("item we donst ->", item);
            dataDonts.donts.push({
                "text_dont": item.text_dont,
                "image_dont": item.image64,
                "id_dont": item.id_dont
            });
        });

        await SERVICES.addBrandDonts(dataDonts, floatingData.id).then((res) => { console.log('data de los donts => ', dataDonts); console.log("si paso we dont "); console.log("res save donst ->") });
        await SERVICES.addBrandPhotoText({ text_photography: text_photography, photography_show: text_photography_show }, floatingData.id).then((res) => { console.log("res save photo text ->") });
        let imagesBrandImgs = {
            "Photographys": []
        };
        photografies.map((item, index) => {
            imagesBrandImgs.Photographys.push(item);
        });
        // console.log('Fotos ->', photografies)
        // console.log('Imagenes ->', imagesBrandImgs)

        await SERVICES.addBrandPhotographys(imagesBrandImgs, floatingData.id).then((res) => {
            
        }).catch((error) => {
            console.error('error save imgs ->', error);
        });
        // if (imagesBrandImgs.length  > 0) {
        //     console.log('Intento enviar')
        //     await SERVICES.addBrandPhotographys(imagesBrandImgs, floatingData.id).then((res) => {
        //         console.log("res save img photographys ->", res)
        //     }).catch((error) => {
        //         console.log('error save imgs ->', error);
        //     });
        // }else {
        //     console.log('No hay imagen para subir')
        // }
        await SERVICES.addBrandPhotographysBullet({
            "Bullet": bullet
        }, floatingData.id).then((res) => { console.log("res save bullet photographys ->") });

        await SERVICES.addBrandAmplifier({
            "status": {
                "amplifiers_show": amplifiers_show
            },
            "Amplifiers": amplifiers
        }, floatingData.id).then((res) => {
            console.error('res save amplifiers ->');
        });


        await SERVICES.addBrandPrimaryCollor({
            "status": {
                "pallet_color_show": text_primary_show
            },
            "PalletColors": pallet
        }, floatingData.id).then((res) => {
            // console.log('res save pallet color ->');
        });



        await SERVICES.addBrandIconographyText({ text_iconography: text_iconography, iconography_show: text_iconography_show }, floatingData.id).then((res) => {
            // console.log('res save iconography text ->');
        });
        await SERVICES.addBrandIconographyIcons({ "CategoryIcons": icons }, floatingData.id).then((res) => {
            // console.log('res save iconography icons ->');
        });

        await SERVICES.addBrandTypography({
            alphabet: String(alphabet).split('data:image/jpeg;base64,')[0],
            tagline_sample: String(tagline_sample).split('data:image/jpeg;base64,')[0],
            parragraph: String(parragraph).split('data:image/jpeg;base64,')[0],
            typography_show: typography_show,
            description
        }, floatingData.id).then((res) => {
            // console.log('res save typography ->');
        }).catch((error) => {
            console.error('error  typography->', error)
        });


        
        fetchData();
    }

    const copyToClipboard = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        navigator.clipboard.writeText(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + url_friendly + '/BrandStrategy');
        // navigator.clipboard.writeText(`http://localhost:3000/Client/` + url_friendly + '/BrandStrategy');
    };
    const ViewProject = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        window.open(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + url_friendly + '/BrandStandard');
        //window.open(`http://localhost:3000/Client/` + url_friendly + '/BrandStandard', '_blank');
    }

    return (
        <div>
            <LoadingModal isLoading={isLoadingModal} />
            <StickyBox offsetTop={0} offsetBottom={20}>
                <div className="title_submenu_container">
                    <p className='submenu_title'>{project.title} Guideline</p>
                    <p className='me-1' onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard();
                        Swal.fire({ title: "URL Coppied Correctly", icon: "success", showCancelButton: false, showConfirmButton: false, text: 'You can share de public URL now', timer: 2500, })
                    }}><i className="bi bi-share" /> Share</p>
                    <p style={{cursor: "pointer"}} className='me-1' onClick={(e) => {
                        e.preventDefault();
                        ViewProject();
                    }}>
                        <i style={{cursor: "pointer"}} className="bi bi-search" /> Preview</p>
                    <button onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/Settings') }} className='settings_button me-1'>
                        <span className='bi bi-gear gearIcon'></span><span className='me-1'>Settings</span>
                    </button>
                    <button onClick={(e) => {
                        e.preventDefault();
                        setIsLoadingModal(true);
                        saveAllInfo();
                        setTimeout(() => {
                            fetchDataSidebar();
                            setIsLoadingModal(false);
                            Swal.fire({ title: "Info Saved Correctly", icon: "success", confirmButtonColor: "#000000" })
                        }, 10000);
                    }} id='button-save-brandform' className='save_exit_button'>
                        SAVE
                    </button>
                </div>
            </StickyBox>
            <div className='submenu_maincontainer'>
                <div>
                    <div id='brand-core-standard' className="">
                        <BrandCoreStandard
                            photoCoverBrand={photoCoverBrand}
                            photoCoverBrandUrl={photoCoverBrandUrl}
                            photo_cover_show={photo_cover_show}
                            setPhotoCoverBrand={setPhotoCoverBrand}
                            setPhotoCoverBrandUrl={setPhotoCoverBrandUrl}
                            setPhoto_cover_show={setPhoto_cover_show}
                            setTextCover={setTextCover}
                            setTextDescription={setTextDescription}
                            setText_cover_show={setText_cover_show}
                            setText_description_show={setText_description_show}
                            textCover={textCover}
                            textDescription={textDescription}
                            text_cover_show={text_cover_show}
                            text_description_show={text_description_show}
                        />
                    </div>
                    <div id='brand-identity'>
                        <BrandIdentity
                            photoLogoIdentity={photoLogoIdentity}
                            photoLogoIdentityUrl={photoLogoIdentityUrl}
                            setPhotoLogoIdentity={setPhotoLogoIdentity}
                            setPhotoLogoIdentityUrl={setPhotoLogoIdentityUrl}
                            identitycolors={identitycolors}
                            image_indentity_show={image_indentity_show}
                            setIdentitycolors={setIdentitycolors}
                            setImage_indentity_show={setImage_indentity_show}

                        />
                    </div>
                    <div id='brand-versions'>
                        <BrandVersions
                            setVersions_show={setVersions_show}
                            versions_show={versions_show}
                            BrandVersionImages={BrandVersionImages}
                            setBrandVersionImages={setBrandVersionImages}
                        />
                    </div>
                    <div id='brand-construction'>
                        <BrandConstruction
                            text_construction={text_construction}
                            setText_construction={setText_construction}
                            image_construction={image_construction}
                            setImage_construction={setImage_construction}
                            image_constructionUrl={image_constructionUrl}
                            setImage_contructionUrl={setImage_constructionUrl}
                            brandConstructionsSymbols={brandConstructionsSymbols}
                            setBrandConstructionsSymbols={setBrandConstructionsSymbols}
                            image_construction_show={image_construction_show}
                            setImage_construction_show={setImage_construction_show}
                            setText_construction_show={setText_construction_show}
                            text_construction_show={text_construction_show}
                        />
                    </div>
                    <div id='brand-url'>
                        <BrandUrl
                            urlImages={urlImages}
                            setUrlImages={setUrlImages}
                            setText_construction={setUrl_text}
                            text_construction={url_text}
                            setText_url_treatment_show={setText_url_treatment_show}
                            text_url_treatment_show={text_url_treatment_show}
                        />
                    </div>
                    <div id='brand-donts'>
                        <BrandDonts
                            donts={donts}
                            setDonts={setDonts}
                            donts_show={donts_show}
                            setDonts_show={setDonts_show}
                        />
                    </div>
                    <div id='brand-pallet-color'>
                        <BrandPalleteColor
                            setText_primary_show={setText_primary_show}
                            text_primary_show={text_primary_show}
                            pallet={pallet}
                            setPallet={setPallet}
                        />
                    </div>
                    <div id='brand-typography'>
                        <BrandTypography
                            alphabet={alphabet}
                            parragraph={parragraph}
                            setAlphabet={setAlphabet}
                            setParragraph={setParragraph}
                            setTagline_sample={setTagline_sample}
                            setTypography_show={setTypography_show}
                            tagline_sample={tagline_sample}
                            typography_show={typography_show}
                            alphabet_url={alphabet_url}
                            description={description}
                            parragraph_url={parragraph_url}
                            setAlphabet_url={setAlphabet_url}
                            setDescription={setDescription}
                            setParragraph_url={setParragraph_url}
                            setTagline_sample_url={setTagline_sample_url}
                            tagline_sample_url={tagline_sample_url}
                        />
                    </div>
                    <div id='brand-photography'>
                        <BrandPhotography
                            photografies={photografies}
                            setPhotografies={setPhotografies}
                            setText_photography={setText_photography}
                            setText_photography_show={setText_photography_show}
                            text_photography={text_photography}
                            text_photography_show={text_photography_show}
                            bullet={bullet}
                            setBullet={setBullet}
                        />
                    </div>
                    <div id='brand-amplifier'>
                        <BrandAmplifier
                            amplifiers_show={amplifiers_show}
                            setAmplifiers_show={setAmplifiers_show}
                            amplifiers={amplifiers}
                            setAmplifiers={setAmplifiers}
                        />
                    </div>

                    <div id='brand-iconography'>
                        <BrandIconography
                            icons={icons}
                            setIcons={setIcons}
                            setText_iconography={setText_iconography}
                            setText_iconography_show={setText_iconography_show}
                            text_iconography={text_iconography}
                            text_iconography_show={text_iconography_show}
                        />
                    </div>
                    <div className='footer_container'>
                        <div className='row'>
                            <div className='btn_canel_footer_container col-md-3'>
                                <button className='btn_cancel_footer'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push('/MyBrands/' + floatingData.id + '/BrandStrategy')
                                    }}
                                >
                                    Back
                                </button>
                            </div>
                            <div className='text_footer col-md-6'>
                                <p className='brand_center'>BRAND CENTER</p>
                                <p className='desc_brand_center'>Continue fill the Brand Downloads form</p>
                            </div>
                            <div className='btn_save_footer_container col-md-3'>
                                <button className='btn_save_footer'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsLoadingModal(true);
                                        saveAllInfo();
                                        setTimeout(() => {
                                            fetchDataSidebar();
                                            setIsLoading(false);
                                            Swal.fire({ title: "Info Saved Correctly", icon: "success", confirmButtonColor: "#000000" })
                                            history.push('/MyBrands/' + floatingData.id + '/BrandDownloads');
                                        }, 10000);

                                    }}>
                                    Save and Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
