import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useParams} from 'react-router-dom';
import './FooterDarkDownloads.style.css';

export const FooterDarkDownloads = () => {
    let url = window.location.href;
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();
    let floatingData = useParams();

    // useEffect(() => {
    //     console.log('Footer ->', url);
    // }, [window.location.href])

    return (
        <>
            {/* {url.includes('BrandStrategy') === true ?
                <div className='footer_container row'>
                    <div className='btn_canel_footer_container col'>
                        <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/Home/') }}>
                            Exit
                        </button>
                    </div>
                    <div className='text_footer col-6'>
                        <p className='brand_center'>BRAND CENTER</p>
                        <p className='desc_brand_center'>Continue fill the Brand Standards form</p>
                    </div>
                    <div className='btn_save_footer_container col'>
                        <button className='btn_save_footer' onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandStandard') }}>
                            Continue
                        </button>
                    </div>
                </div>
                :
                url.includes('BrandStandard') === true ?
                <div className='footer_container row'>
                    <div className='btn_canel_footer_container col'>
                        <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandStrategy') }}>
                            Back
                        </button>
                    </div>
                    <div className='text_footer col-6'>
                        <p className='brand_center'>BRAND CENTER</p>
                        <p className='desc_brand_center'>Continue fill the Brand Downloads form</p>
                    </div>
                    <div className='btn_save_footer_container col'>
                        <button className='btn_save_footer' onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandDownloads') }}>
                            Continue
                        </button>
                    </div>
                </div>
                :
                <div className='footer_container row'>
                    <div className='btn_canel_footer_container col'>
                        <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/BrandStandard') }}>
                            Back
                        </button>
                    </div>
                    <div className='text_footer col-6'>
                        <p className='brand_center'>BRAND CENTER</p>
                        <p className='desc_brand_center'>Are you finish?</p>
                    </div>
                    <div className='btn_save_footer_container col'>
                        <button className='btn_save_footer' onClick={(e) => { e.preventDefault(); history.push('/Home/') }}>
                            Finish
                        </button>
                    </div>
                </div>
            } */}
        </>
    )
}
