import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import './BrandCore.style.css';
import { URIS } from '../../Services/Uris';
import { Link } from 'react-router-dom';

export const BrandCore = ({ killSession, textCover, setTextCover, photoCover, setPhotoCover, textCore, setTextCore, photoCoverUrl, setPhotoCoverUrl, show_textCover, setShow_textCover, show_photoCoverBrandCore, setShow_photoCoverBrandCore, show_textCore, setShow_textCore }) => {
    let floatingData = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setPhotoCoverUrl(reader.result);
                setPhotoCover(base64);
            }
        };
        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })


    const handleError = (message, subMessage) => {
        // Swal.fire({ title: message, text: subMessage, icon: 'error' });
        return setIsLoading(false);
    };

    const fetchData = async () => {
        // console.log(`la data que envio we -> `, floatingData.id);
        await SERVICES.getBrandCore(floatingData.id).then((res) => {
           
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            // if (res.status !== 200) return () => { console.log('hola'); dispatch(logOut()); history.push('/Login') };
            if (res.data !== null) {
                setTextCore(res.data.text_core ?? "");
                setTextCover(res.data.text_cover ?? "");
                setPhotoCoverUrl(res.data.photo_cover ?? "");
                // setShow_photoCoverBrandCore(res.data.photo_cover_show ?? true);
                // setShow_textCore(res.data.text_core_show ?? "");
                setShow_textCover(res.data.brand_core_show ?? false);
            }
        }).catch((error) => {
            console.error('Error get core ->', error);
        });
    };

    useEffect(() => {

        fetchData();
    }, []);

    const pressedEnter = (key, e) => {
        if (key === 'Enter') {
            
            setTextCore(e.target.value);
        }
    }

    const modalTextCover =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/1-Brand-Text-Cover.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });

    }
    const modalPhotoCover =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/2-Photo-Cover.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });

    }
    const modalTextIntro =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/3-Brand-Text-Core.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });

    }


    return (
        <div id='BrandCore' className='submenu_maincontainer'>
            <div>
                <div className="row row-cols-1 row-cols-md-2">
                    <div className='col inputs_settings_container'>
                        <div className="d-flex justifi-content-center">
                            <p className='label_input_settings me-auto'>Text Cover <Link onClick={(e) => {modalTextCover();}}><i className="bi bi-exclamation-circle"/></Link></p>
                        </div>
                        <p className='recomendationText mb-4'>We recommended place what the company does</p>
                        <textarea
                            value={textCover}
                            onChange={(e) => setTextCover(e.target.value)}
                            className='form-control input_settings'
                            type="textarea"
                            rows={6}
                            placeholder='Sample: We are a creativity agency'
                            onKeyDown={(e) => StringJumpLine(e.code, e, setTextCover)}
                        />
                        
                    </div>
                    <div className='col inputs_settings_container'>
                        <div className="d-flex justifi-content-center">
                            <p className='label_input_settings me-auto'>Photo Cover <Link onClick={(e) => {modalPhotoCover();}}><i className="bi bi-exclamation-circle" /></Link></p>
                            {!show_textCover &&
                                <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setShow_textCover(true) }} className="bi bi-square" /></p>
                            }
                            {show_textCover &&
                                <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setShow_textCover(false) }} className="bi bi-check-square" /></p>
                            }
                        </div>
                        <p className='recomendationText mb-4'>We recommended square format. Maximum images: 800x800px</p> 
                        <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                photoCoverUrl === "" ?
                                    <div className='text-center'>
                                        <i className="bi bi-image picture_avatar_suerinfo" />
                                        <p className='placeholder_img'>Drag & drop your brand statement photo cover here.</p>
                                        <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                                    </div>
                                     :
                                    <img
                                        className='img-profile-user'
                                        src={String(photoCoverUrl).includes("http") === true ? photoCoverUrl + `?=${performance.now()}` : photoCoverUrl}
                                    />
                            }
                        </div>
                    </div>
                </div>
                <div className='inputs_settings_container'>
                    <div className="d-flex mb-4">
                        <p className='label_input_settings me-auto'>Brand Text Intro <Link onClick={(e) => {modalTextIntro();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    </div>
                    <textarea
                        value={textCore}
                        onChange={(e) => setTextCore(e.target.value)}
                        className='form-control input_settings'
                        type="textarea"
                        rows={6}
                        onKeyDown={(e) => StringJumpLine(e.code, e, setTextCore)}
                    />
                </div>
            </div>
        </div>
    )
}
