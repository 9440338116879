import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';

export const DropZoneHelper = ({ index, BrandVersionImages, setBrandVersionImages }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.error("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...BrandVersionImages];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].image_version = base64;
                sebo[index].image_version_url = reader.result;

                
                setBrandVersionImages(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });

    const deleteBrandVersion = async () => {
        if (String(BrandVersionImages[index].image_version_url).includes('http') === true) {
            await SERVICES.deleteBrandVersion(floatingData.id, BrandVersionImages[index].id_version).then((res) => {
                
                let sebo = [...BrandVersionImages];
                sebo.splice(index, 1);
                // fetchData();
                setBrandVersionImages(sebo);
            }).catch((error) => {
                // console.log('error delete version ->', error);
            });
        } else {
            let sebo = [...BrandVersionImages];
            sebo.splice(index, 1);
            setBrandVersionImages(sebo);
        }
    };


    return (
        <>
            <div className="d-flex align-items-center">
                <p className='fw-bold me-auto'>Version N.{index + 1}</p>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteBrandVersion();
                }} className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x800px</p>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    BrandVersionImages[index].image_version_url === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop you brand version logo here.</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={String(BrandVersionImages[index].image_version_url).includes('http') === true ? BrandVersionImages[index].image_version_url + `?=${performance.now()}` : BrandVersionImages[index].image_version_url}
                        />
                }
            </div>
            <div className="row row-cols-1 row-cols-md-3">

                {Array(...BrandVersionImages[index].colors_version).map((item, index2) => (
                    <div key={index2} >
                        <div>
                            <div className="d-flex align-items-center">
                                <p className='identity_color_text me-auto'>#{index + 1} Color Name Title</p>
                                <a onClick={(e) => {
                                    console.error('Intentaste Eliminar un Color')
                                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
                            </div>
                            <input
                                className='form-control input_colors'
                                placeholder='Sample Grass Green'
                                value={item.title_color}
                                onChange={(e) => {
                                    let sebo = [...BrandVersionImages];
                                    
                                    sebo[index].colors_version[index2].title_color = e.target.value;
                                    setBrandVersionImages(sebo);
                                }}
                            />
                        </div>
                        <div>
                            <p className='identity_color_text'>#{index + 1} RGB - Red, Green, Blue</p>
                            <input
                                className='form-control input_colors'
                                placeholder='Sample rgb( 000, 000, 000 )'
                                value={item.color_rgb}
                                onChange={(e) => {
                                    let sebo = [...BrandVersionImages];
                                    sebo[index].colors_version[index2].color_rgb = e.target.value;
                                    setBrandVersionImages(sebo);
                                }}
                            />
                        </div>
                        <div>
                            <p className='identity_color_text'>#{index + 1} HEX - (Hexadecimal)</p>
                            <input
                                className='form-control input_colors'
                                placeholder='Sample #00000'
                                value={item.color_hex}
                                onChange={(e) => {
                                    let sebo = [...BrandVersionImages];
                                    sebo[index].colors_version[index2].color_hex = e.target.value;
                                    setBrandVersionImages(sebo);
                                }}
                            />
                        </div>
                        <div>
                            <p className='identity_color_text'>#{index + 1} CMYK - Cyan, Magenta, Yellow, Black </p>
                            <input
                                className='form-control input_colors'
                                placeholder='Sample C70 M84 Y0 K0'
                                value={item.color_cmyk}
                                onChange={(e) => {
                                    let sebo = [...BrandVersionImages];
                                    sebo[index].colors_version[index2].color_cmyk = e.target.value;
                                    setBrandVersionImages(sebo);
                                }}
                            />
                        </div>
                        <div>
                            <p className='identity_color_text'>#{index + 1} Pantone </p>
                            <input
                                className='form-control input_colors'
                                placeholder='Sample 7678 C'
                                value={item.pantone}
                                onChange={(e) => {
                                    let sebo = [...BrandVersionImages];
                                    sebo[index].colors_version[index2].pantone = e.target.value;
                                    setBrandVersionImages(sebo);
                                }}
                            />
                        </div>
                    </div>
                ))}
                <div className="add-more-Container-top mt-3">
                    <a onClick={() => {
                        let sebo = [...BrandVersionImages[index].colors_version];
                        let allInfo = [...BrandVersionImages];
                        
                        sebo.push({
                            "id_version_colors": null,
                            "title_color": "",
                            "color_rgb": "",
                            "color_hex": "",
                            "color_cmyk": "",
                            "pantone": ""
                        });
                        allInfo[index].colors_version = sebo;
                        setBrandVersionImages(allInfo);
                    }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Color</a>
                </div>
            </div>
        </>
    )
}
