import React, { useState } from 'react';
import { MembershipCardContainer } from '../MembershipCardContainer/MembershipCardContainer';
import './Memberships.style.css';

export const Memberships = () => {
    const [memberships, setMemberships] = useState([
        {
            title: "Free",

            text: "Standard",
            content: [
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
            ],
        },
        {
            title: "Premium",
            text: "Standard",
            content: [
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
                {
                    contentson: "test"
                },
            ],
        },
    ]);
    return (
        <div>
            <div className='mymembership_info_container'>
                <div className="infouser_header border-bottom">
                    <p className='profileTitle'>My profile</p>
                </div>
                <p className='subtitle_Profile mt-3 mb-4'>Membership</p>
                <div className="row row-cols-1 row-cols-md-3 mymembership_info_camp">
                    <div className="col d-flex">
                        <p className='p_membership_info_camp me-1'>Level</p>
                        <p className='p_membership_info_camp free_plan'>Free</p>
                    </div>
                    <div className="col d-flex">
                        <p className='p_membership_info_camp me-2 modified_membership'>Change</p>
                        <p className='p_membership_info_camp modified_membership'>Cancel</p>
                    </div>
                    <div className="col d-flex">
                        <p className='p_membership_info_camp me-3'>Billing expiration</p>
                        <p className='p_membership_info_camp fw-bold'>Free</p>
                    </div>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 memberships_cards">
                {memberships.map((item, index) => (
                    <MembershipCardContainer membership={item} key={index} />
                ))}
            </div>
        </div>
    )
}
