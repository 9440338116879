import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DropzoneFlyers } from '../../Helpers/DropzoneFlyers';
import { SERVICES } from '../../Services/Services';

export const BrandFlyers = ({ fetchData, flyers, setFlyers, flyers_show, setFlyers_show }) => {

    let floatingData = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let newone = {
        "id_flyers": null,
        "path": "",
        "path_url": "",
    }

    const deleteAllFlyers = async () => {
        await SERVICES.deleteAllBrandFlyers(floatingData.id).then((res) => {
           
            setFlyers([newone]);
            fetchData();
        }).catch((error) => {
            console.error('error delete all logos ->', error);
        });
    };


    return (
        <div id='BrandCore' className='submenu_maincontainer'>
            <div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Add Flyers file <i className="bi bi-exclamation-circle" /></p>
                        {!flyers_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setFlyers_show(true) }} className="bi bi-square" /></p>}
                        {flyers_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setFlyers_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    {/* <button onClick={(e) => { e.preventDefault(); deleteAllFlyers() }} className='btn btn-danger mb-2'>
                        Delete All Flyers
                    </button> */}
                    {Array(...flyers).map((item, index) => (
                        <DropzoneFlyers
                            flyers={flyers}
                            setFlyers={setFlyers}
                            key={index}
                            index={index}
                        />
                    ))}
                    <div className="d-flex justify-content-end">
                        <a onClick={(e) => {
                            e.preventDefault();
                            
                            let newObj = [...flyers];
                            newObj.push(newone);
                            setFlyers(newObj);

                        }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
