
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { SERVICES } from '../../Services/Services';
import './DeleteBrandModal.style.css';


export const DeleteBrandModal = ({ brand, fetchData, viewModalDelete, setViewModalDelete }) => {
    const deleteBrand = async () => {
        await SERVICES.deleteBrand(brand.url_brand).then((res) => {
            
            setViewModalDelete(false);
            fetchData();
        }).catch((error) => {
            console.error('error delete brand ->', error);
            setViewModalDelete(false);
        });
        setViewModalDelete(false);
    };
    return (
        <Modal className='d-flex align-items-center' show={viewModalDelete} onHide={() => setViewModalDelete(false)}>
            <Modal.Body className='p-5'>
                <p className='fs-4 fw-bold text-center'>Delete Confirmation</p>
                <p className='text-center fw-bold'>Are you sure you want to delete this?</p>
                <div className="row row-cols-1 row-cols-sm-2 mt-4">
                    <div className='col'>
                        <button onClick={(e) => { e.preventDefault(); setViewModalDelete(false); }} className='cancel_delete_button w-100 py-2'>
                            Cancel
                        </button>
                    </div>
                    <div className='col'>
                        <button onClick={(e) => { e.preventDefault(); deleteBrand(); }} className='save_exit_button w-100'>
                            Delete Brand
                        </button>
                    </div>

                </div>
                {/* <p>Are you sure that you want to remove {brand.title} created by {brand.display_name}?</p> */}
            </Modal.Body>
            {/* <Modal.Footer>
                <button onClick={(e) => {e.preventDefault(); setViewModalDelete(false);}} className='btn btn-secondary'>
                    Cancel
                </button>
                <button onClick={(e) => {e.preventDefault(); deleteBrand();}} className='btn btn-danger'>
                    Delete Brand
                </button>
            </Modal.Footer> */}
        </Modal>
    )
}
