import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import './ProfileSidebar.style.css';


export const ProfileSidebar = () => {
    const { user } = useSelector((state) => state.authSession);
    const {pathname} = useLocation();
    const { path } = useRouteMatch();

    const history = useHistory();
    const listMenu = [
        {
            title: "MEMBERSHIP",
            path: path + "/Memberships",
        },
        {
            title: "ACCOUNT",
            path: path + "/InfoUser",
        },
        {
            title: "INVOICES",
            path: path + '/Invoices',
        },
    ];

    useEffect(() => {
        // console.log('path hola ->', pathname)
        // setPathMe(pathname);
    }, [])
    return (
        <div className="sidebar_profile_menu">
            <div className='profile_box_container my-4'>
                <img
                    src={user ? `${user.avatar}?=${performance.now()}` : ""}
                    alt='img-profile'
                    className={`circular_profile_img ?timestamp=${performance.now()}`}
                />
                <p className='name_ptofile_box'>{user ? user.name : ""}</p>
                <p className='email_profile_box'>{user ? user.email : ""}</p>
            </div>
            {listMenu.map((item, index) => (
                <div onClick={(e) => {e.preventDefault(); history.push(item.path)}} className={`btn-sidebar-menu ${pathname  === item.path ? 'active_path_sidebar' : null}`} key={index}>
                    <p className=''>{item.title}</p>
                    <p><i className="fa fa-angle-right" aria-hidden="true" /></p>
                </div>
            ))}
        </div>
    );
};
