import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DropzoneAssets } from '../../Helpers/DropzoneAssets';
import { DropzoneFlyers } from '../../Helpers/DropzoneFlyers';
import { SERVICES } from '../../Services/Services';

export const BrandAssets = ({ fetchData, assets, setAssets, assets_show, setAssets_show }) => {
    let floatingData = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let newone = {
        "asset_title": "",
        "id_asset": null,
        "path": "",
        "path_url": ""
    }

    const deleteAllAssets = async () => {
        await SERVICES.deleteAllBrandAssets(floatingData.id).then((res) => {
            
            setAssets([newone]);
            fetchData();
        }).catch((error) => {
            console.error('error delete all logos ->', error);
        });
    };


    return (
        <div id='BrandCore' className='submenu_maincontainer'>
            <div>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center">
                        <p className='label_input_settings me-auto'>Add New Assets <i className="bi bi-exclamation-circle" /></p>
                        {!assets_show && <p>Show <i onClick={(e) => { e.preventDefault(); setAssets_show(true) }} className="bi bi-square" /></p>}
                        {assets_show && <p>Show <i onClick={(e) => { e.preventDefault(); setAssets_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    {/* <button onClick={(e) => { e.preventDefault(); deleteAllAssets() }} className='btn btn-danger mb-2'>
                        Delete All Assets
                    </button> */}
                    <div className="row row-cols-1 row-cols-md-3">
                        {Array(...assets).map((item, index) => (
                            <DropzoneAssets
                                assets={assets}
                                index={index}
                                setAssets={setAssets}
                                key={index}
                            />
                        ))}
                        <div className="add-more-Container-top mt-3">
                            <a onClick={(e) => {
                                e.preventDefault();
                                let newObj = [...assets];
                                newObj.push(newone);
                                setAssets(newObj);
                            }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> New Asset</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
