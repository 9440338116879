import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { DropZoneSymbolsContructions } from '../../Helpers/DropZoneSymbolsConstruction';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandConstruction = ({ text_construction, setText_construction, image_construction, setImage_construction, image_constructionUrl, setImage_contructionUrl, brandConstructionsSymbols, setBrandConstructionsSymbols, text_construction_show, setText_construction_show, image_construction_show, setImage_construction_show }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image();
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");
        reader.readAsDataURL(file);
        reader.onload = () => {
            img.src = reader.result
            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                setImage_contructionUrl(reader.result);
                setImage_construction(base64);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    })
    const modalLogoConstruction=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/7-Logo-Construccion-Image.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalConstructionDesc =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/8-Logo-Construccion-description.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalSymbolConstruction =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/9-Symbol-Construccion-Image.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    return (
        <>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center">
                    <p className='label_input_settings me-auto'>Logo Construction <Link onClick={(e) => {modalLogoConstruction();}}><i className="bi bi-exclamation-circle" /></Link></p>

                    {!image_construction_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setImage_construction_show(true) }} className="bi bi-square" /></p>}
                    {image_construction_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setImage_construction_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x800px</p>
                <div className="img_infouser_container bg-white mb-4" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        image_constructionUrl === "" ?
                            <div className='text-center'>
                                <i className="bi bi-image picture_avatar_suerinfo" />
                                <p className='placeholder_img'>Drag & drop your Logo Construction here or browse.</p>
                                <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                            </div> :
                            <img
                                className='img-profile-user'
                                src={String(image_constructionUrl).includes('http') === true ? image_constructionUrl + `?=${performance.now()}` : image_constructionUrl}
                            />
                    }
                </div>
                <div className="d-flex justifi-content-center">
                    <p className='logo_version_url me-auto'>Construction Description here <Link onClick={(e) => {modalConstructionDesc();}}><i className="bi bi-exclamation-circle" /></Link></p>
                </div>
                <textarea
                    value={text_construction}
                    onChange={(e) => { setText_construction(e.target.value) }}
                    className='form-control input_settings'
                    rows={5}
                    onKeyDown={(e) => StringJumpLine(e.code, e, setText_construction)}
                />
            </div>

            <div className='inputs_settings_container'>
                <p className='logo_version_url'>Symbol Construction <Link onClick={(e) => {modalSymbolConstruction();}}><i className="bi bi-exclamation-circle" /></Link></p>
                <div className='row row-cols-1 row-cols-md-2'>
                    {Array(...brandConstructionsSymbols).map((item, index) => (
                        <DropZoneSymbolsContructions
                            key={index}
                            brandConstructionsSymbols={brandConstructionsSymbols}
                            index={index}
                            setBrandConstructionsSymbols={setBrandConstructionsSymbols}
                        />
                    ))}

                </div>
            </div>
        </>
    )
}
