// const API_ENDPOINT = "http://localhost:7000/api/";
const API_ENDPOINT = "https://api.brandspace.ai/api/";

export const URIS = {
    signin                       : `${API_ENDPOINT}signin`,
    signup                       : `${API_ENDPOINT}signup`,
    logout                       : `${API_ENDPOINT}logout`,
    showUser                     : `${API_ENDPOINT}users/profile`,
    mybrands                     : `${API_ENDPOINT}brands-owned`,
    createbrand                  : `${API_ENDPOINT}create-brand`,
    restore_password_session     : `${API_ENDPOINT}restore-password-session`,
    restore_password             : `${API_ENDPOINT}restore-password`,
    brand_core                   : `${API_ENDPOINT}brand-core`,
    brand_statement              : `${API_ENDPOINT}brand-statement`,
    brand_mission_vision         : `${API_ENDPOINT}brand-mission-vision`,
    brand_personality            : `${API_ENDPOINT}brand-personality`,
    brand_tones                  : `${API_ENDPOINT}brand-tones`,
    brand_manifiesto             : `${API_ENDPOINT}brand-manifiesto`,
    delete_brand                 : `${API_ENDPOINT}delete-brand`,
    brand_tones_personality      : `${API_ENDPOINT}brand-tones-personality`,
    brand_tones_communication    : `${API_ENDPOINT}brand-tones-communication`,
    brand_core_standard          : `${API_ENDPOINT}brand-core-standard`,
    brand_identity               : `${API_ENDPOINT}brand-identity`,
    brand_constructions          : `${API_ENDPOINT}brand-constructions`,
    brand_versions               : `${API_ENDPOINT}brand-versions`,
    brand_url_treatments         : `${API_ENDPOINT}brand-url-treatments`,
    brand_donts                  : `${API_ENDPOINT}brand-donts`,
    brand_colors_pallete         : `${API_ENDPOINT}brand-pallet-colors`,
    brand_typography             : `${API_ENDPOINT}brand-typography`,
    brand_photographys           : `${API_ENDPOINT}brand-photographys`,
    brand_amplifier              : `${API_ENDPOINT}brand-amplifier`,
    brand_amplifier_images       : `${API_ENDPOINT}brand-amplifier-images`,
    brand_iconographys           : `${API_ENDPOINT}brand-iconographys`,
    brand_constructions_symbols  : `${API_ENDPOINT}brand-constructions-symbols`,
    brand_url_treatments_image   : `${API_ENDPOINT}brand-url-treatments-image`,
    brand_values                 : `${API_ENDPOINT}brand-values`,
    delete_brand_value           : `${API_ENDPOINT}brand-value`,
    brand_identity_color         : `${API_ENDPOINT}brand-identity-color`,
    public_brands_strategy       : `${API_ENDPOINT}public-brands-strategy`,
    brand_photographys_images    : `${API_ENDPOINT}brand-photographys-images`,
    brand_photographys_bullets   : `${API_ENDPOINT}brand-photographys-bullets`,
    brand_pattern_description    : `${API_ENDPOINT}brand-pattern-description`,
    brand_pattern_images         : `${API_ENDPOINT}brand-pattern-images`,
    brand_background_description : `${API_ENDPOINT}brand-background-description`,
    brand_background_images      : `${API_ENDPOINT}brand-background-images`,
    brand_primary_color          : `${API_ENDPOINT}brand-primary-color`,
    brand_secondary_color        : `${API_ENDPOINT}brand-secondary-color`,
    brand_background_color       : `${API_ENDPOINT}brand-background-color`,
    brand_iconographys_icons     : `${API_ENDPOINT}brand-iconographys-icons`,
    brand_logos_resources        : `${API_ENDPOINT}brand-logos-resources`,
    brand_powerpoint_resources   : `${API_ENDPOINT}brand-powerpoint-resources`,
    brand_teams_resources        : `${API_ENDPOINT}brand-teams-resources`,
    brand_linkedln_resources     : `${API_ENDPOINT}brand-linkedln-resources`,
    brand_letterhead_resources   : `${API_ENDPOINT}brand-letterhead-resources`,
    brand_icons_resources        : `${API_ENDPOINT}brand-icons-resources`,
    brand_flyers_resources       : `${API_ENDPOINT}brand-flyers-resources`,
    brand_assets_resources       : `${API_ENDPOINT}brand-assets-resources`,
    brands_public_resources      : `${API_ENDPOINT}brands-public-resources`,
    brands_public_standards      : `${API_ENDPOINT}brands-public-standards`,
    forgot_password              : `${API_ENDPOINT}forgot-password`,
    brand_settings               : `${API_ENDPOINT}brand-settings`,
    brand_url_friendly           : `${API_ENDPOINT}brand-url-friendly`,
    brand_taglines               : `${API_ENDPOINT}brand-taglines`,
    download_resource_logos      : `${API_ENDPOINT}download-resource`,
    download_resource_icons      : `${API_ENDPOINT}download-resource-icons`,
    download_resource_flyers     : `${API_ENDPOINT}download-resource-assets`,
    download_resource_assets     : `${API_ENDPOINT}download-resource-flyer`,
    verify_token                 : `${API_ENDPOINT}verify-token`,
    brands_status                : `${API_ENDPOINT}brands/status`,
    brands_status_build          : `${API_ENDPOINT}brands/status-public`,
    image_helper : 'https://wonderbrands.sfo3.digitaloceanspaces.com/system_images/'
}