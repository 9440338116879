import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { DropZoneHelper } from '../../Helpers/Dropzone';
import { DropZoneSymbolsContructions } from '../../Helpers/DropZoneSymbolsConstruction';
import { DropZoneUrl } from '../../Helpers/DropzoneUrl';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export const BrandUrl = ({ text_construction, setText_construction, urlImages, setUrlImages, text_url_treatment_show, setText_url_treatment_show }) => {

    let newone = {
        "id_url_img": null,
        "image_url": "",
        "image_path": "",
        "id_brand": null,
    }
    const modalUrlTreat=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/10-URL-Treatment-Image.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalUrlTreatDesc=() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/11-URL-Treatment-description.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    return (
        <>
            <div className='inputs_settings_container'>
                <div className='d-flex justifi-content-center'>
                    <p className='label_input_settings me-auto'>URL Logo Treatment <Link onClick={(e) => {modalUrlTreat();}}><i className="bi bi-exclamation-circle" /></Link></p>
                    {!text_url_treatment_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_url_treatment_show(true) }} className="bi bi-square" /></p>}
                    {text_url_treatment_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setText_url_treatment_show(false) }} className="bi bi-check-square" /></p>}
                </div>
                <p className='recomendationText'>We recommended landscape format. Maximum images: 1200x800px</p>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                    {Array(...urlImages).map((item, index) => (
                        <DropZoneUrl
                            key={index}
                            urlLogos={urlImages}
                            setUrlLogos={setUrlImages}
                            index={index}
                        />
                    ))}
                    <div className="add-more-Container-top mt-3">
                        <a onClick={(e) => {
                            e.preventDefault();
                            
                            let newObj = [...urlImages];
                            newObj.push(newone);
                            
                            setUrlImages(newObj);
                        }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New URL Logo Image</a>
                    </div>
                </div>
                <div className="d-flex">
                    <p className='label_input_settings me-auto'>URL Treatment description here <Link onClick={(e) => {modalUrlTreatDesc();}}><i className="bi bi-exclamation-circle" /></Link></p>

                </div>
                <textarea
                    value={text_construction}
                    type="textarea"
                    rows={6}
                    onChange={(e) => { setText_construction(e.target.value) }}
                    className='form-control input_settings'
                />
            </div>

        </>
    )
}
