import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const DropzoneAmplifierHere = ({ index, index2, amplifiers, setAmplifiers, item }) => {
    let floatingData = useParams();
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                const base64 = getBase64StringFromDataURL(reader.result);
                let sebo = [...amplifiers];
                sebo[index].PatternImages[index2].image_pattern = base64;
                sebo[index].PatternImages[index2].image_pattern_url = reader.result;
                setAmplifiers(sebo);
                // setPathUrl(reader.result);
                // setPath64(base64);
            }
        };


        // setIsLoading(false);
    });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });

    const deleteBrandImage = async () => {
        if (String(amplifiers[index].PatternImages[index2].image_pattern_url).includes('http') === true) {
            await SERVICES.deleteBrandAmplifierImg(floatingData.id, amplifiers[index].PatternImages[index2].id_amplipatternimg).then((res) => {

                let sebo = [...amplifiers];
                sebo[index].PatternImages.splice(index, 1);
                // fetchData();
                setAmplifiers(sebo);
            }).catch((error) => {
                console.error('error delete amplifier img ->', error);
            });
        } else {
            let sebo = [...amplifiers];
            sebo[index].PatternImages.splice(index, 1);
            setAmplifiers(sebo);
        }
    };
    const modalAmplifierPhoto = () => {
        Swal.fire({
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/27-Amplifier-Image.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    return (
        <div>
            <div className="d-flex align-items-center">
                <p className='symbol_title me-auto'>#{index2 + 1} Photo <Link onClick={(e) => { modalAmplifierPhoto(); }}><i className="bi bi-exclamation-circle" /></Link></p>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteBrandImage(index);
                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    amplifiers[index].PatternImages[index2].image_pattern_url === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop your photo here or browse</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={String(amplifiers[index].PatternImages[index2].image_pattern_url).includes("http") === true ? amplifiers[index].PatternImages[index2].image_pattern_url + `?=${performance.now()}` : amplifiers[index].PatternImages[index2].image_pattern_url}
                        />
                }
            </div>
        </div>
    );
};



export const BrandAmplifier = ({ amplifiers_show, setAmplifiers_show, amplifiers, setAmplifiers }) => {
    let floatingData = useParams();
    let newOne = {
        "id_amplipattern": null,
        "title_amplifier": "",
        "text_amplifier": "",
        "PatternImages": [
            {
                "image_pattern": "",
                "image_pattern_url": "",
                "id_amplipatternimg": null
            }
        ]
    }
    let newOneImg = {
        "image_pattern": "",
        "image_pattern_url": "",
        "id_amplipatternimg": null
    }

    const deleteCategory = async (index) => {
        if (amplifiers[index].id_amplipattern !== null) {
            await SERVICES.deleteBrandFullAmplifier(floatingData.id, amplifiers[index].id_amplipattern).then((res) => {

                let sebo = [...amplifiers];
                sebo.splice(index, 1);
                // fetchData();
                setAmplifiers(sebo);
            }).catch((error) => {
                console.error('error delete amplifier cat full ->', error);
            });
        } else {
            let sebo = [...amplifiers];
            sebo.splice(index, 1);
            setAmplifiers(sebo);
        }
    };

    const modalAmplifier = () => {
        Swal.fire({
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/25-Amplifier-Tittle.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const modalAmplifierDesc = () => {
        Swal.fire({
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_standards/26-Amplifier-Tittle.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    const amplifiersText = 'Show';
    const amplifiersIcon = amplifiers_show ? 'bi bi-check-square' : 'bi bi-square';


    return (
        <div>
            <div className='inputs_settings_container'>
                <div className="d-flex justifi-content-center mb-3">
                    <p className='label_input_settings me-auto'>Amplifier <Link onClick={(e) => { modalAmplifier(); }}><i className="bi bi-exclamation-circle" /></Link></p>
                    <p className="ShowText">
                        {amplifiersText}{" "}
                        <i onClick={() => setAmplifiers_show(!amplifiers_show)} className={amplifiersIcon} />
                    </p></div>
                {Array(...amplifiers).map((item, index) => (
                    <div key={index}>
                        <div className='d-flex align-items-center'>
                            <label className='symbol_title me-auto'>#{index + 1} Category Name</label>
                            <a onClick={(e) => {
                                e.preventDefault();
                                deleteCategory(index);
                            }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
                        </div>
                        <input
                            value={item.title_amplifier}
                            placeholder='Samples: Patterns, Backgrounds'
                            onChange={(e) => {
                                let sebo = [...amplifiers];
                                sebo[index].title_amplifier = e.target.value;
                                setAmplifiers(sebo);
                            }}
                            className='form-control input_settings'
                        />
                        <label className='symbol_title me-auto'>Description <Link onClick={(e) => { modalAmplifierDesc(); }}><i className="bi bi-exclamation-circle" /></Link></label>

                        <textarea
                            value={item.text_amplifier}
                            rows={4}
                            onChange={(e) => {
                                let sebo = [...amplifiers];
                                sebo[index].text_amplifier = e.target.value;
                                setAmplifiers(sebo);
                            }}
                            className='form-control input_settings'
                        />
                        <div className="row row-cols-1 row-cols-md-3">
                            {Array(...item.PatternImages).map((item2, index2) => (
                                <DropzoneAmplifierHere
                                    key={index2}
                                    amplifiers={amplifiers}
                                    index={index}
                                    item={item2}
                                    setAmplifiers={setAmplifiers}
                                    index2={index2}
                                />
                            ))}
                            <div className="add-more-Container-top mt-3">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    let sebo = [...amplifiers[index].PatternImages];
                                    let allInfo = [...amplifiers];
                                    sebo.push(newOneImg);
                                    allInfo[index].PatternImages = sebo;
                                    setAmplifiers(allInfo);
                                }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add Photo</a>
                            </div>

                        </div>
                    </div>
                ))}
                <div className="d-flex justify-content-end">
                    <a onClick={(e) => {
                        e.preventDefault();
                        let newObj = [...amplifiers];
                        newObj.push(newOne);
                        setAmplifiers(newObj);
                    }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Category</a>
                </div>
            </div>
        </div>
    );
};
