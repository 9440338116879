import React from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../Services/Services';

export const DeleteButton = ({title, id, index, fetchData, type, dataArray, setDataArray}) => {
    //type 1 = brand values
    let floatingData = useParams();

    const onlyRemoveFromArray = () => {
        
        let sebo = [...dataArray];
        sebo.splice(index, 1);
        setDataArray(sebo);
    }

    const deleteFunction = async() => {
        //console.log('cosas ->', {title, id, index, fetchData, type, dataArray, setDataArray});
        if(id === null) return onlyRemoveFromArray();
        switch (type) {
            case 1:
                await SERVICES.deleteBrandValuesById(floatingData.id, id).then((res) => {
                    // console.log('res delete by id ->', res);
                    let sebo = [...dataArray];
                    sebo.splice(index, 1);
                    setDataArray(sebo);
                    // fetchData();
                }).catch((error) => {
                    // console.log('error delete ->', error);
                })
                break;
        
            default:
                
                break;
        }
    }
    return (
        <a onClick={(e) => {e.preventDefault(); deleteFunction()}} className='deleteOne myOwn'>
            <span className="bi bi-x-circle-fill deleteCircle"/>
        </a>
    )
}
