import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';

export const DropzoneFlyers = ({ index, flyers, setFlyers }) => {
    let floatingData = useParams();
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...flyers];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].path = base64;
                sebo[index].path_url = reader.result;

                setFlyers(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });

    const deleteFlyer = async () => {
        if (String(flyers[index].path_url).includes('http') === true) {
            await SERVICES.deleteBrandFlyer(floatingData.id, flyers[index].id_flyers).then((res) => {
                
                let sebo = [...flyers];
                sebo.splice(index, 1);
                // fetchData();
                setFlyers(sebo);
            }).catch((error) => {
                console.error('error delete all logos ->', error);
            });
        } else {
            let sebo = [...flyers];
            sebo.splice(index, 1);
            setFlyers(sebo);
        }
    };


    return (
        <>

            <div className="d-flex align-items-center">
                <p className='fw-bold me-auto'>Flyer No. {index + 1}</p>
                <a onClick={(e) => { e.preventDefault(); deleteFlyer() }}
                    className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" />
                </a>
            </div>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    flyers[index].path_url === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop flyer file here or browse</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={String(flyers[index].path_url).includes('http') === true ? flyers[index].path_url + `?=${performance.now()}` : flyers[index].path_url}
                        />
                }
            </div>
        </>
    )
}
