import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';

export const DropZonePhotography = ({ index, photografies, setPhotografies }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...photografies];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].image_photography_url = reader.result;
                sebo[index].image_photography = base64;
                setPhotografies(sebo);
            }
        };
        // setIsLoading(false);
    }); 
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });
    const deleteBrandPhotography = async () => {
        if (String(photografies[index].image_photography_url).includes('http') === true) {
            await SERVICES.deleteBrandPhotography(floatingData.id, photografies[index].id_Photographyimg).then((res) => {
                
                let sebo = [...photografies];
                sebo.splice(index, 1);
                // fetchData();
                setPhotografies(sebo);
            }).catch((error) => {
                console.error('error delete photography ->', error);
            });
        } else {
            let sebo = [...photografies];
            sebo.splice(index, 1);
            setPhotografies(sebo);
        }
    };

    return (
        <div>
            <div className="d-flex align-items-center">
                <p className='symbol_title me-auto'>Image Nº{index + 1}</p>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteBrandPhotography();
                }} className='deleteOne alignright' style={{ textAlign: 'right' }}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    photografies[index].image_photography_url === "" ?
                        <div className='text-center'>
                            <i className="bi bi-image picture_avatar_suerinfo" />
                            <p className='placeholder_img'>Drag & drop your photo here or browse</p>
                            <p className='format_image_style'>(.png, .jpg, .jpeg and .svg)</p>
                        </div> :
                        <img
                            className='img-profile-user'
                            src={String(photografies[index].image_photography_url).includes('http') === true ? photografies[index].image_photography_url + `?=${performance.now()}` : photografies[index].image_photography_url}
                        />
                }
            </div>
        </div>
    )
}
