import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../store/auth/ProfileStatus';
import { BrandCore } from '../BrandCore';
import { BrandPersonality } from '../BrandPersonality';
import { BrandStatement } from '../BrandStatement';
import { BrandStrategyValues } from '../BrandStrategyValues/BrandStrategyValues';
import { Manifiesto } from '../Manifiesto';
import { MissionVision } from '../MissionVission';
import { StringJumpLine } from '../../Helpers/StringJumpLine';
import { getCookie } from '../../utils/cookies';
import StickyBox from "react-sticky-box";
import LoadingModal from '../../Components/LoadingModal';
import { Link } from 'react-router-dom';


export const BrandStrategy = ({fetchDataSidebar}) => {
    const history = useHistory();
    let floatingData = useParams();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    //BrandCore
    const [textCover, setTextCover] = useState("");
    const [show_textCover, setShow_textCover] = useState(false);
    const [show_photoCoverBrandCore, setShow_photoCoverBrandCore] = useState(false);
    const [show_textCore, setShow_textCore] = useState(false);
    const [photoCover, setPhotoCover] = useState("");
    const [photoCoverUrl, setPhotoCoverUrl] = useState("");
    const [textCore, setTextCore] = useState("");
    const [url_friendly, setUrl_friendly] = useState("");
    const [project, setProject] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    //end brand core

    //Brand Statement
    const [brand_statement, setBrand_statement] = useState("");
    const [show_brandStatementText, setShow_brandStatementText] = useState(false);
    const [photoCoverStatement, setPhotoCoverStatement] = useState("");
    const [show_brandStatementImg, setShow_brandStatementImg] = useState(false);
    const [photoCoverUrlStatement, setPhotoCoverUrlStatement] = useState("");
    //End Statement

    //Mission and vision
    const [text_mission, setText_mission] = useState("");
    const [text_mission_show, setText_mission_show] = useState(false);
    const [text_vision, setText_vision] = useState("");
    const [text_vision_show, setText_vision_show] = useState(false);
    //End Mission and vision

    //Brand Values
    const [show_values, setShow_values] = useState(false);
    const [brandValues, setBrandValues] = useState([
        {
            "id_values": null,
            "title_value": "",
            "text_value": "",
            "image_value": "",
            "image_value_url": ""
        },
    ]);
    //End Brand Values

    //Brand Manifiesto
    const [text_manifiesto, setText_manifiesto] = useState("");
    const [photoCoverManifiesto, setPhotoCoverManifiesto] = useState("");
    const [photoCoverUrlManifiesto, setPhotoCoverUrlManifiesto] = useState("");
    const [text_manifiesto_show, setText_manifiesto_show] = useState(false);
    const [photo_manifiesto_show, setPhoto_manifiesto_show] = useState(false);
    //End Manifiesto



    //Brand Personality
    const [text_personality, setText_personality] = useState("");
    const [text_communication, setText_communication] = useState("");
    const [text_communication_show, setText_communication_show] = useState(false);
    const [text_personality_show, setText_personality_show] = useState(false);

    const [tones_Personality, setTones_Personality] = useState([
        {
            "text_perso_tone": "",
            "percentage_perso_tone": "",
            "id_tones_perso": null,
        },
    ]);

    const [tones_Communication, setTones_Communication] = useState([
        {
            "text_comu_tone": "",
            "percentage_comu_tone": "",
            "id_tones_comu": null,
        },
    ]);
    //End brand Personality


    //Brand Taglines
    const [tagline_show, setTagline_show] = useState(false);
    const [taglines, setTaglines] = useState([
        {
            "id_tagline": null,
            "title_tagline": "",
            "text_tagline": '',
        },
    ])
    //End brand taglines

    const killSession = async () => {
        Swal.fire({ title: 'Your token expired', icon: 'error', confirmButtonColor: 'red' });
        dispatch(logOut());
        history.push('/Login');
    }

    const saveBrandManifiesto = async (e) => {
        let data = {
            "text_manifiesto": text_manifiesto,
            "photo_manifiesto": photoCoverManifiesto,
            "manifiesto_show": text_manifiesto_show,
        };
        // console.log('data manifiesto ->', data);
        await SERVICES.addBrandManifiesto(data, floatingData.id).then((res) => {
            
            // if(res.status != 200) handleError('Something wrong', '');
            // // Swal.fire({title:'Saved info correctly', icon:'success'});
            // fetchData();
        }).catch((error) => {
            console.error('Error core ->', e);
        })

    };

    const saveBrandPersonality = async () => {
        let data = {
            "personality_show": text_personality_show,
            "text_personality": text_personality,
            "text_communication": text_communication,
        };
        
        await SERVICES.addBrandPersonality(data, floatingData.id).then((res) => {
            
            // if(res.status != 200) handleError('Something wrong', '');
            // Swal.fire({title:'Saved info correctly', icon:'success'});
            // fetchData();
        }).catch((error) => {
            console.error('Error identity info ->', error);
        });

        await SERVICES.addBrandPersonalityArrays({
            "Tones_Personality": tones_Personality,
            "Tones_Communication": tones_Communication
        }, floatingData.id).then((res) => {
            
            // if(res.status != 200) handleError('Something wrong', '');
            // Swal.fire({title:'Saved info correctly', icon:'success'});
            // fetchData();
        }).catch((error) => {
            console.error('Error identity array ->', error);
        });

    };

    const saveBrandMissionVision = async () => {
        let data = {
            "text_mission": text_mission,
            "text_vision": text_vision,
            "mision_vision_show": text_mission_show,
        };
        await SERVICES.addBrandMissionVision(data, floatingData.id).then((res) => {
            // if(res.status != 200) handleError('Something wrong', '');
            // Swal.fire({title:'Saved info correctly', icon:'success'});
            // fetchData();
        }).catch((error) => {
            console.error('Error core ->', error);
        })

    };
    const saveBrandValues = async () => {
        let valores = {
            "status": [
                {
                    "values_show": show_values
                }
            ],
            "values": [
            ]
        }
        brandValues.map((item, index) => {
            valores.values.push(item);
        });
        await SERVICES.addBrandValues(valores, floatingData.id).then((res) => {
            setBrandValues([]);
            fetchDataValues();
        }).catch((error) => {
            console.error('Error core ->', error);
        })


    };

    const saveBrandStatement = async () => {
        let data = {
            "brand_statement": brand_statement,
            "photo_statement": photoCoverStatement,
            "brand_statement_show": show_brandStatementText,
        };
        
        await SERVICES.addBrandStatement(data, floatingData.id).then((res) => {
            // console.log("res save statement ->", res);
            // if(res.status != 200) handleError('Something wrong', '');
            // Swal.fire({title:'Saved info correctly', icon:'success'});
            // fetchData();
        }).catch((error) => {
            console.error('Error core ->', error);
        })

    };

    const saveBrandCore = async () => {
        
        let data = {
            "text_cover": textCover,
            "text_core": textCore,
            "photo_cover": photoCover,
            "brand_core_show": show_textCover,
        };
        
        await SERVICES.addBrandCore(data, floatingData.id).then((res) => {
            // console.log("res core ->", res)
            // Swal.fire({ title: 'Saved info correctly', icon: 'success' });
        }).catch((error) => {
            console.error('Error core ->', error);
        })
    };

    const saveTaglines = async () => {
        let data = {
            "status": [
                {
                    "tagline_show": tagline_show
                }
            ],
            "Taglines": taglines
        }
        await SERVICES.addBrandTaglines(data, floatingData.id).then((res) => {
            // console.log("res save TagLines ->", res)
            // Swal.fire({ title: 'Saved info correctly', icon: 'success' });
        }).catch((error) => {
            console.error('Error core ->', error);
        })
    }



    const fetchData = async () => {
        await SERVICES.getBrandTaglines(floatingData.id).then((res) => {
            // console.log("res get taglines ->", res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();

            let finalData = [];
            res.data.Taglines.map((item, index) => {
                finalData.push({
                    "title_tagline": item.title_tagline ?? "",
                    "text_tagline": item.text_tagline ?? "",
                    "id_tagline": item.id_tagline,
                });
            });
            if (finalData.length > 0) {
                setTaglines(finalData);
            }
            setTagline_show(res.data.statusTaglines.tagline_show ?? false);
        }).catch((error) => {
            console.error('Error get core ->', error);
        });
        await SERVICES.getMyBrandsSettings(floatingData.id).then((res) => {
            
            if (res.data.found === true) {
                setProject(res.data.data_settings);
                setUrl_friendly(res.data.url_friendly);
            }
        }).catch((error) => {
            console.error('Error ->', error);
        });
    }

    const deleteTagline = async (index) => {
        if (taglines[index].id_tagline !== "") {
            await SERVICES.deleteBrandTagline(floatingData.id, taglines[index].id_tagline).then((res) => {
                
                let sebo = [...taglines];
                sebo.splice(index, 1);
                // fetchData();
                setTaglines(sebo);
            }).catch((error) => {
                console.error('error delete tone ->', error);
            });
        } else {
            let sebo = [...taglines];
            sebo.splice(index, 1);
            setTaglines(sebo);
        }
    };

    const fetchDataValues = async () => {
        await SERVICES.getBrandValues(floatingData.id).then((res) => {
            // console.log("res get values ->", res);
            if (res.status === 401 && res.message.is_valid_token === false) return killSession();
            // if (res.status !== 200) return () => { dispatch(logOut()); history.push('/Login') };
            let finalData = [];

            if (res.data.Values.length > 0) {
                res.data.Values.map((item, index) => {
                    finalData.push({
                        "id_values": item.id_values,
                        "title_value": item.title_value ?? "",
                        "text_value": item.text_value ?? "",
                        "image_value": "",
                        "image_value_url": item.image_value ?? ""
                    });
                });
                setBrandValues(finalData);
            }
            setShow_values(res.data.statusValues.values_show ?? false)



        }).catch((error) => {
            console.error('Error get core ->', error);
        });
    };

    const copyToClipboard = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        navigator.clipboard.writeText(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + url_friendly + '/BrandStrategy');
        // navigator.clipboard.writeText(`http://localhost:3000/Client/` + url_friendly + '/BrandStrategy');
    };
    const ViewProject = () => {
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        window.open(`https://${jsonCookie.name_subdomain}.brandspace.ai/Client/` + url_friendly + '/BrandStrategy');
        //window.open(`http://localhost:3000/Client/` + url_friendly + '/BrandStrategy', '_blank');
    }
    // onClick={(e) => { e.preventDefault(); ViewProject() }}

    useEffect(() => {
        
        fetchDataValues();
        fetchData();
    }, []);
    
    const modalTagLine =() =>{
        Swal.fire({ 
            imageUrl: 'https://wonderbrands.sfo3.cdn.digitaloceanspaces.com/system_images/brand_strategy/14-Tagline.jpg',
            imageAlt: 'Help Image',
            showConfirmButton: false
        });
    }
    return (
        <>
            <LoadingModal isLoading={isLoading}/>
            <StickyBox offsetTop={0} offsetBottom={20}>
            <div className="title_submenu_container">
                <p className='submenu_title'>{project.title} Guideline</p>
                <p className='me-1' onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard();
                    Swal.fire({ title: "URL Coppied Correctly", icon: "success", showCancelButton: false, showConfirmButton: false, text: 'You can share de public URL now', timer: 2500, })
                }}><i className="bi bi-share" /> Share</p>
                <p style={{cursor: "pointer"}} className='me-1' onClick={(e) => {
                    e.preventDefault();
                    ViewProject()
                }}>
                    <i style={{cursor: "pointer"}} className="bi bi-search" /> Preview</p>
                <button onClick={(e) => { e.preventDefault(); history.push('/MyBrands/' + floatingData.id + '/Settings') }} className='settings_button me-1'>
                    <span className='bi bi-gear gearIcon'></span><span className='me-1'>Settings</span>
                </button>
                <button onClick={(e) => {
                    
                    e.preventDefault();
                    setIsLoading(true);
                    saveBrandCore();
                    saveBrandStatement();
                    saveBrandMissionVision();
                    saveBrandPersonality();
                    saveBrandManifiesto();
                    saveBrandValues();
                    saveTaglines();
                    setTimeout(() => {
                        fetchDataSidebar();
                        setIsLoading(false);
                        Swal.fire({ title: "Info Saved Correctly", icon: "success", confirmButtonColor: "#000000" })
                    }, 10000);
                    
                    
                }} id='button-save-brandform' className='save_exit_button'>
                    SAVE
                </button>
            </div>
            </StickyBox>
            <BrandCore
                killSession={killSession}
                photoCover={photoCover}
                photoCoverUrl={photoCoverUrl}
                saveBrandCore={saveBrandCore}
                setPhotoCover={setPhotoCover}
                setPhotoCoverUrl={setPhotoCoverUrl}
                setTextCore={setTextCore}
                setTextCover={setTextCover}
                textCore={textCore}
                textCover={textCover}
                show_textCover={show_textCover}
                setShow_textCover={setShow_textCover}
                show_photoCoverBrandCore={show_photoCoverBrandCore}
                setShow_photoCoverBrandCore={setShow_photoCoverBrandCore}
                show_textCore={show_textCore}
                setShow_textCore={setShow_textCore}
            />
            <BrandStatement
                killSession={killSession}
                brand_statement={brand_statement}
                photoCover={photoCoverStatement}
                photoCoverUrl={photoCoverUrlStatement}
                saveBrandStatement={saveBrandStatement}
                setBrand_statement={setBrand_statement}
                setPhotoCover={setPhotoCoverStatement}
                setPhotoCoverUrl={setPhotoCoverUrlStatement}
                setShow_brandStatementImg={setShow_brandStatementImg}
                setShow_brandStatementText={setShow_brandStatementText}
                show_brandStatementImg={show_brandStatementImg}
                show_brandStatementText={show_brandStatementText}
            />
            <MissionVision
                killSession={killSession}
                setText_mission={setText_mission}
                setText_vision={setText_vision}
                text_mission={text_mission}
                text_vision={text_vision}
                setText_mission_show={setText_mission_show}
                setText_vision_show={setText_vision_show}
                text_mission_show={text_mission_show}
                text_vision_show={text_vision_show}
            />
            <BrandStrategyValues
                killSession={killSession}
                brandValues={brandValues}
                setBrandValues={setBrandValues}
                setShow_values={setShow_values}
                show_values={show_values}
                fetchData={fetchDataValues}
            />
            <Manifiesto
                killSession={killSession}
                photoCover={photoCoverManifiesto}
                photoCoverUrl={photoCoverUrlManifiesto}
                setPhotoCover={setPhotoCoverManifiesto}
                setPhotoCoverUrl={setPhotoCoverUrlManifiesto}
                setText_manifiesto={setText_manifiesto}
                text_manifiesto={text_manifiesto}
                photo_manifiesto_show={photo_manifiesto_show}
                setPhoto_manifiesto_show={setPhoto_manifiesto_show}
                setText_manifiesto_show={setText_manifiesto_show}
                text_manifiesto_show={text_manifiesto_show}
            />
            <BrandPersonality
                killSession={killSession}
                setText_communication={setText_communication}
                setText_personality={setText_personality}
                text_personality={text_personality}
                setText_personality_show={setText_personality_show}
                text_personality_show={text_personality_show}
                setTones_Communication={setTones_Communication}
                setTones_Personality={setTones_Personality}
                tones_Communication={tones_Communication}
                tones_Personality={tones_Personality}
            />
            <div id="BrandTagline" className='submenu_maincontainer'>
                <div className='inputs_settings_container'>
                    <div className="d-flex justifi-content-center mb-4">
                        <p className='label_input_settings me-auto'>TagLine <Link onClick={(e) => {modalTagLine();}}><i className="bi bi-exclamation-circle" /></Link></p>
                        {!tagline_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setTagline_show(true) }} className="bi bi-square" /></p>}
                        {tagline_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setTagline_show(false) }} className="bi bi-check-square" /></p>}
                    </div>
                    <div className='row row-cols-1 row-cols-md-2'>
                        {taglines.map((item, index) => (
                            <div key={index}>
                                <div className='row row-cols-sm-2' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    <label className='value-label'>Title {index + 1}</label>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        deleteTagline(index)
                                    }} className='deleteOne alignright'><i className="bi bi-x-circle-fill deleteCircle" /></a>
                                </div>
                                <input
                                    value={item.title_tagline}
                                    onChange={(e) => {
                                        let sebo = [...taglines];
                                        sebo[index].title_tagline = e.target.value;
                                        setTaglines(sebo);
                                    }}
                                    className='form-control input_settings'
                                />
                                <label className='value-label'>Description</label>
                                <textarea
                                    value={item.text_tagline}
                                    type="textarea"
                                    rows={4}
                                    onChange={(e) => {
                                        let sebo = [...taglines];
                                        sebo[index].text_tagline = e.target.value;
                                        setTaglines(sebo);
                                    }}
                                    className='form-control input_settings'
                                />
                            </div>
                        ))}
                        <div className="add-more-Container-top mt-3">
                            <a onClick={(e) => {
                                e.preventDefault();
                                let newObj = [...taglines];
                                newObj.push({
                                    "id_tagline": "",
                                    "text_tagline": "",
                                    "text_tagline": ""
                                });
                                setTaglines(newObj);
                            }} className='addNewElement'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New Tagline</a>
                        </div>
                    </div>

                </div>
            </div>
            <div className='footer_container'>
                <div className='row'>
                    <div className='btn_canel_footer_container col-md-3'>
                        <button className='btn_cancel_footer' onClick={(e) => { e.preventDefault(); history.push('/Home/') }}>
                            Exit
                        </button>
                    </div>
                    <div className='text_footer col-6'>
                        <p className='brand_center'>BRAND CENTER</p>
                        <p className='desc_brand_center'>Continue fill the Brand Standards form</p>
                    </div>
                    <div className='btn_save_footer_container col-md-3'>
                        <button className='btn_save_footer'
                            onClick={(e) => {
                                setIsLoading(true);
                                saveBrandCore();
                                saveBrandStatement();
                                saveBrandMissionVision();
                                saveBrandPersonality();
                                saveBrandManifiesto();
                                saveBrandValues();
                                saveTaglines();
                                setTimeout(() => {
                                    setIsLoading(false);
                                    Swal.fire({ title: "Brand Strategy saved correctly", icon: "success", confirmButtonColor: "#000000" })
                                    history.push('/MyBrands/' + floatingData.id + '/BrandStandard')
                                }, 10000);
                            }}>
                            Save and Continue
                        </button>
                    </div>
                </div>

            </div>
            {/* <div className="pb-5 d-flex justify-content-end">
                <button onClick={(e) => {
                    e.preventDefault();
                    saveBrandCore();
                    saveBrandStatement();
                    saveBrandMissionVision();
                    saveBrandPersonality();
                    saveBrandManifiesto();
                    saveBrandValues();
                    saveTaglines();
                    Swal.fire({ title: "Info Saved Correctly", icon: "success", confirmButtonColor: "#000000" })
                }} id='button-save-settings-brands' className='save_exit_button'>
                    SAVE
                </button>
            </div> */}
        </>
    )
}
