import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { DropzoneIconsDownloads } from '../../Helpers/DropzoneIconsDownload';
import { SERVICES } from '../../Services/Services';



export const BrandIconsDownloads = ({ fetchData, icons, setIcons, icon_show, setIcon_show }) => {
    let floatingData = useParams();
    let newone = {
        "id_iconsFile": null,
        "path": "",
        "path_url": "",
    };

    const deleteAllIcons = async () => {
        await SERVICES.deleteAllBrandIcons(floatingData.id).then((res) => {
            setIcons([newone]);
            fetchData();
        }).catch((error) => {
            console.error('error delete all logos ->', error);
        });
    };

    return (
        <div className='inputs_settings_container'>
            <div className="d-flex">
                <p className='label_input_settings me-auto'>Icons <i className="bi bi-exclamation-circle" /></p>
                {!icon_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setIcon_show(true) }} className="bi bi-square" /></p>}
                {icon_show && <p className='ShowText'>Show <i onClick={(e) => { e.preventDefault(); setIcon_show(false) }} className="bi bi-check-square" /></p>}
            </div>
            {/* <button onClick={(e) => { e.preventDefault(); deleteAllIcons() }} className='btn btn-danger mb-2'>
                Delete All Icons
            </button>  */}
            {Array(...icons).map((item, index) => (
                <DropzoneIconsDownloads
                    key={index}
                    icons={icons}
                    index={index}
                    setIcons={setIcons}
                />
            ))}
            <div className="d-flex justify-content-end">
                <a onClick={(e) => {
                    e.preventDefault();
                    
                    let newObj = [...icons];
                    newObj.push(newone);
                    
                    setIcons(newObj);

                }} className='addNew'><i className="bi bi-plus-circle-fill sizeCircle" /> Add New</a>
            </div>
        </div>
    )
}


