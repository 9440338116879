import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { SERVICES } from '../Services/Services';

export const DropZoneUrl = ({ index, urlLogos, setUrlLogos }) => {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    let floatingData = useParams();
    const onDrop = useCallback((acceptedFiles) => {
        // setIsLoading(true);
        const validFileFormats = ["image/png", "image/jpeg", "image/jpg"];
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                // console.log('reader ->', reader);
                let sebo = [...urlLogos];

                const base64 = getBase64StringFromDataURL(reader.result);
                sebo[index].image_url = base64;
                sebo[index].image_path = reader.result;

                setUrlLogos(sebo);
            }
        };
        // setIsLoading(false);
    });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/svg': ['.svg'],
        }
    });

    const deleteBrandUrl = async () => {
        if (String(urlLogos[index].image_path).includes('http') === true) {
            
            await SERVICES.deleteBrandUrl(floatingData.id, urlLogos[index].id_url_img).then((res) => {
                
                let sebo = [...urlLogos];
                sebo.splice(index, 1);
                // fetchData();
                setUrlLogos(sebo);
            }).catch((error) => {
                console.error('error delete url ->', error);
            });
        } else {
            let sebo = [...urlLogos];
            sebo.splice(index, 1);
            setUrlLogos(sebo);
        }
    };
    return (
        <div>
            <div className="d-flex align-items-center">
                <p className=' me-auto'>#{index + 1} URL Logo</p>
                <a onClick={(e) => {
                    e.preventDefault();
                    deleteBrandUrl();
                }} className='deleteOne alignright' style={{textAlign:'right'}}><i className="bi bi-x-circle-fill deleteCircle" /></a>
            </div>
            
            <div className="img_infouser_container bg-white mb-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    urlLogos[index].image_path === "" ?
                        <i className="bi bi-image picture_avatar_suerinfo" /> :
                        <img
                            className='img-profile-user'
                            src={String(urlLogos[index].image_path).includes("http") === true ? urlLogos[index].image_path + `?=${performance.now()}` : urlLogos[index].image_path}
                        />
                }
            </div>
        </div>
    )
}
