import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SERVICES } from '../../../Services/Services';
import { logIn } from '../../../store/auth/ProfileStatus';
import './SignUpForm.style.css';
import { getCookie } from '../../../utils/cookies';

export const SignUpForm = ({ setMode }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [company, setCompany] = useState("");
    const [subdomain, setSubdomain] = useState("");
    const [name, setName] = useState("");


    const createAccount = async (e) => {

        e.preventDefault();
        
        if (email.trim() === "" || password.trim() === "" || company.trim() === "" || subdomain.trim() === "") return Swal.fire({
            title: 'Empty spaces',
            text: 'Please, complete it.',
            icon: 'warning',
            timer: 5000
        });
        
        let res = await SERVICES.createAcccount(email, password, name, company, subdomain);
        
        if(res.status !== 200) return Swal.fire({title: res.message.message, icon:'error', confirmButtonColor: '#000000'});
        let cookieData = getCookie('Authorization');
        const jsonCookie = JSON.parse(cookieData)
        if (jsonCookie) {
            window.location.replace(`https://${jsonCookie.name_subdomain}.brandspace.ai/Home`);
            //history.push('/Home');
        } else {
            throw new Error('No se pudo obtener la cookie');
        }
    };
    return (
        <div className="login-form-style my-4">
            <p className='fw-bold fs-4 mb-4'>Let's get started</p>
            <div className='mb-4'>
                <p className='labels-login mb-2'>Name</p>
                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='form-control inputs-login-page py-2'
                />
            </div>
            <div className='mb-4'>
                <p className='labels-login mb-2'>Email address</p>
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={'email'}
                    className='form-control inputs-login-page py-2'
                />
            </div>
            <div className='mb-4'>
                <p className='labels-login mb-2'>Company</p>
                <input
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    className='form-control inputs-login-page py-2'
                />
            </div>
            <div className='mb-4'>
                <p className='labels-login mb-2'>Subdomain</p>
                <input
                    value={subdomain}
                    onChange={(e) => setSubdomain(e.target.value)}
                    className='form-control inputs-login-page py-2'
                />
            </div>
            <div>
                <p className='labels-login mb-2'>Password</p>
                <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={'password'}
                    className='form-control inputs-login-page py-2'
                />
            </div>
            <div className='mt-5'>
                <button onClick={createAccount} className='btn-login'>
                    Sign Up
                </button>
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <p className='text-secondary fw-gray'>Already have an account?</p>

                <a onClick={(e) => { e.preventDefault(); history.push('Login') }} className='text-secondary border-bottom'>Log In</a>
            </div>
        </div>
    )
}
